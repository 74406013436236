import React from "react";
import styled from "@emotion/styled";
import {
  Alert as MuiAlert,
  Box,
  TextField as MuiTextField,
} from "@mui/material";
import { spacing } from "@mui/system";
// ----------------------------------------------------------------------

const Alert = styled(MuiAlert)(spacing);
export default function AlertMsg({ errors }) {
  const getErrorsMsg = () => {
    try {
      if (!errors) return <></>;
      if (Object.keys(errors).length === 0) return <></>;
      return (
        <Alert mt={2} mb={3} severity="warning">
          <Box display="flex" flexDirection="column">
            {Object.entries(errors).map(([k, v], i) => {
              return <div key={i}>{v}</div>;
            })}
          </Box>
        </Alert>
      );
    } catch (error) {
      return <></>;
    }
  };

  if (!errors) return <> </>;
  return <>{getErrorsMsg()} </>;
}
