import * as React from "react";
import styled from "@emotion/styled";
import {
  Avatar as MuiAvatar,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  Typography,
  Chip as MuiChip,
  Grid,
  Box,
} from "@mui/material";
import { spacing } from "@mui/system";
import AudioBoxFromUrl from "components/media/AudioBoxFromUrl";
import VideoBoxFromUrl from "components/media/VideoBoxFromUrl";
import CardMediaFromUrl from "components/media/CardMediaFromUrl";
import { getMediaUrl } from "utils/objectUtil";

// ----------------------------------------------------------------------
const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Avatar = styled(MuiAvatar)(spacing);
const Chip = styled(MuiChip)(spacing);
export default function CardDayChallenge({ challengeDay, refModel }) {
  const getAvatarStereo = (it) => {
    try {
      const str = it.refstereotype.code.substring(0, 2);
      return str;
    } catch (error) {
      return "";
    }
  };

  if (!challengeDay) return <></>;
  const { pointgained } = challengeDay;
  return (
    <Card>
      {challengeDay.description && (
        <Typography mb={4} color="textSecondary" component="p">
          {`${challengeDay.description}`}
        </Typography>
      )}
      <Grid container spacing={{ xs: 2, md: 3 }}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Card sx={{ maxWidth: 345 }}>
            {challengeDay.bgimage && (
              <CardMediaFromUrl
                image={getMediaUrl(challengeDay, "bgimage", refModel)}
                title={challengeDay.title}
              />
            )}
            <CardContent>
              <Typography variant="body2" color="text.secondary">
                Image de fond
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Card sx={{ maxWidth: 345 }}>
            {challengeDay.image && (
              <CardMediaFromUrl
                image={getMediaUrl(challengeDay, "image", refModel)}
                title={challengeDay.title}
              />
            )}
            <CardContent>
              <Typography variant="body2" color="text.secondary">
                Image de présentation
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Card sx={{ maxWidth: 345 }}>
            {challengeDay.femalevoice && (
              <AudioBoxFromUrl
                audio={getMediaUrl(challengeDay, "femalevoice", refModel)}
              />
            )}
            <CardContent>
              <Typography variant="body2" color="text.secondary">
                Voix féminine
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Card sx={{ maxWidth: 345 }}>
            {challengeDay.malevoice && (
              <AudioBoxFromUrl
                audio={getMediaUrl(challengeDay, "malevoice", refModel)}
              />
            )}
            <CardContent>
              <Typography variant="body2" color="text.secondary">
                Voix masculine
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Card sx={{ maxWidth: 345 }}>
            {challengeDay.inspirecontent && (
              <VideoBoxFromUrl
                video={getMediaUrl(challengeDay, "inspirecontent", refModel)}
              />
            )}
            <CardContent>
              <Typography variant="body2" color="text.secondary">
                Vidéo d'inspiration
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {pointgained.map((p, i) => {
        return (
          <Box
            sx={{ width: "100%" }}
            display="flex"
            alignItems="center"
            key={i}
          >
            <Chip
              variant="outlined"
              avatar={<Avatar>{getAvatarStereo(p)}</Avatar>}
              label={`${p.refstereotype.label}: ${p.point}`}
              m={1}
              color={
                p.refstereotype.code === "strong"
                  ? "error"
                  : p.refstereotype.code === "medium"
                  ? "warning"
                  : "primary"
              }
            />
            <Typography color="textSecondary" component="p" sx={{ ml: 3 }}>
              {`Message: ${p.awardmessage}`}
            </Typography>
          </Box>
        );
      })}
    </Card>
  );
}
