import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { withTheme } from "@emotion/react";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Bar } from "react-chartjs-2";

import { MoreVertical } from "react-feather";

import {
  Avatar as MuiAvatar,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardMedia as MuiCardMedia,
  CardContent,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid as MuiGrid,
  LinearProgress as MuiLinearProgress,
  Link,
  Table,
  CardHeader,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography as MuiTypography,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import axios from "axios";
import { API_URL } from "config";
import { Add as AddIcon } from "@mui/icons-material";
import SportsGymnasticsIcon from "@mui/icons-material/SportsGymnastics";
import MicExternalOnIcon from "@mui/icons-material/MicExternalOn";
import MissedVideoCallIcon from "@mui/icons-material/MissedVideoCall";
import DraftsIcon from "@mui/icons-material/Drafts";
import BookmarkAddedIcon from "@mui/icons-material/BookmarkAdded";
import PageviewIcon from "@mui/icons-material/Pageview";
import EditIcon from "@mui/icons-material/Edit";
import { spacing } from "@mui/system";
import usePublishedProg from "hooks/usePublishedProg";
import { getMediaUrl } from "utils/objectUtil";
import CardMediaFromUrl from "components/media/CardMediaFromUrl";
import PublishProgDelBtn from "./PublishProgDelBtn";
// ----------------------------------------------------------------------
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Button = styled(MuiButton)(spacing);

const Card = styled(MuiCard)(spacing);

const Chip = styled(MuiChip)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Grid = styled(MuiGrid)(spacing);

const Typography = styled(MuiTypography)(spacing);

const REF_MODEL = "publishprog";
export default function PublishProgPage() {
  const { publishedPrograms, getPublishedPrograms } = usePublishedProg();
  useEffect(() => {
    getPublishedPrograms();
  }, []);
  return (
    <>
      <Helmet title="Programmes publiés" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Programmes publiés
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/app">
              Accueil
            </Link>
            <Typography>Tous les programmes publiés</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>

      <Divider my={6} />
      <Grid container spacing={6}>
        <>
          {Array.isArray(publishedPrograms) && (
            <>
              {publishedPrograms.map((it, i) => {
                return (
                  <Grid item xs={12} lg={6} xl={3} key={i}>
                    <Detail data={it} />
                  </Grid>
                );
              })}
            </>
          )}
        </>
      </Grid>
    </>
  );
}
/* item card */
const Detail = ({ data }) => {
  if (!data) return <></>;
  return (
    <Card>
      <CardHeader action={<PositionedMenu data={data} />} title={data.title} />
      {data.image ? (
        <CardMediaFromUrl image={data.image} title={data.title} />
      ) : null}
      <CardContent>
        <Typography mb={4} color="textSecondary" component="h5">
          {`${data.company.name}`}
        </Typography>
        <Typography mb={4} color="textSecondary" component="p">
          {`${data.description}`}
        </Typography>
      </CardContent>
    </Card>
  );
};

const PositionedMenu = ({ data }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        id="demo-positioned-button"
        aria-controls={open ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreVertical />
      </IconButton>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem component={NavLink} to={`/app/publish-view/${data.id}`}>
          <Button
            aria-label="details"
            size="small"
            onClick={handleClick}
            color="primary"
          >
            <PageviewIcon sx={{ mr: 2 }} /> Détail
          </Button>
        </MenuItem>
        <MenuItem>
          <PublishProgDelBtn
            editData={data}
            refName={REF_MODEL}
            onComplete={handleClose}
          />
        </MenuItem>
      </Menu>
    </div>
  );
};
