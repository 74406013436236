import React from "react";
import styled from "@emotion/styled";
import {
  Avatar as MuiAvatar,
  Box,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Typography as MuiTypography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { fDate } from "utils/formatTime";
import { getMediaUrl } from "utils/objectUtil";
import AvatarImgFromUrl from "components/media/AvatarImgFromUrl";

// ----------------------------------------------------------------------
const Button = styled(MuiButton)(spacing);
const Card = styled(MuiCard)(spacing);

const Spacer = styled.div(spacing);
const Typography = styled(MuiTypography)(spacing);
const Centered = styled.div`
  text-align: center;
`;

const Avatar = styled(MuiAvatar)`
  display: inline-block;
  height: 128px;
  width: 128px;
`;
const refModel = "";
export default function UserDetail({ userData }) {
  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Informations générales
        </Typography>

        <Spacer mb={4} />

        <Centered>
          {userData.avatar && (
            <AvatarImgFromUrl
              image={userData.avatar}
              sx={{
                display: "inline-block",
                height: "128px",
                width: "128px",
              }}
            />
          )}
          <Typography variant="body2" component="div" gutterBottom>
            <Box fontWeight="fontWeightMedium">{`${userData.pseudo}`}</Box>
            <Box fontWeight="fontWeightRegular">{`${userData.firstname} ${userData.lastname}`}</Box>
          </Typography>

          <Button mr={2} variant="outlined" color="warning" size="small">
            Activé le: {fDate(userData.enabledat)}
          </Button>
        </Centered>
      </CardContent>
    </Card>
  );
}
