import React, { useState } from "react";
import useProgram from "hooks/useProgram";
import { Button } from "@mui/material";
import useNotification from "hooks/useNotification";
import CircularProgress from "@mui/material/CircularProgress";
import BookmarkAddedIcon from "@mui/icons-material/BookmarkAdded";
// ----------------------------------------------------------------------
export default function SelectStereoProgBtn({ editProgram, data }) {
  const [submitting, setsubmitting] = useState(false);
  const { initProgramById, selectStereoForProg } = useProgram();
  const { notif } = useNotification();
  const selectStereo = async () => {
    try {
      setsubmitting(true);
      await selectStereoForProg(editProgram.id, data.id);
      await initProgramById(editProgram.id);
      notif("Sauvegarde réussie");
      setsubmitting(false);
    } catch (error) {
      console.error(error);
      notif(null, error);
      setsubmitting(false);
    }
  };
  return (
    <>
      {submitting ? (
        <CircularProgress />
      ) : (
        <>
          <Button
            variant="outlined"
            color="warning"
            onClick={() => selectStereo()}
          >
            <BookmarkAddedIcon size="small" />
            Sélectionner
          </Button>
        </>
      )}
    </>
  );
}
