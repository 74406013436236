import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import styled from "@emotion/styled";
import {
  Alert as MuiAlert,
  Box,
  MenuItem,
  TextField as MuiTextField,
  ListItemText,
  Button,
  Stack,
  Paper,
  Divider as MuiDivider,
} from "@mui/material";
import { spacing } from "@mui/system";
import useNotification from "hooks/useNotification";
import useProgram from "hooks/useProgram";
import useReferentiel from "hooks/useReferentiel";
import UploadSingleFile from "components/file/UploadSingleFile";
import ImgBoxFromUrl from "components/media/ImgBoxFromUrl";
import AudioBoxFromUrl from "components/media/AudioBoxFromUrl";
import { API_URL } from "config";
import { useNavigate } from "react-router-dom";
import { getMediaUrl } from "utils/objectUtil";
// ----------------------------------------------------------------------
const TextField = styled(MuiTextField)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Item = styled(Paper)(({ theme }) => ({
  //backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
const REF_MODEL = "programs";
export default function ProgramCreateForm({ editProgram, onComplete }) {
  const { initProgramById, getPrograms, saveProgram, saveProgarmFormdata } =
    useProgram();
  const { notif } = useNotification();
  const { appconfigs, refs } = useReferentiel();
  const { navigate } = useNavigate();
  const dataSchema = Yup.object().shape({
    company: Yup.string().max(100).required(`Veuillez renseigner le thème`),
    code: Yup.string().max(20).required("Veuillez renseigner le code"),
    title: Yup.string().max(100).required(`Veuillez renseigner le titre`),
    description: Yup.string().max(250),
    numberoffdays: Yup.number()
      .max(30)
      .required(`Veuillez renseigner le nombre de jours`),
    image:
      editProgram && editProgram.id
        ? Yup.string()
        : Yup.mixed().required("Veuillez renseigner l'image"),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      company: editProgram && editProgram.company ? editProgram.company.id : "",
      code: editProgram && editProgram.code ? editProgram.code : "",
      title: editProgram && editProgram.title ? editProgram.title : "",
      description:
        editProgram && editProgram.description ? editProgram.description : "",
      numberoffdays:
        editProgram && editProgram.numberoffdays
          ? editProgram.numberoffdays
          : "",
    },
    validationSchema: dataSchema,
    onSubmit: async () => {
      try {
        let dataCreated = null;
        const newData =
          editProgram && editProgram.id
            ? { ...editProgram, ...values }
            : { ...values };
        const companyFounded = companies.find((it) => it.id === values.company);
        newData.company = companyFounded;
        /* in case file upload is choiced we need to make formdata */
        const isFormdata = values.image;

        if (isFormdata) {
          const formData = new FormData();
          delete newData.modifiedby;
          delete newData.createdby;
          if (values.image) {
            delete newData.image;
            formData.append("image", values.image);
          }
          Object.entries(newData).forEach(([e, k]) => {
            if (e === "company") {
              formData.append(e, JSON.stringify(k));
            } else {
              formData.append(e, k);
            }
          });
          if (editProgram && editProgram.id) {
            formData.delete("numberoffdays");
          }
          dataCreated = await saveProgarmFormdata(newData, formData);
        } else {
          dataCreated = await saveProgram({
            ...newData,
          });
        }
        if (editProgram && editProgram.id) {
          await initProgramById(editProgram.id);
        }

        notif("Sauvegarde réussie");
        await getPrograms();
        if (onComplete) {
          onComplete();
        }
        if (dataCreated) {
          navigate(`/app/edit-program/${dataCreated.id}`);
        }
      } catch (error) {
        console.error(error);
        notif(null, error);
        setSubmitting(false);
      }
    },
  });

  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setSubmitting,
    handleChange,
    setFieldValue,
  } = formik;

  if (!refs) return;
  const { companies } = refs;
  if (!companies) return <></>;
  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <TextField
            fullWidth
            select
            label="Société"
            value={values.company}
            onChange={handleChange("company")}
            {...getFieldProps("company")}
            error={Boolean(touched.company && errors.company)}
            helperText={touched.company && errors.company}
            my={2}
          >
            {companies.map((option, i) => (
              <MenuItem key={i} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            fullWidth
            type="text"
            label="Code"
            {...getFieldProps("code")}
            error={Boolean(touched.code && errors.code)}
            helperText={touched.code && errors.code}
            my={2}
          />
          <TextField
            fullWidth
            type="text"
            name="title"
            label="Titre"
            {...getFieldProps("title")}
            error={Boolean(touched.title && errors.title)}
            helperText={touched.title && errors.title}
            my={2}
          />
          <TextField
            fullWidth
            type="text"
            name="description"
            label="Description"
            {...getFieldProps("description")}
            error={Boolean(touched.description && errors.description)}
            helperText={touched.description && errors.description}
            my={2}
          />
          <TextField
            fullWidth
            type="number"
            name="numberoffdays"
            label="Nombre de jours"
            {...getFieldProps("numberoffdays")}
            error={Boolean(touched.numberoffdays && errors.numberoffdays)}
            helperText={touched.numberoffdays && errors.numberoffdays}
            my={2}
            disabled={editProgram && editProgram.id}
          />
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{ my: 2 }}
            direction={{ sm: "column", md: "row" }}
            spacing={{ xs: 1, sm: 2, md: 4 }}
          >
            {editProgram && editProgram.image && (
              <Item>
                <ImgBoxFromUrl
                  // image={`${API_URL.getstream}/programs_${editProgram.id}/${editProgram.image}`}
                  image={getMediaUrl(editProgram, "image", REF_MODEL)}
                  sx={{
                    borderRadius: 1,
                    objectFit: "cover",
                    width: "150px",
                    height: "auto",
                  }}
                />
              </Item>
            )}
            <Item>
              <UploadSingleFile
                value={values.image}
                label="Image d'illustration"
                onChange={(val) => setFieldValue("image", val)}
                caption="(Les formats *.jpeg and *.png sont acceptés)"
                error={Boolean(touched.image && errors.image)}
                sx={{ maxHeight: "150px" }}
                acceptFile={{
                  accept: {
                    "image/jpeg": [".jpeg", ".png"],
                  },
                }}
                mediaType="image"
              />
            </Item>
          </Stack>
          <Divider my={6} />
          <Button
            sx={{ my: 2 }}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            Valider
          </Button>
        </Form>
      </FormikProvider>
    </>
  );
}
