import React from "react";
import styled from "@emotion/styled";
import { rgba } from "polished";
import { NavLink } from "react-router-dom";

import { Button, Box, Container, Grid, Typography } from "@mui/material";
import { spacing } from "@mui/system";
import { ArrowForward as ArrowForwardIcon } from "@mui/icons-material";

import {
  Mail as MailIcon,
  Code as CodeIcon,
  Users as UsersIcon,
  Figma as FigmaIcon,
  BookOpen as BookOpenIcon,
  PlusCircle as PlusCircleIcon,
  Settings,
  Gift,
  ShoppingBag,
  BookOpen,
  ShoppingCart,
} from "react-feather";

const Wrapper = styled.div`
  ${spacing};
  background: ${(props) => props.theme.palette.background.paper};
  text-align: center;
`;

const TypographyOverline = styled(Typography)`
  text-transform: uppercase;
  color: ${(props) => props.theme.palette.primary.main};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
`;

const FeatureWrapper = styled.div`
  display: flex;
  text-align: left;
  padding: 18px 20px;
`;

const FeatureIcon = styled.div`
  svg {
    flex-shrink: 0;
    width: auto;
    height: 48px;
    width: 48px;
    background: ${(props) => rgba(props.theme.palette.primary.main, 0.15)};
    color: ${(props) => props.theme.palette.primary.main};
    padding: 10px;
    border-radius: 50%;
  }
`;

const ArrowForward = styled(ArrowForwardIcon)`
  margin-left: ${(props) => props.theme.spacing(2)};
`;

const Feature = ({ Icon, title, description }) => {
  return (
    <Grid item xs={12} sm={6} md={4} lg={4}>
      <FeatureWrapper>
        <FeatureIcon>
          <Icon />
        </FeatureIcon>
        <Box ml={6}>
          <Typography variant="h4" gutterBottom>
            {title}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            {description}
          </Typography>
        </Box>
      </FeatureWrapper>
    </Grid>
  );
};

function Features() {
  return (
    <Wrapper py={20}>
      <Container>
        <Typography variant="h2" component="h3" gutterBottom>
          Bienvenu dans l'interface Back-Office de BombaLife
        </Typography>
        <Box mb={8} />
        <Grid container spacing={6}>
          <Feature
            Icon={UsersIcon}
            title="Gestion des utilisateurs"
            description="Créer et éditer les utilisateurs ainsi que leurs profils d'accès par projet client. "
          />
          <Feature
            Icon={PlusCircleIcon}
            title="Gestion des codes"
            description="Générer des codes d'application et leurs validités."
          />
          <Feature
            Icon={Settings}
            title="Gestion des référentiels"
            description="Gérer des référentiels (thèmes, sujets, type de défi...)"
          />

          <Feature
            Icon={ShoppingBag}
            title="Gestion des contenu média"
            description="Créer de contenus média (vidéo, image, audio) qui seront téléchargés depuis l'application BombaLife"
          />
          <Feature
            Icon={BookOpen}
            title="Gestion des programmes"
            description="Réaliser un programme avec les thèmes, les sujets les défis et les publier aux utilisateurs"
          />

          <Feature
            Icon={Gift}
            title="Gérer la boutique BombaLife"
            description="Créer des cadeaux et les offrir aux utilisateurs"
          />
          {/* <Feature
            Icon={MailIcon}
            title="Dev-to-Dev Support"
            description="Our themes are supported by specialists who provide quick and effective support. Usually an email reply takes <24h."
          /> */}
        </Grid>
      </Container>
    </Wrapper>
  );
}

export default Features;
