import React from "react";
import styled from "@emotion/styled";
import {
  Card as MuiCard,
  CardContent,
  Typography as MuiTypography,
  IconButton,
  Popover,
  CardActions,
  Button,
  Badge,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { spacing } from "@mui/system";
import ErrorIcon from "@mui/icons-material/Error";
import Done from "@mui/icons-material/Done";

// ----------------------------------------------------------------------
const Card = styled(MuiCard)(spacing);
const Typography = styled(MuiTypography)(spacing);
export default function ThemeMessagesBtn({ editProgram, editTheme }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  if (!editProgram) return <></>;
  if (!editTheme) return <></>;
  if (!editProgram.messages) return <></>;
  const getMessages = () => {
    try {
      const arr = editProgram.messages.filter((it) => {
        const code = it.code.split("-")[0];
        console.log(code, editTheme.reftheme.id);
        return String(code) === String(editTheme.reftheme.id);
      });
      return arr;
    } catch (error) {
      return [];
    }
  };
  const listMsg = getMessages();
  return (
    <>
      {editTheme.isValidated ? (
        <>
          <IconButton aria-label="details" color="success" variant="contained">
            <Done />
          </IconButton>
        </>
      ) : (
        <>
          <IconButton
            aria-label="details"
            color="error"
            variant="contained"
            onClick={handleClick}
          >
            <Badge badgeContent={listMsg.length} color="warning">
              <ErrorIcon color="action" />
            </Badge>
          </IconButton>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Card
              sx={{
                minWidth: 275,
                backgroundColor: "#1A2027",
              }}
            >
              <CardContent>
                <List dense>
                  {listMsg.map((it, i) => {
                    return (
                      <ListItem key={i}>
                        <ListItemText primary={it.content} />
                      </ListItem>
                    );
                  })}
                </List>
              </CardContent>
              <CardActions sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button onClick={handleClose}>Annuler</Button>
              </CardActions>
            </Card>
          </Popover>
        </>
      )}
    </>
  );
}
