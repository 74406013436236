import { useDispatch, useSelector } from "react-redux";
import {
  startLoading,
  stopLoading,
  setInitAppconfig,
  setInitialize,
} from "redux/slices/refSlice";
import axios from "axios";
import { API_URL } from "config";
// ----------------------------------------------------------------------

export default function useReferentiel() {
  const dispatch = useDispatch();
  const { refs, appconfigs } = useSelector((state) => state.ref);

  return {
    refs,
    appconfigs,
    initRefs: async () => {
      try {
        dispatch(startLoading());
        const res = await axios.get(API_URL.refs);
        if (res && res.data) {
          const refs = res.data;
          dispatch(setInitialize({ refs }));
        } else {
          throw new Error("Référentiels ne sont pas disponibles");
        }
      } catch (error) {
        dispatch(stopLoading());
        console.error(error);
        throw new Error("Référentiels ne sont pas disponibles");
      }
    },
    initAppconfigs: async () => {
      try {
        dispatch(startLoading());
        const res = await axios.get(API_URL.appconfig);
        if (res && res.data) {
          const apponfigs = res.data;
          dispatch(setInitAppconfig([...apponfigs]));
        } else {
          throw new Error("Configurations ne sont pas disponibles");
        }
      } catch (error) {
        dispatch(stopLoading());
        console.error();
        throw error;
      }
    },
    saveRef: async (o, refName) => {
      try {
        console.log(API_URL[refName]);
        dispatch(startLoading());
        delete o.modifiedby;
        delete o.createdby;
        if (o && o.id) {
          await axios.patch(API_URL[refName] + "/" + o.id, o);
        } else {
          await axios.post(API_URL[refName], o);
        }
      } catch (error) {
        dispatch(stopLoading());
        console.error();
        throw error;
      }
    },
    delRef: async (o, refName) => {
      try {
        dispatch(startLoading());
        if (o && o.id) {
          await axios.delete(API_URL[refName] + "/" + o.id);
        }
      } catch (error) {
        dispatch(stopLoading());
        console.error();
        throw error;
      }
    },
    saveReftheme: async (o) => {
      try {
        dispatch(startLoading());
        delete o.modifiedby;
        delete o.createdby;
        if (o && o.id) {
          await axios.patch(API_URL.reftheme + "/" + o.id, o);
        } else {
          await axios.post(API_URL.reftheme, o);
        }
      } catch (error) {
        dispatch(stopLoading());
        console.error();
        throw error;
      }
    },
    saveRefsubject: async (o) => {
      try {
        dispatch(startLoading());
        delete o.modifiedby;
        delete o.createdby;
        delete o.maletranscripts;
        delete o.femaletranscripts;
        if (o && o.id) {
          await axios.patch(API_URL.refsubject + "/" + o.id, o);
        } else {
          await axios.post(API_URL.refsubject, o);
        }
      } catch (error) {
        dispatch(stopLoading());
        console.error();
        throw error;
      }
    },
    saveRefActionPoint: async (o) => {
      try {
        dispatch(startLoading());
        delete o.modifiedby;
        delete o.createdby;
        if (o && o.id) {
          await axios.patch(API_URL.refactionpoint + "/" + o.id, o);
        } else {
          await axios.post(API_URL.refactionpoint, o);
        }
      } catch (error) {
        dispatch(stopLoading());
        console.error();
        throw error;
      }
    },
    delRefsubject: async (o) => {
      try {
        dispatch(startLoading());
        if (o && o.id) {
          await axios.delete(API_URL.refsubject + "/" + o.id);
        }
      } catch (error) {
        dispatch(stopLoading());
        console.error();
        throw error;
      }
    },
    sendFormdata: async (o, formdata) => {
      try {
        dispatch(startLoading());
        delete o.modifiedby;
        delete o.createdby;
        delete o.maletranscripts;
        delete o.femaletranscripts;
        if (o && o.id) {
          await axios.patch(API_URL.refsubject + "/formdata/" + o.id, formdata);
        } else {
          await axios.post(API_URL.refsubject + "/formdata", formdata);
        }
      } catch (error) {
        dispatch(stopLoading());
        console.error();
        throw error;
      }
    },
    saveRefVoiceCoach: async (o) => {
      try {
        dispatch(startLoading());
        delete o.modifiedby;
        delete o.createdby;
        delete o.transcripts;
        if (o && o.id) {
          await axios.patch(API_URL.refCoachVoice + "/" + o.id, o);
        } else {
          await axios.post(API_URL.refCoachVoice, o);
        }
      } catch (error) {
        dispatch(stopLoading());
        console.error();
        throw error;
      }
    },
    saveRefVoiceCoachFormData: async (o, formdata) => {
      try {
        dispatch(startLoading());
        delete o.modifiedby;
        delete o.createdby;
        delete o.transcripts;
        if (o && o.id) {
          await axios.patch(
            API_URL.refCoachVoice + "/formdata/" + o.id,
            formdata
          );
        } else {
          await axios.post(API_URL.refCoachVoice + "/formdata", formdata);
        }
      } catch (error) {
        dispatch(stopLoading());
        console.error();
        throw error;
      }
    },
  };
}
