import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { NavLink } from "react-router-dom";
import styled from "@emotion/styled";
import {
  Avatar,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Card as MuiCard,
  CardActions,
  CardContent as MuiCardContent,
  CardMedia as MuiCardMedia,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid,
  Link,
  Typography as MuiTypography,
  AvatarGroup as MuiAvatarGroup,
} from "@mui/material";
import axios from "axios";
import { spacing } from "@mui/system";
import { API_URL } from "config";
import RefsEditBtn from "./RefsEditBtn";
import RefsDelBtn from "./RefsDelBtn";

// ----------------------------------------------------------------------
const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)`
  border-bottom: 1px solid ${(props) => props.theme.palette.grey[300]};
`;

const CardMedia = styled(MuiCardMedia)`
  height: 220px;
`;
const Typography = styled(MuiTypography)(spacing);
const Chip = styled(MuiChip)`
  height: 20px;
  padding: 4px 0;
  font-size: 85%;
  background-color: ${(props) =>
    props.theme.palette[props.color ? props.color : "primary"].light};
  color: ${(props) => props.theme.palette.common.white};
  margin-bottom: ${(props) => props.theme.spacing(4)};
`;

const AvatarGroup = styled(MuiAvatarGroup)`
  margin-left: ${(props) => props.theme.spacing(2)};
`;

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
export default function CardItem({
  row,
  refName,
  image,
  title,
  description,
  chip,
  createdBy,
  modifiedBy,
}) {
  const [imageObj, setimageObj] = useState(null);
  useEffect(() => {
    if (!image) return;

    const init = async () => {
      try {
        const response = await axios.get(`${image}`, {
          responseType: "arraybuffer",
        });
        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });

        const imageDataUrl = URL.createObjectURL(blob);
        setimageObj(imageDataUrl);
      } catch (error) {
        console.error(error);
      }
    };
    init();
  }, [image]);
  return (
    <Card>
      {imageObj ? (
        <CardMedia image={imageObj} title="Contemplative Reptile" />
      ) : null}
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          {title}
        </Typography>

        {chip}

        <Typography mb={4} color="textSecondary" component="p">
          {description}
        </Typography>

        <AvatarGroup max={3}>
          {createdBy && (
            <Avatar alt="Avatar" src="/static/img/avatars/avatar-1.jpg" />
          )}
          {modifiedBy && (
            <Avatar alt="Avatar" src="/static/img/avatars/avatar-2.jpg" />
          )}
        </AvatarGroup>
      </CardContent>
      <CardActions sx={{ display: "flex", justifyContent: "flex-end" }}>
        <RefsEditBtn editData={row} refName={refName} />
        <RefsDelBtn editData={row} refName={refName} />
      </CardActions>
    </Card>
  );
}
