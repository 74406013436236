import Box from "@mui/material/Box";

import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { fDateTime } from "utils/formatTime";
import ImgBoxFromUrl from "components/media/ImgBoxFromUrl";
import { getMediaUrl } from "utils/objectUtil";

// ----------------------------------------------------------------------
const REF_MODEL = "publishprog";
export default function TabPublishInfo({ editProgram }) {
  if (!editProgram) return <></>;
  return (
    <Grid container spacing={{ xs: 2, md: 3 }}>
      <Grid item sm={12} md={6}>
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 1, width: "25ch" },
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            label="Code"
            defaultValue={editProgram.code}
            InputProps={{
              readOnly: true,
            }}
            sx={{ my: 3 }}
          />
          <TextField
            label="Nom du progamme"
            defaultValue={editProgram.title}
            InputProps={{
              readOnly: true,
            }}
            sx={{ my: 3 }}
          />
          <TextField
            label="Nom du progamme"
            defaultValue={editProgram.title}
            InputProps={{
              readOnly: true,
            }}
            sx={{ my: 3 }}
          />
          <TextField
            label="Nombre de jour"
            defaultValue={editProgram.numberoffdays}
            InputProps={{
              readOnly: true,
            }}
            sx={{ my: 3 }}
          />
          <TextField
            label="Description"
            defaultValue={editProgram.description}
            InputProps={{
              readOnly: true,
            }}
            sx={{ my: 3 }}
          />
          <TextField
            label="Date de publication"
            defaultValue={fDateTime(editProgram.publishedat)}
            InputProps={{
              readOnly: true,
            }}
            sx={{ my: 3 }}
          />

          <TextField
            label="Société"
            defaultValue={editProgram.company.name}
            InputProps={{
              readOnly: true,
            }}
            sx={{ my: 3 }}
          />
          <TextField
            label="Créé par"
            defaultValue={editProgram.createdby.pseudo}
            InputProps={{
              readOnly: true,
            }}
            sx={{ my: 3 }}
          />
          <TextField
            label="Modifié par"
            defaultValue={editProgram.modifiedby.pseudo}
            InputProps={{
              readOnly: true,
            }}
            sx={{ my: 3 }}
          />
        </Box>
      </Grid>
      <Grid item sm={12} md={6}>
        <ImgBoxFromUrl
          image={editProgram.image}
          sx={{
            borderRadius: 1,
            objectFit: "cover",
            width: "200px",
            height: "auto",
          }}
        />
      </Grid>
    </Grid>
  );
}
