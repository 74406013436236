import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import styled from "@emotion/styled";
import {
  Checkbox,
  Button,
  MenuItem,
  TextField as MuiTextField,
  ListItemText,
  Paper,
  Stack,
} from "@mui/material";
import { spacing } from "@mui/system";
import useNotification from "hooks/useNotification";
import useProgram from "hooks/useProgram";
import useReferentiel from "hooks/useReferentiel";
import { refBoosterExe } from "config";
import UploadSingleFile from "components/file/UploadSingleFile";
import ImgBoxFromUrl from "components/media/ImgBoxFromUrl";
import VideoBoxFromUrl from "components/media/VideoBoxFromUrl";
import { getMediaUrl } from "utils/objectUtil";
// ----------------------------------------------------------------------
const TextField = styled(MuiTextField)(spacing);
const Item = styled(Paper)(({ theme, i }) => ({
  backgroundColor: i % 2 === 0 ? "#1A2027" : "",
  padding: theme.spacing(1),
}));
const REF_MODEL = "booster";
export default function BoosterForm({ editData, onComplete }) {
  const { saveBoosterFormData, saveBooster, getBoosters } = useProgram();
  const { refs } = useReferentiel();
  const { refstereotype } = refs;
  const { notif } = useNotification();
  const getValidationParams = () => {
    try {
      if (!refstereotype) return;
      const obj = {};
      refstereotype.forEach((it) => {
        obj[`point_${it.code}`] = Yup.string()
          .max(200)
          .required("Veuillez renseigner le point gagné pour " + it.label);
        obj[`awardmessage_${it.code}`] = Yup.string()
          .max(200)
          .required(
            "Veuillez renseigner le message de récompense pour " + it.label
          );
      });
      return obj;
    } catch (error) {
      return {};
    }
  };
  const getInitValues = () => {
    try {
      const obj = {};
      refstereotype.forEach((it) => {
        const pointField = `point_${it.code}`;
        obj[pointField] = "";
        const awardmessageField = `awardmessage_${it.code}`;
        obj[awardmessageField] = "";
      });
      return obj;
    } catch (error) {
      return {};
    }
  };
  const getFieldParams = () => {
    try {
      if (!refstereotype) return;
      return (
        <>
          {refstereotype.map((it, i) => {
            const pointField = `point_${it.code}`;
            const awardmessageField = `awardmessage_${it.code}`;
            return (
              <Item key={i} i={i}>
                <TextField
                  fullWidth
                  type="number"
                  label={`Point gagné pour le ${it.label}`}
                  {...getFieldProps(pointField)}
                  error={Boolean(touched[pointField] && errors[pointField])}
                  helperText={touched[pointField] && errors[pointField]}
                  my={2}
                />
                <TextField
                  fullWidth
                  type="text"
                  label={`Message de récompense le ${it.label}`}
                  {...getFieldProps(awardmessageField)}
                  error={Boolean(
                    touched[awardmessageField] && errors[awardmessageField]
                  )}
                  helperText={
                    touched[awardmessageField] && errors[awardmessageField]
                  }
                  my={2}
                />
              </Item>
            );
          })}
        </>
      );
    } catch (error) {
      return <></>;
    }
  };
  const dataSchema = Yup.object().shape({
    code: Yup.string().max(20).required("Veuillez renseigner le code"),
    label: Yup.string().max(50).required(`Veuillez renseigner le libellé`),
    refboostertype: Yup.string()
      .max(50)
      .required(`Veuillez renseigner le booster type`),
    ...getValidationParams(),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      code: editData && editData.code ? editData.code : "",
      label: editData && editData.label ? editData.label : "",
      refboostertype:
        editData && editData.refboostertype ? editData.refboostertype.id : "",
      ...getInitValues(),
    },
    validationSchema: dataSchema,
    onSubmit: async () => {
      try {
        const pointgained = [];
        refstereotype.forEach((it) => {
          const keyField = `point_${it.code}`;
          const awardmessageField = `awardmessage_${it.code}`;
          pointgained.push({
            refstereotype: { ...it },
            point: values[keyField],
            awardmessage: values[awardmessageField],
          });
        });
        const newData =
          editData && editData.id
            ? { ...editData, ...values, pointgained }
            : { ...values, pointgained };
        const validData = {};
        Object.entries(newData).forEach(([k, v]) => {
          if (!k.startsWith("awardmessage_") && !k.startsWith("point_")) {
            validData[k] = v;
          }
        });
        /* in case file upload is choiced we need to make formdata */
        const isFormdata = values.image || values.video;

        if (isFormdata) {
          const formData = new FormData();
          delete validData.modifiedby;
          delete validData.createdby;
          if (values.image) {
            delete validData.image;
            formData.append("image", values.image);
          }
          if (values.video) {
            delete validData.video;
            formData.append("video", values.video);
          }
          Object.entries(validData).forEach(([e, k]) => {
            if (e === "pointgained") {
              formData.append(e, JSON.stringify(k));
            } else {
              formData.append(e, k);
            }
          });

          await saveBoosterFormData(validData, formData);
        } else {
          await saveBooster({
            ...validData,
          });
        }

        await getBoosters();
        notif("Sauvegarde réussie");
        onComplete();
      } catch (error) {
        console.error(error);
        notif(null, error);
        setSubmitting(false);
      }
    },
  });

  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setSubmitting,
    handleChange,
    setFieldValue,
  } = formik;
  useEffect(() => {
    if (!refstereotype) return;
    if (!editData) return;
    if (!editData.pointgained) return;
    if (!setFieldValue) return;

    refstereotype.forEach((it) => {
      const pointField = `point_${it.code}`;
      const awardmessageField = `awardmessage_${it.code}`;
      const pointGainedFound = editData.pointgained.find(
        (p) => p.refstereotype.code === it.code
      );

      if (pointGainedFound) {
        setFieldValue(pointField, pointGainedFound.point);
        setFieldValue(awardmessageField, pointGainedFound.awardmessage);
      }
    });
  }, [refstereotype, editData, setFieldValue]);
  const { refboostertype } = refs;
  if (!refs) return <></>;
  if (!refboostertype) return <></>;
  if (!refstereotype) return <></>;
  const fieldDisabled = editData && editData.id;
  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <TextField
            fullWidth
            type="text"
            label="Code"
            {...getFieldProps("code")}
            error={Boolean(touched.code && errors.code)}
            helperText={touched.code && errors.code}
            my={2}
            disabled={fieldDisabled}
          />
          <TextField
            fullWidth
            type="text"
            name="label"
            label="Libellé"
            {...getFieldProps("label")}
            error={Boolean(touched.label && errors.label)}
            helperText={touched.label && errors.label}
            my={2}
          />
          <TextField
            fullWidth
            select
            label="Booster type"
            value={values.refboostertype}
            onChange={handleChange("refboostertype")}
            {...getFieldProps("refboostertype")}
            error={Boolean(touched.refboostertype && errors.refboostertype)}
            helperText={touched.refboostertype && errors.refboostertype}
            my={2}
            disabled={fieldDisabled}
          >
            {refboostertype.map((option, i) => (
              <MenuItem key={i} value={option.id}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          {/* case exercice booster need image and video fields */}
          {values &&
            values.refboostertype &&
            String(values.refboostertype) === String(refBoosterExe) && (
              <>
                <Stack
                  alignItems="center"
                  justifyContent="flex-start"
                  sx={{ py: 10 }}
                  direction="row"
                  spacing={4}
                >
                  {editData && editData.image && (
                    <Item>
                      <ImgBoxFromUrl
                        image={getMediaUrl(editData, "image", REF_MODEL)}
                        sx={{
                          borderRadius: 1,
                          objectFit: "cover",
                          width: "150px",
                          height: "auto",
                        }}
                      />
                    </Item>
                  )}
                  <Item>
                    <UploadSingleFile
                      value={values.image}
                      label="Image d'illustration"
                      onChange={(val) => setFieldValue("image", val)}
                      caption="(Les formats *.jpeg and *.png sont acceptés)"
                      error={Boolean(touched.image && errors.image)}
                      sx={{ maxHeight: "50px" }}
                      acceptFile={{
                        accept: {
                          "image/jpeg": [".jpeg", ".png"],
                        },
                      }}
                      mediaType="image"
                    />
                  </Item>
                </Stack>
                <Stack
                  alignItems="center"
                  justifyContent="flex-start"
                  sx={{ py: 10 }}
                  direction="row"
                  spacing={5}
                >
                  {editData && editData.video && (
                    <Item>
                      <VideoBoxFromUrl
                        video={getMediaUrl(editData, "video", REF_MODEL)}
                      />
                    </Item>
                  )}

                  <Item>
                    <UploadSingleFile
                      value={values.video}
                      label="Vidéo d'exercice"
                      onChange={(val) => setFieldValue("video", val)}
                      caption="(Les formats *.mp4 sont acceptés)"
                      error={Boolean(touched.video && errors.video)}
                      sx={{ maxHeight: "150px" }}
                      acceptFile={{
                        accept: {
                          "video/mp4": [".mp4"],
                        },
                      }}
                      mediaType="video"
                    />
                  </Item>
                </Stack>
              </>
            )}
          <Stack>{getFieldParams()}</Stack>
          <Button
            sx={{ my: 2 }}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            Valider
          </Button>
        </Form>
      </FormikProvider>
    </>
  );
}
