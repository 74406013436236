import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { withTheme } from "@emotion/react";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Bar } from "react-chartjs-2";

import { MoreVertical } from "react-feather";

import {
  Avatar as MuiAvatar,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardMedia as MuiCardMedia,
  CardContent,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid as MuiGrid,
  LinearProgress as MuiLinearProgress,
  Link,
  Table,
  CardHeader,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography as MuiTypography,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import axios from "axios";
import { API_URL } from "config";
import { Add as AddIcon } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import { spacing } from "@mui/system";
import useChallenge from "hooks/useChallenge";
import { getMediaUrl } from "utils/objectUtil";
import AudioBoxFromUrl from "components/media/AudioBoxFromUrl";
import ChallengeDelBtn from "pages/challenge/ChallengeDelBtn";
// ----------------------------------------------------------------------
const CardMedia = styled(MuiCardMedia)`
  height: 220px;
`;
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Button = styled(MuiButton)(spacing);

const Card = styled(MuiCard)(spacing);

const Chip = styled(MuiChip)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Grid = styled(MuiGrid)(spacing);

const LinearProgress = styled(MuiLinearProgress)(spacing);

const Spacer = styled.div(spacing);

const Typography = styled(MuiTypography)(spacing);

const Centered = styled.div`
  text-align: center;
`;

const Avatar = styled(MuiAvatar)`
  display: inline-block;
  height: 128px;
  width: 128px;
`;

const LetterAvatar = styled(MuiAvatar)(spacing);
const AboutIcon = styled.span`
  display: flex;
  padding-right: ${(props) => props.theme.spacing(2)};

  svg {
    width: 14px;
    height: 14px;
  }
`;

const ChartWrapper = styled.div`
  height: 280px;
  position: relative;
`;

const StatsIcon = styled.div`
  position: absolute;
  right: 16px;
  top: 32px;

  svg {
    width: 32px;
    height: 32px;
    color: ${(props) => props.theme.palette.secondary.main};
  }
`;

const ProductsChip = styled(Chip)`
  height: 20px;
  padding: 4px 0;
  font-size: 90%;
  background-color: ${(props) =>
    props.theme.palette[props.color ? props.color : "primary"].light};
  color: ${(props) => props.theme.palette.common.white};
`;

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;
const REF_MODEL = "stereotypechallenge";
export default function ProgStereoChallengePage() {
  const { stereotypechallenges, getStereotypeChallenges } = useChallenge();
  useEffect(() => {
    getStereotypeChallenges();
  }, []);
  return (
    <>
      <Helmet title="Stereotype programme" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Stereotype programme
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/app">
              Accueil
            </Link>
            <Typography>Stereotype programme</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <Button
            component={NavLink}
            to="/app/edit-stereotypechallenge/add"
            variant="contained"
            color="primary"
          >
            <AddIcon />
            Nouveau
          </Button>
        </Grid>
      </Grid>

      <Divider my={6} />
      <Grid container spacing={6}>
        <>
          {Array.isArray(stereotypechallenges) && (
            <>
              {stereotypechallenges.map((it, i) => {
                return (
                  <Grid item xs={12} lg={12} xl={12} key={i}>
                    <Detail data={it} />
                  </Grid>
                );
              })}
            </>
          )}
        </>
      </Grid>
    </>
  );
}
/* item card */
const Detail = ({ data }) => {
  const [imageObj, setimageObj] = useState(null);
  const [bgimageObj, setbgimageObj] = useState(null);
  useEffect(() => {
    if (!data) return;
    if (!data.image) return;
    const init = async () => {
      try {
        const response = await axios.get(
          getMediaUrl(data, "image", "stereotypechallenge"),
          {
            responseType: "arraybuffer",
          }
        );
        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });
        const imageDataUrl = URL.createObjectURL(blob);
        setimageObj(imageDataUrl);
      } catch (error) {
        console.error(error);
      }
    };
    init();
  }, [data]);
  useEffect(() => {
    if (!data) return;
    if (!data.bgimageObj) return;
    const init = async () => {
      try {
        const response = await axios.get(
          getMediaUrl(data, "bgimage", "stereotypechallenge"),
          {
            responseType: "arraybuffer",
          }
        );
        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });
        const imageDataUrl = URL.createObjectURL(blob);
        setbgimageObj(imageDataUrl);
      } catch (error) {
        console.error(error);
      }
    };
    init();
  }, [data]);
  if (!data) return <></>;
  return (
    <Card mb={6}>
      <CardHeader action={<PositionedMenu data={data} />} title={data.title} />
      <CardContent>
        {data.refsubject && (
          <>
            <Typography variant="p" gutterBottom>
              {`Sujet ${data.refsubject.code} - ${data.refsubject.label}`}
            </Typography>
            <Spacer mb={3} />
          </>
        )}
        <Centered>
          <Box display="flex" justifyContent="center">
            {imageObj ? (
              <Avatar
                src={imageObj}
                title="Contemplative Reptile"
                variant="rounded"
                sx={{ width: 200 }}
              />
            ) : null}
            {bgimageObj ? (
              <Avatar
                src={bgimageObj}
                title="Contemplative Reptile"
                variant="rounded"
                sx={{ width: 200 }}
              />
            ) : null}
            {data && data.femalevoice && (
              <Box
                sx={{ width: 250, mx: 6 }}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <AudioBoxFromUrl
                  audio={getMediaUrl(
                    data,
                    "femalevoice",
                    "stereotypechallenge"
                  )}
                />
                <Typography
                  variant="body2"
                  component="div"
                  gutterBottom
                  sx={{ mt: 3 }}
                >
                  <Box fontWeight="fontWeightMedium"> Voix féminine</Box>
                </Typography>
              </Box>
            )}
            {data && data.malevoice && (
              <Box
                sx={{ width: 250, mx: 6 }}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <AudioBoxFromUrl
                  audio={getMediaUrl(data, "malevoice", "stereotypechallenge")}
                />
                <Typography
                  variant="body2"
                  component="div"
                  gutterBottom
                  sx={{ mt: 3 }}
                >
                  <Box fontWeight="fontWeightRegular"> Voix masculine</Box>
                </Typography>
              </Box>
            )}
          </Box>
        </Centered>
        {data.questions && <Questions questions={data.questions} />}
      </CardContent>
    </Card>
  );
};

const Questions = ({ questions }) => {
  if (!Array.isArray(questions)) return <></>;
  const getAvatarStereo = (it) => {
    try {
      const str = it.refstereotype.code.substring(0, 2);
      return str;
    } catch (error) {
      return "";
    }
  };
  return (
    <Grid container spacing={6}>
      <Grid item xs={12} lg={8} xl={9}>
        <Card mb={6}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              La liste des questionnaires
            </Typography>
            <TableWrapper>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Question</TableCell>
                    <TableCell>Réponses</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {questions.map((it, i) => {
                    return (
                      <TableRow
                        key={i}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {it.title}
                        </TableCell>
                        <TableCell>
                          {it.choices.map((it, i) => {
                            return (
                              <React.Fragment key={i}>
                                <Card mb={1} sx={{ p: 0 }}>
                                  <Typography
                                    variant="p"
                                    gutterBottom
                                    sx={{ mx: 6 }}
                                  >
                                    {it.title}
                                  </Typography>
                                  {it.pointgained.map((p, i) => {
                                    return (
                                      <Chip
                                        variant="outlined"
                                        key={i}
                                        avatar={
                                          <LetterAvatar>
                                            {getAvatarStereo(p)}
                                          </LetterAvatar>
                                        }
                                        label={`${p.refstereotype.label}: ${p.point}`}
                                        m={1}
                                        color={
                                          p.refstereotype.code === "strong"
                                            ? "error"
                                            : p.refstereotype.code === "medium"
                                            ? "warning"
                                            : "primary"
                                        }
                                      />
                                    );
                                  })}
                                </Card>
                                <Divider my={2} />
                              </React.Fragment>
                            );
                          })}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableWrapper>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
const PositionedMenu = ({ data }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        id="demo-positioned-button"
        aria-controls={open ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreVertical />
      </IconButton>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem
          component={NavLink}
          to={`/app/edit-stereotypechallenge/${data.id}`}
        >
          <Button
            aria-label="details"
            size="small"
            onClick={handleClick}
            color="primary"
          >
            <EditIcon /> Modifier
          </Button>
        </MenuItem>
        <MenuItem>
          <ChallengeDelBtn
            editData={data}
            refName={REF_MODEL}
            onComplete={handleClose}
          />
        </MenuItem>
      </Menu>
    </div>
  );
};
