import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import axios from "axios";
import ReactPlayer from "react-player";
// ----------------------------------------------------------------------
export default function VideoBoxFromUrl({ video, sx }) {
  const [imageObj, setimageObj] = useState(null);
  const [displayedControl, setdisplayedControl] = useState(false);
  useEffect(() => {
    return () => {
      setimageObj(null);
    };
  }, []);
  useEffect(() => {
    if (!video) return;
    const init = async () => {
      try {
        const response = await axios.get(`${video}`, {
          responseType: "arraybuffer",
        });
        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });

        const imageDataUrl = URL.createObjectURL(blob);
        setimageObj(imageDataUrl);
      } catch (error) {
        console.error(error);
      }
    };
    init();
  }, [video]);
  if (!imageObj) return <></>;
  return (
    <>
      <ReactPlayer
        muted
        playing
        url={imageObj}
        controls={displayedControl}
        onMouseOver={(e) => {
          setdisplayedControl(true);
        }}
        onMouseOut={(e) => {
          setdisplayedControl(false);
        }}
      />
    </>
  );
}
