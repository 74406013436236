import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import styled from "@emotion/styled";
import {
  Alert as MuiAlert,
  Box,
  MenuItem,
  TextField as MuiTextField,
  Divider as MuiDivider,
  ListItemText,
  Button,
  IconButton,
  Stack,
  Paper,
  Card,
  Popover,
  CardActions,
  CardContent,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { Add as AddIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";
import useReferentiel from "hooks/useReferentiel";
import AlertMsg from "components/custom/AlertMsg";
// ----------------------------------------------------------------------
const TextField = styled(MuiTextField)(spacing);
const Alert = styled(MuiAlert)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Item = styled(Paper)(({ theme, i }) => ({
  backgroundColor: i % 2 === 0 ? "#1A2027" : "",
  padding: theme.spacing(1),
}));

export default function PointgainedForm({ editPoints, onCompletePoint }) {
  const { refs } = useReferentiel();
  const { refstereotype } = refs;

  const getValidationParams = () => {
    try {
      if (!refstereotype) return;
      const obj = {};
      refstereotype.forEach((it) => {
        obj[`point_${it.code}`] = Yup.string()
          .max(200)
          .required("Veuillez renseigner le point gagné pour " + it.label);
        obj[`awardmessage_${it.code}`] = Yup.string()
          .max(200)
          .required(
            "Veuillez renseigner le message de récompense pour " + it.label
          );
      });
      return obj;
    } catch (error) {
      return {};
    }
  };

  const getInitValues = () => {
    try {
      const obj = {};
      refstereotype.forEach((it) => {
        const pointField = `point_${it.code}`;
        obj[pointField] = "";
        const awardmessageField = `awardmessage_${it.code}`;
        obj[awardmessageField] = "";
      });
      return obj;
    } catch (error) {
      return {};
    }
  };
  const getFieldParams = () => {
    try {
      if (!refstereotype) return;
      return (
        <>
          {refstereotype.map((it, i) => {
            const pointField = `point_${it.code}`;
            const awardmessageField = `awardmessage_${it.code}`;
            return (
              <Item key={i} i={i}>
                <TextField
                  fullWidth
                  type="number"
                  label={`Point gagné pour le ${it.label}`}
                  {...getFieldProps(pointField)}
                  error={Boolean(touched[pointField] && errors[pointField])}
                  helperText={touched[pointField] && errors[pointField]}
                  my={2}
                />
                <TextField
                  fullWidth
                  type="text"
                  label={`Message de récompense le ${it.label}`}
                  {...getFieldProps(awardmessageField)}
                  error={Boolean(
                    touched[awardmessageField] && errors[awardmessageField]
                  )}
                  helperText={
                    touched[awardmessageField] && errors[awardmessageField]
                  }
                  my={2}
                />
              </Item>
            );
          })}
        </>
      );
    } catch (error) {
      return <></>;
    }
  };

  const dataSchema = Yup.object().shape({
    ...getValidationParams(),
  });
  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      ...getInitValues(),
    },
    validationSchema: dataSchema,
    onSubmit: async () => {
      try {
        const pointgained = [];
        refstereotype.forEach((it) => {
          const keyField = `point_${it.code}`;
          const awardmessageField = `awardmessage_${it.code}`;
          pointgained.push({
            id: "temp_" + new Date().getTime(),
            refstereotype: { ...it },
            point: values[keyField],
            awardmessage: values[awardmessageField],
          });
        });
        onCompletePoint(pointgained);
      } catch (error) {
        console.error(error);
        setSubmitting(false);
      }
    },
  });
  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setSubmitting,
    setFieldValue,
  } = formik;

  useEffect(() => {
    if (!refstereotype) return;
    if (!editPoints) return;
    if (!setFieldValue) return;

    refstereotype.forEach((it) => {
      const pointField = `point_${it.code}`;
      const awardmessageField = `awardmessage_${it.code}`;
      const pointGainedFound = editPoints.find(
        (p) => p.refstereotype.code === it.code
      );

      if (pointGainedFound) {
        setFieldValue(pointField, pointGainedFound.point);
        setFieldValue(awardmessageField, pointGainedFound.awardmessage);
      }
    });
  }, [refstereotype, editPoints, setFieldValue]);

  if (!refstereotype) return <></>;
  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <AlertMsg errors={errors} />
          <Stack>{getFieldParams()}</Stack>
          <Divider my={2} />
          <Button
            sx={{ mt: 10 }}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            Valider
          </Button>
        </Form>
      </FormikProvider>
    </>
  );
}
