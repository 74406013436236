import React, { useEffect, useState } from "react";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import styled from "@emotion/styled";
import {
  Alert as MuiAlert,
  Box,
  MenuItem,
  TextField as MuiTextField,
  ListItemText,
  Button,
  Stack,
  Paper,
  Divider as MuiDivider,
  Container,
  Typography,
  Grid,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Helmet } from "react-helmet-async";
import { spacing } from "@mui/system";
import axios from "axios";
import { API_URL } from "config";
import UserProgramTimeLine from "./UserProgramTimeLine";
import UserProgramStereo from "./UserProgramStereo";
// ----------------------------------------------------------------------
const Divider = styled(MuiDivider)(spacing);
const Header = styled.div`
  padding: ${(props) => props.theme.spacing(6)} 0;
`;
export default function UserProgramEditPage() {
  const [editData, seteditData] = useState(null);
  const [isLoaded, setisLoaded] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  useEffect(() => {
    if (!id) return;
    const init = async () => {
      try {
        const res = await axios.get(API_URL.appcode + "/" + id);
        if (res && res.data) {
          seteditData(res.data);
        }
        setisLoaded(true);
      } catch (error) {
        setisLoaded(true);
        console.error(error);
      }
    };
    init();
  }, [id]);
  if (!editData && isLoaded) return <EmptyData />;
  return (
    <>
      <Helmet title="Programme appliqué" />

      {editData && (
        <>
          <Button
            variant="contained"
            color="primary"
            component={NavLink}
            to={`/app/user-edit/${editData.userapp.id}`}
          >
            <ArrowBackIosIcon />
            Retourner au profil d'utilisateur
          </Button>
          <Divider my={6} />
          {editData.userprogram && (
            <Grid container>
              <Grid item xs={6} sm={4} lg={3}>
                <Header>
                  <Typography variant="subtitle1" gutterBottom align="left">
                    {`Points gagnés avec ce programme: ${
                      editData.userprogram.points &&
                      editData.userprogram.points.userpoint
                        ? editData.userprogram.points.userpoint
                        : 0
                    }`}
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom align="left">
                    {`S.Note calculée: ${
                      editData.userprogram.points &&
                      editData.userprogram.points.totalsnote
                        ? editData.userprogram.points.totalsnote
                        : 0
                    }`}
                  </Typography>
                </Header>
              </Grid>
              <Grid item xs={6} sm={8} lg={9}>
                <Header>
                  <Typography variant="h3" gutterBottom align="center">
                    {editData.userprogram.title}
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom align="center">
                    {`Nombre de jours: ${editData.userprogram.numberoffdays}`}
                  </Typography>
                </Header>
              </Grid>
            </Grid>
          )}
          <Grid container spacing={6}>
            <Grid item xs={12} lg={5} xl={4}>
              <UserProgramTimeLine editData={editData} />
            </Grid>
            <Grid item xs={12} lg={7} xl={8}>
              <UserProgramStereo editData={editData} />
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}

const EmptyData = () => {
  const [display, setdisplay] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setdisplay(true);
    }, 2000);
    return () => {
      clearTimeout(timeout);
    };
  }, []);
  return (
    <Container>
      {display && (
        <>
          <Typography variant="h2" component="h3" gutterBottom>
            Le code du programme demandé n'est pas disponible.
          </Typography>
          <Box mb={8} />
          <Grid container spacing={6}>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <Button
                component={NavLink}
                to="/app/users"
                variant="contained"
                color="primary"
              >
                <ArrowBackIosIcon />
                Retourner à la liste des utilisateurs
              </Button>
            </Grid>
          </Grid>
        </>
      )}
    </Container>
  );
};
