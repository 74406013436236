export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const auth0Config = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
};

export const cognitoConfig = {
  userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  clientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
};
const HOST = process.env.REACT_APP_API_URL
  ? process.env.REACT_APP_API_URL
  : "http://localhost:3001/v1";
export const API_URL = {
  login: `${HOST}/auth/login`,
  logout: `${HOST}/auth/logout`,
  authenticateByToken: `${HOST}/auth/me`,
  svgOptim: `${HOST}/svg/optim`,
  patient: `${HOST}/patient`,
  refs: `${HOST}/ref`,
  appconfig: `${HOST}/appconfig`,
  users: `${HOST}/users`,
  userapp: `${HOST}/userapp`,
  appcodes: `${HOST}/appcode`,
  reftheme: `${HOST}/reftheme`,
  refsubject: `${HOST}/refsubject`,
  refstereotype: `${HOST}/refstereotype`,
  getstream: `${HOST}/getstream/by-bo`,
  stereotypechallenge: `${HOST}/stereochallenge`,
  challenge: `${HOST}/challenge`,
  refchallengetype: `${HOST}/refchallengetype`,
  refactionpoint: `${HOST}/refactionpoint`,
  refboostertype: `${HOST}/refboostertype`,
  refreportcontent: `${HOST}/refreportcontent`,
  refgiftcategory: `${HOST}/refgiftcategory`,
  gift: `${HOST}/gift`,
  giftStock: `${HOST}/giftStock`,
  program: `${HOST}/program`,
  dayflow: `${HOST}/dayflow`,
  daychoice: `${HOST}/daychoice`,
  publishedprog: `${HOST}/publishedprog`,
  booster: `${HOST}/booster`,
  appvideo: `${HOST}/appvideo`,
  appmusic: `${HOST}/appmusic`,
  appimage: `${HOST}/appimage`,
  appcode: `${HOST}/appcode`,
  transcript: `${HOST}/transcript`,
  refCoachVoice: `${HOST}/refCoachVoice`,
  manageUserApp:`${HOST}/manageUserApp`,
};
export const maxdaysprog = "maxdaysprog";
export const maxsubjectsbyday = "maxsubjectsbyday";
export const maxchallengesbysubject = "maxchallengesbysubject";
export const refBoosterExe = "63a584080dd85e0820de6ca4";
