import React from "react";

import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import ProgramCreateForm from "./ProgramCreateForm";
// ----------------------------------------------------------------------
export default function ProgramCreateBtn() {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const onComplete = () => {
    setOpen(false);
  };
  return (
    <>
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        <AddIcon />
        Nouveau programme
      </Button>
      <Dialog open={open} fullWidth maxWidth="lg">
        <DialogTitle> Création d'un nouveau programme</DialogTitle>
        <DialogContent>
          <ProgramCreateForm onComplete={onComplete} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Annuler</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
