import { useDispatch, useSelector } from "react-redux";

import axios from "axios";
import { API_URL } from "config";
import {
  startLoading,
  stopLoading,
  initAppvideos,
  initAppmusics,
  initAppimages,
} from "redux/slices/appmediaSlice";

// ----------------------------------------------------------------------

export default function useAppmedia() {
  const dispatch = useDispatch();
  const { appvideos, appmusics, appimages } = useSelector(
    (state) => state.appmedia
  );

  return {
    appvideos,
    appmusics,
    appimages,
    getAppVideos: async () => {
      try {
        dispatch(startLoading());
        const res = await axios.get(API_URL.appvideo);
        if (res && res.data) {
          dispatch(initAppvideos(res.data));
        } else {
          throw new Error("App videos ne sont pas disponibles");
        }
      } catch (error) {
        dispatch(stopLoading());
        console.error(error);
        throw new Error("App videos ne sont pas disponibles");
      }
    },

    getAppMusics: async () => {
      try {
        dispatch(startLoading());
        const res = await axios.get(API_URL.appmusic);
        if (res && res.data) {
          dispatch(initAppmusics(res.data));
        } else {
          throw new Error("App videos ne sont pas disponibles");
        }
      } catch (error) {
        dispatch(stopLoading());
        console.error(error);
        throw new Error("App videos ne sont pas disponibles");
      }
    },
    getAppImages: async () => {
      try {
        dispatch(startLoading());
        const res = await axios.get(API_URL.appimage);
        if (res && res.data) {
          dispatch(initAppimages(res.data));
        } else {
          throw new Error("App videos ne sont pas disponibles");
        }
      } catch (error) {
        dispatch(stopLoading());
        console.error(error);
        throw new Error("App videos ne sont pas disponibles");
      }
    },
    uploadAppMedia: async (formdata, refName) => {
      try {
        await axios.post(API_URL[refName] + "/formdata", formdata);
      } catch (error) {
        dispatch(stopLoading());
        console.error(error);
        throw error;
      }
    },
    saveAppMedia: async (o, refName) => {
      try {
        dispatch(startLoading());
        delete o.modifiedby;
        delete o.createdby;
        await axios.patch(API_URL[refName] + "/" + o.id, o);
        dispatch(stopLoading());
      } catch (error) {
        dispatch(stopLoading());
        console.error(error);
        throw error;
      }
    },
    delMedia: async (o, refName) => {
      try {
        dispatch(startLoading());
        if (o && o.id) {
          await axios.delete(API_URL[refName] + "/" + o.id);
        }
      } catch (error) {
        dispatch(stopLoading());
        console.error();
        throw error;
      }
    },
    updateTranscript: async (model, idModel, field, transcript, isSame) => {
      try {
        const body = {
          model,
          idModel,
          field,
          transcript,
          isSame,
        };
        await axios.post(API_URL.transcript, body);
      } catch (error) {
        console.error();
        throw error;
      }
    },
  };
}
