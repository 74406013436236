import * as React from "react";
import styled from "@emotion/styled";
import {
  Avatar as MuiAvatar,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  Typography,
  Chip as MuiChip,
} from "@mui/material";
import { spacing } from "@mui/system";

// ----------------------------------------------------------------------

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Avatar = styled(MuiAvatar)(spacing);
const Chip = styled(MuiChip)(spacing);

export default function CardDayStereoChallenge({ challengeDay, refModel }) {
  const { questions } = challengeDay;

  const getAvatarStereo = (it) => {
    try {
      const str = it.refstereotype.code.substring(0, 2);
      return str;
    } catch (error) {
      return "";
    }
  };
  if (!questions) return <></>;
  return (
    <>
      <h3>Questions</h3>
      {questions.map((it, i) => {
        const { choices } = it;
        return (
          <Card key={i}>
            <CardContent>
              <Typography mb={4} color="textSecondary" component="h5">
                {`${it.title}`}
              </Typography>
              {choices.map((it, i) => {
                return (
                  <React.Fragment key={i}>
                    <Card mb={1} sx={{ p: 0 }}>
                      <Typography variant="p" gutterBottom sx={{ mx: 6 }}>
                        {it.title}
                      </Typography>
                      {it.pointgained.map((p, i) => {
                        return (
                          <Chip
                            variant="outlined"
                            key={i}
                            avatar={<Avatar>{getAvatarStereo(p)}</Avatar>}
                            label={`${p.refstereotype.label}: ${p.point}`}
                            m={1}
                            color={
                              p.refstereotype.code === "strong"
                                ? "error"
                                : p.refstereotype.code === "medium"
                                ? "warning"
                                : "primary"
                            }
                          />
                        );
                      })}
                    </Card>
                    <Divider my={2} />
                  </React.Fragment>
                );
              })}
            </CardContent>
          </Card>
        );
      })}
    </>
  );
}
