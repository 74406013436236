import React, { useEffect, useState } from "react";
import {
  Button,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  TextField as MuiTextField,
  Stack,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import SubtitlesIcon from "@mui/icons-material/Subtitles";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import useNotification from "hooks/useNotification";
import useAppmedia from "hooks/useAppmedia";
// ----------------------------------------------------------------------
const TextField = styled(MuiTextField)(spacing);
export default function EditFormSubtitle({
  transcripts,
  model,
  idModel,
  field,
  onComplete,
  hideSameThing,
}) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleComplete = () => {
    onComplete();
    handleClose();
  };
  if (!transcripts) return <></>;
  if (!transcripts.results) return <></>;
  return (
    <div>
      <Button
        aria-label="details"
        size="small"
        color="warning"
        onClick={handleClickOpen}
        sx={{ m: 2 }}
      >
        <SubtitlesIcon size="small" />
        Editer le transcript
      </Button>
      <Dialog open={open} fullWidth maxWidth="xl">
        <DialogTitle> Edition du transcript vocal</DialogTitle>
        <DialogContent>
          <FormEdit
            results={transcripts.results}
            model={model}
            idModel={idModel}
            field={field}
            onComplete={handleComplete}
            hideSameThing={hideSameThing}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Annuler</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
const FormEdit = ({
  results,
  model,
  idModel,
  field,
  onComplete,
  hideSameThing,
}) => {
  const [alternatives, setalternatives] = useState([]);
  const { notif } = useNotification();
  const { updateTranscript } = useAppmedia();
  useEffect(() => {
    if (!results) return;
    const arr = [];
    results.forEach((it) => {
      const { alternatives } = it;
      alternatives.forEach((alternative) => {
        const { words } = alternative;
        if (words) {
          words.forEach((word) => {
            arr.push(word);
          });
        }
      });
    });
    setalternatives(arr);
  }, [results]);
  const getDataSchema = () => {
    try {
      if (!Array.isArray(alternatives)) return {};
      const obj = {};
      /*  format item {startTime: '7s', endTime: '7.200s', word: 'fait'} */
      alternatives.forEach((it) => {
        const { startTime, endTime, word } = it;
        const maxLength = word.length + 20;
        const field = fieldString(it);
        obj[field] = Yup.string()
          .max(maxLength)
          .required(
            `Veuillez renseigner les mots au timeline ${startTime}-${endTime}.`
          );
      });
      return obj;
    } catch (error) {
      console.error(error);
      return {};
    }
  };
  const getInitData = () => {
    try {
      if (!Array.isArray(alternatives)) return {};
      const obj = {};
      /*  format item {startTime: '7s', endTime: '7.200s', word: 'fait'} */
      alternatives.forEach((it) => {
        const { startTime, endTime, word } = it;
        const field = fieldString(it);
        obj[field] = word ? word : "";
      });
      return obj;
    } catch (error) {
      console.error(error);
      return {};
    }
  };
  const getTextFields = () => {
    try {
      if (!Array.isArray(alternatives)) return <></>;
      const obj = {};
      /*  format item {startTime: '7s', endTime: '7.200s', word: 'fait'} */
      return (
        <>
          {alternatives.map((it, i) => {
            const { startTime, endTime, word } = it;
            const field = fieldString(it);
            const myLabel = `${startTime}-${endTime}`;

            return (
              <>
                <TextField
                  key={myLabel}
                  type="text"
                  label={myLabel}
                  {...getFieldProps(field)}
                  error={Boolean(touched[field] && errors[field])}
                  helperText={touched[field] && errors[field]}
                  py={2}
                  sx={{ maxWidth: 150 }}
                />
              </>
            );
          })}
        </>
      );
    } catch (error) {
      console.error(error);
      return <></>;
    }
  };
  const dataSchema = Yup.object().shape({
    ...getDataSchema(),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      isSame: true,
      ...getInitData(),
    },
    validationSchema: dataSchema,
    onSubmit: async () => {
      try {
        if (!model) return;
        if (!idModel) return;
        if (!field) return;
        /*  format item {startTime: '7s', endTime: '7.200s', word: 'fait'} */
        const transcript = [];
        Object.entries(values).forEach(([k, v]) => {
          const obj = decodeField(k);
          obj.word = v;
          transcript.push(obj);
        });
        await updateTranscript(
          model,
          idModel,
          field,
          transcript,
          hideSameThing ? false : values.isSame
        );
        onComplete();
      } catch (error) {
        console.error(error);
        notif(null, error);
        setSubmitting(false);
      }
    },
  });

  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setSubmitting,
    handleChange,
  } = formik;
  if (!alternatives) return <></>;
  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack
          spacing={2}
          direction="row"
          alignItems="center"
          sx={{ my: 2, flexWrap: "wrap" }}
        >
          {getTextFields()}
        </Stack>
        {hideSameThing ? (
          <></>
        ) : (
          <FormControlLabel
            control={<Checkbox checked={values.isSame} color="primary" />}
            label="Même transcript pour la voix femelle et male ?"
            name="isSame"
            onChange={handleChange}
          />
        )}
        <Button
          sx={{ my: 2 }}
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          disabled={isSubmitting}
        >
          Valider
        </Button>
      </Form>
    </FormikProvider>
  );
};
const fieldString = (obj) => {
  if (!obj) return null;

  const { startTime, endTime } = obj;
  const str = `${startTime}-${endTime}`;
  return str.split(".").join("_");
};
const decodeField = (str) => {
  if (!str) return null;
  const obj = {};
  const arr = str.split("-");
  arr.forEach((it, i) => {
    const val = it.split("_").join(".");
    if (i === 0) {
      obj.startTime = val;
    } else if (i === 1) {
      obj.endTime = val;
    }
  });

  return obj;
};
