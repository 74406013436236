import { useDispatch, useSelector } from "react-redux";
import { maxsubjectsbyday, maxchallengesbysubject } from "config";
import axios from "axios";
import { API_URL } from "config";
import {
  startLoading,
  stopLoading,
  initPublishedPrograms,
  initAppProgram,
  initPublishProg,
} from "redux/slices/programSlice";

// ----------------------------------------------------------------------

export default function usePublishedProg() {
  const dispatch = useDispatch();
  const { isLoading, isLoaded, appPublishProg, publishedPrograms } =
    useSelector((state) => state.programs);
  const { appconfigs } = useSelector((state) => state.ref);
  return {
    isLoaded,
    isLoading,
    appPublishProg,
    publishedPrograms,
    getPublishedPrograms: async () => {
      try {
        dispatch(startLoading());
        const res = await axios.get(API_URL.publishedprog);
        if (res && res.data) {
          dispatch(initPublishedPrograms(res.data));
        } else {
          dispatch(initPublishedPrograms(null));
          throw new Error("Programmes publiés ne sont pas disponibles");
        }
      } catch (error) {
        dispatch(stopLoading());
        console.error(error);
        throw new Error("Programmes publiés ne sont pas disponibles");
      }
    },
    delPublishProgram: async (o) => {
      try {
        if (!o) return;
        dispatch(startLoading());
        const res = await axios.delete(`${API_URL.publishedprog}/${o.id}`);
      } catch (error) {
        dispatch(stopLoading());
        console.error(error);
        throw error;
      }
    },
    initPublishProgById: async (id) => {
      try {
        dispatch(startLoading());
        const res = await axios.get(`${API_URL.publishedprog}/${id}`);
        if (res && res.data) {
          dispatch(initPublishProg(res.data));
        } else {
          throw new Error("Challenges ne sont pas disponibles");
        }
      } catch (error) {
        dispatch(stopLoading());
        console.error(error);
        throw new Error("Challenges ne sont pas disponibles");
      }
    },
  };
}
