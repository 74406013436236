import { useDispatch, useSelector } from "react-redux";
import { startLoading, initGifts, stopLoading } from "redux/slices/giftSlice";
import axios from "axios";
import { API_URL } from "config";
// ----------------------------------------------------------------------

export default function useDataUser() {
  const dispatch = useDispatch();
  const { gifts, isLoading, isLoaded } = useSelector((state) => state.gift);

  return {
    gifts,
    isLoaded,
    isLoading,
    getGifts: async () => {
      try {
        dispatch(startLoading());
        const res = await axios.get(API_URL.gift);
        if (res && res.data) {
          dispatch(initGifts([...res.data]));
        } else {
          throw new Error("Cadeaux ne sont pas disponibles");
        }
      } catch (error) {
        dispatch(stopLoading());
        console.error();
        throw new Error("Cadeaux ne sont pas disponibles");
      }
    },

    getGiftById: async (id) => {
      try {
        const res = await axios.get(API_URL.gift + "/" + id);
        if (res && res.data) {
          return res.data;
        } else {
          throw new Error("UsersData ne sont pas disponibles");
        }
      } catch (error) {
        console.error();
        throw new Error("Référentiels ne sont pas disponibles");
      }
    },
    saveGift: async (o) => {
      try {
        delete o.createdby;
        delete o.modifiedby;
        if (o && o.id) {
          const res = await axios.patch(API_URL.gift + "/" + o.id, o);
        } else {
          const res = await axios.post(API_URL.gift, o);
        }
      } catch (error) {
        dispatch(stopLoading());
        console.error();
        throw error;
      }
    },
    saveGiftFormData: async (o, formdata) => {
      try {
        dispatch(startLoading());
        delete o.modifiedby;
        delete o.createdby;
        if (o && o.id) {
          await axios.patch(API_URL.gift + "/formdata/" + o.id, formdata);
        } else {
          await axios.post(API_URL.gift + "/formdata", formdata);
        }
      } catch (error) {
        dispatch(stopLoading());
        console.error();
        throw error;
      }
    },
    delGift: async (o) => {
      try {
        dispatch(startLoading());
        if (o && o.id) {
          await axios.delete(API_URL.gift + "/" + o.id);
        }
      } catch (error) {
        dispatch(stopLoading());
        console.error();
        throw error;
      }
    },
    delImgFromGallery: async (o, img) => {
      try {
        dispatch(startLoading());
        if (o && o.id) {
          await axios.delete(API_URL.gift + "/" + o.id + "/galleryimg/" + img);
        }
      } catch (error) {
        dispatch(stopLoading());
        console.error();
        throw error;
      }
    },
    getStockByGift: async (idGift) => {
      try {
        const res = await axios.get(API_URL.giftStock + "/byGift/" + idGift);
        if (res && res.data) {
          return res.data;
        } else {
          throw new Error("UsersData ne sont pas disponibles");
        }
      } catch (error) {
        console.error();
        throw new Error("Référentiels ne sont pas disponibles");
      }
    },
    saveGiftStock: async (o) => {
      try {
        delete o.createdby;
        delete o.modifiedby;
        if (o && o.id) {
          const res = await axios.patch(API_URL.giftStock + "/" + o.id, o);
        } else {
          const res = await axios.post(API_URL.giftStock, o);
        }
      } catch (error) {
        dispatch(stopLoading());
        console.error();
        throw error;
      }
    },
  };
}
