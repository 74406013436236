import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { rgba } from "polished";

import {
  Box,
  Grid,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Chip as MuiChip,
  Typography as MuiTypography,
} from "@mui/material";
import { spacing } from "@mui/system";

// ----------------------------------------------------------------------

const illustrationCardStyle = (props) => css`
  ${props.illustration &&
  props.theme.palette.mode !== "dark" &&
  `
    background: ${rgba(props.theme.palette.primary.main, 0.125)};
    color: ${props.theme.palette.primary.main};
  `}
`;

const Card = styled(MuiCard)`
  position: relative;
  margin-bottom: ${(props) => props.theme.spacing(6)};

  ${illustrationCardStyle}
`;

const Typography = styled(MuiTypography)(spacing);

const CardContent = styled(MuiCardContent)`
  position: relative;

  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(4)};
  }
`;

const Chip = styled(MuiChip)`
  position: absolute;
  top: 16px;
  right: 16px;
  height: 20px;
  padding: 4px 0;
  font-size: 85%;

  margin-bottom: ${(props) => props.theme.spacing(4)};
  span {
    padding-left: ${(props) => props.theme.spacing(2)};
    padding-right: ${(props) => props.theme.spacing(2)};
  }
`;

const PointInfo = ({ title, amount, chip, illustration, refstereotype }) => {
  const getColor = () => {
    try {
      if (refstereotype.code === "low") {
        return "primary";
      } else if (refstereotype.code === "medium") {
        return "warning";
      } else {
        return "error";
      }
    } catch (error) {
      return null;
    }
  };
  return (
    <Card illustration={illustration}>
      <CardContent>
        <Typography variant="h6" mb={4}>
          {title}
        </Typography>
        <Typography variant="h3" mb={3}>
          <Box fontWeight="fontWeightRegular">{amount}</Box>
        </Typography>
        <Chip label={refstereotype.label} color={getColor()} />
      </CardContent>
    </Card>
  );
};

export default function PointgainedInfo({ editPoints }) {
  if (!Array.isArray(editPoints)) return <></>;
  return (
    <Grid container spacing={6}>
      {editPoints.map((it, i) => {
        return (
          <Grid key={i} item xs={12} sm={12} md={6} lg={3} xl>
            <PointInfo
              title={it.awardmessage}
              amount={it.point}
              refstereotype={it.refstereotype}
            />
          </Grid>
        );
      })}
    </Grid>
  );
}
