import React from "react";
import styled from "@emotion/styled";
import {
  Avatar as MuiAvatar,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid as MuiGrid,
  LinearProgress as MuiLinearProgress,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography as MuiTypography,
  Paper,
  Stack,
  IconButton,
  Popover,
  CardActions,
  Button,
} from "@mui/material";
import { spacing } from "@mui/system";
import { Delete as DeleteIcon } from "@mui/icons-material";
import StereoChalQuestionEditBtn from "./StereoChalQuestionEditBtn";
// ----------------------------------------------------------------------
const Card = styled(MuiCard)(spacing);

const Chip = styled(MuiChip)(spacing);

const Grid = styled(MuiGrid)(spacing);

const Typography = styled(MuiTypography)(spacing);

const ProductsChip = styled(Chip)`
  height: 20px;
  padding: 4px 0;
  font-size: 90%;
  background-color: ${(props) =>
    props.theme.palette[props.color ? props.color : "primary"].light};
  color: ${(props) => props.theme.palette.common.white};
`;

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;

const Divider = styled(MuiDivider)(spacing);

export default function StereoChalQuestions({
  questions,
  handleCompleteQuestion,
  handleDelQuestion,
}) {
  if (!Array.isArray(questions)) return <></>;
  return (
    <Grid container spacing={6}>
      <Grid item xs={12} lg={8} xl={9}>
        <Card mb={6}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Liste des questionnaires
            </Typography>
            <TableWrapper>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Question</TableCell>
                    <TableCell>Réponses</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {questions.map((it, i) => {
                    return (
                      <TableRow
                        key={i}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {it.title}
                        </TableCell>
                        <TableCell>
                          <ProductsChip
                            label={`${it.choices.length} choix`}
                            color="primary"
                          />
                        </TableCell>
                        <TableCell>
                          <Stack
                            direction="row"
                            divider={
                              <Divider orientation="vertical" flexItem />
                            }
                            spacing={2}
                          >
                            <Item>
                              <StereoChalQuestionEditBtn
                                editData={it}
                                handleCompleteQuestion={handleCompleteQuestion}
                              />
                            </Item>
                            <Item>
                              <DeleteQuestionBtn
                                editData={it}
                                handleDelete={handleDelQuestion}
                              />
                            </Item>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableWrapper>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
const Item = styled(Paper)(({ theme }) => ({
  //backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const DeleteQuestionBtn = ({ editData, handleDelete }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleConfirm = () => {
    handleDelete(editData);
    setAnchorEl(null);
  };
  if (!editData) return;

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <>
      <IconButton
        aria-label="details"
        size="small"
        color="error"
        variant="contained"
        onClick={handleClick}
      >
        <DeleteIcon size="small" />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Card
          sx={{
            minWidth: 275,
            backgroundColor: "#1A2027",
          }}
        >
          <CardContent>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              Etes vous sur de supprimer ?
            </Typography>
          </CardContent>
          <CardActions sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button onClick={handleConfirm}>Valider</Button>
            <Button onClick={handleClose}>Annuler</Button>
          </CardActions>
        </Card>
      </Popover>
    </>
  );
};
