import React, { forwardRef } from "react";
import { Radio } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
// ----------------------------------------------------------------------
const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#3C60B5",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#106ba3",
  },
});

const RadioBtnStyled = forwardRef(
  (
    { color = "primary", variant = "text", children, className, ...other },
    ref
  ) => {
    if (color === "inherit" || color === "primary" || color === "secondary") {
      return (
        <Radio
          ref={ref}
          color={color}
          variant={variant}
          className={className}
          {...other}
          checkedIcon={<BpCheckedIcon />}
          icon={<BpIcon />}
        >
          {children}
        </Radio>
      );
    }

    return (
      <Radio
        ref={ref}
        variant={variant}
        {...other}
        checkedIcon={<BpCheckedIcon />}
        icon={<BpIcon />}
      >
        {children}
      </Radio>
    );
  }
);
export default RadioBtnStyled;
