import {
  BookOpen,
  Settings,
  Briefcase,
  Calendar,
  CheckSquare,
  CreditCard,
  Grid,
  Heart,
  Layout,
  List,
  Map,
  ShoppingCart,
  PieChart,
  Sliders,
  Users,
  ShoppingBag,
  Airplay,
  Gift,
} from "react-feather";

const pagesSection = [
  {
    href: "/app/users",
    icon: Users,
    title: "Utilisateurs",
    children: [
      {
        href: "/app/users",
        title: "Tous",
      },
    ],
  },
  {
    href: "/pages",
    icon: Layout,
    title: "Codes d'application",
    children: [
      {
        href: "/app/appcodes",
        title: "Codes",
      },
    ],
  },
];

const refsSection = [
  {
    icon: Settings,
    title: "Programmes",
    children: [
      {
        href: "/app/refs-theme",
        title: "Thèmes",
      },
      {
        href: "/app/refs-subject",
        title: "Sujets",
      },
      {
        href: "/app/refs-stereotype",
        title: "Stereotype",
      },
      {
        href: "/app/refs-refchallengetype",
        title: "Type de défi",
      },
      {
        href: "/app/refs-refboostertype",
        title: "Type de booster",
      },
      {
        href: "/app/refs-actionpoint",
        title: "Bomba coins",
      },
      {
        href: "/app/refs-reportcontent",
        title: "Type de contenu signalé",
      },
      {
        href: "/app/refs-coachvoices",
        title: "Voix des coachs",
      },
    ],
  },
  {
    icon: Gift,
    title: "Gifts",
    children: [
      {
        href: "/app/refs-giftcategory",
        title: "Type de coupon cadeau",
      },
    ],
  },
];
const bombaMediaSection = [
  {
    icon: ShoppingBag,
    title: "Audio visuel",
    children: [
      {
        href: "/app/library-video",
        title: "Vidéos",
      },
      {
        href: "/app/library-music",
        title: "Musiques",
      },
      {
        href: "/app/library-image",
        title: "Images",
      },
    ],
  },
];

const progSection = [
  {
    icon: BookOpen,
    title: "App. programmes",
    children: [
      {
        href: "/app/prog-stereotypechallenge",
        title: "Stereotype challenge",
      },
      {
        href: "/app/prog-challenge",
        title: "Challenges",
      },
      {
        href: "/app/program",
        title: "Programmes",
      },
      {
        href: "/app/prog-booster",
        title: "Boosters",
      },
    ],
  },
  {
    href: "/pages",
    icon: Airplay,
    title: "Prog. publiés",
    children: [
      {
        href: "/app/publish-enable",
        title: "Activés",
      },
    ],
  },
];
const giftSection = [
  {
    icon: ShoppingCart,
    title: "Cadeaux",
    children: [
      {
        href: "/app/gift",
        title: "Tous les cadeaux",
      },
    ],
  },
];
const navItems = [
  {
    title: "Utilisateurs et codes",
    pages: pagesSection,
  },
  {
    title: "Bomba Médiatèque",
    pages: bombaMediaSection,
  },
  {
    title: "Référentiels",
    pages: refsSection,
  },
  {
    title: "Gestion des programmes",
    pages: progSection,
  },

  {
    title: "Boutique",
    pages: giftSection,
  },
  /* {
    title: "BombaLife Pro",
    pages: docsSection,
  }, */
];

export default navItems;
