import React, { useState } from "react";

import {
  Button,
  IconButton,
  Card,
  Popover,
  CardActions,
  CardContent,
  Typography,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import useProgram from "hooks/useProgram";
import useNotification from "hooks/useNotification";
// ----------------------------------------------------------------------
export default function ProgramCopyBtn({ editData, onComplete }) {
  const { getPrograms, cloneProgram } = useProgram();
  const { notif } = useNotification();
  const [submitting, setsubmitting] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleConfirm = async () => {
    try {
      setsubmitting(true);
      await cloneProgram(editData);
      await getPrograms();
      notif("Copie réussie");
      setsubmitting(false);
      setAnchorEl(null);
      onComplete();
    } catch (error) {
      console.error(error);
      notif(null, error);
      setsubmitting(false);
    }
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <Button
        aria-label="details"
        size="small"
        onClick={handleClick}
        color="warning"
      >
        <ContentCopyIcon /> Dupliquer
      </Button>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Card
          sx={{
            minWidth: 275,
            backgroundColor: "#1A2027",
          }}
        >
          <CardContent>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              Etes vous sur de copier ?
            </Typography>
          </CardContent>
          <CardActions sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button disabled={submitting} onClick={handleConfirm}>
              Valider
            </Button>
            <Button onClick={handleClose}>Annuler</Button>
          </CardActions>
        </Card>
      </Popover>
    </div>
  );
}
