import React, { useEffect, useState } from "react";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import styled from "@emotion/styled";
import {
  Alert as MuiAlert,
  Box,
  MenuItem,
  TextField as MuiTextField,
  ListItemText,
  Button,
  Stack,
  Paper,
  Divider as MuiDivider,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import CircularProgress from "@mui/material/CircularProgress";
import { Helmet } from "react-helmet-async";
import { spacing } from "@mui/system";
import useNotification from "hooks/useNotification";
import useReferentiel from "hooks/useReferentiel";
import UploadSingleFile from "components/file/UploadSingleFile";
import ImgBoxFromUrl from "components/media/ImgBoxFromUrl";
import AudioBoxFromUrl from "components/media/AudioBoxFromUrl";
import { API_URL } from "config";
import useChallenge from "hooks/useChallenge";
import BackBtn from "components/custom/BackBtn";
import AlertMsg from "components/custom/AlertMsg";
import { getMediaUrl } from "utils/objectUtil";
import EditFormSubtitle from "components/media/EditFormSubtitle";
import StereoChalQuestions from "./StereoChalQuestions";
import StereoChalQuestionEditBtn from "./StereoChalQuestionEditBtn";
// ----------------------------------------------------------------------
const Divider = styled(MuiDivider)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Item = styled(Paper)(({ theme }) => ({
  //backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
const REF_MODEL = "stereotypechallenge";
export default function StereoChallengeEditPage() {
  const navigate = useNavigate();
  const { id } = useParams();

  const {
    findStereoChallengeById,
    getStereotypeChallenges,
    saveChallenge,
    saveChallengeFormData,
  } = useChallenge();
  const { notif } = useNotification();
  const { refs } = useReferentiel();
  const [editData, seteditdata] = useState(null);
  const [editQuestions, seteditQuestions] = useState([]);
  useEffect(() => {
    if (!id || id === "add") return;
     init();
  }, [id]);

  const init = async () => {
    try {
      const res = await findStereoChallengeById(id);
      if (res) {
        seteditdata(res);
      }
    } catch (error) {
      console.error();
    }
  };
  useEffect(() => {
    if (!editData) return;
    if (!editData.questions) return;
    seteditQuestions(editData.questions);
  }, [editData]);

  const dataSchema = Yup.object().shape({
    code: Yup.string().max(20).required("Veuillez renseigner le code"),
    title: Yup.string().max(100).required(`Veuillez renseigner le titre`),
    refsubject: Yup.string().max(100).required(`Veuillez renseigner le sujet`),
    image:
      editData && editData.id
        ? Yup.string()
        : Yup.mixed().required("Veuillez renseigner l'image"),
    bgimage:
      editData && editData.id
        ? Yup.string()
        : Yup.mixed().required("Veuillez renseigner l'image de fond"),
    femalevoice:
      editData && editData.id
        ? Yup.string()
        : Yup.mixed().required("Veuillez renseigner la voix féminine"),
    malevoice:
      editData && editData.id
        ? Yup.string()
        : Yup.mixed().required("Veuillez renseigner la voix masculine"),
    questions: Yup.array()
      .required("Veuillez renseigner les questionnaires")
      .min(1, "Les questionnaires de réponse ne sont pas valides")
      .max(50, "Les questionnaires de réponse ne sont pas valides"),
  });
  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      code: editData && editData.code ? editData.code : "",
      title: editData && editData.title ? editData.title : "",
      refsubject: editData && editData.refsubject ? editData.refsubject.id : "",
      questions: "",
    },
    validationSchema: dataSchema,
    onSubmit: async () => {
      try {
        const newData =
          editData && editData.id ? { ...editData, ...values } : { ...values };
        /* in case file upload is choiced we need to make formdata */
        const isFormdata =
          values.image ||
          values.bgimage ||
          values.femalevoice ||
          values.malevoice;
        if (isFormdata) {
          const formData = new FormData();
          delete newData.modifiedby;
          delete newData.createdby;
          if (values.image) {
            delete newData.image;
            formData.append("image", values.image);
          }
          if (values.bgimage) {
            delete newData.bgimage;
            formData.append("bgimage", values.bgimage);
          }
          if (values.femalevoice) {
            delete newData.femalevoice;
            formData.append("femalevoice", values.femalevoice);
          }
          if (values.malevoice) {
            delete newData.malevoice;
            formData.append("malevoice", values.malevoice);
          }
          Object.entries(newData).forEach(([e, k]) => {
            if (e === "questions") {
              const questionsTransformed = k.map((it) => {
                const choices = it.choices.map((c) => {
                  const pointgained = c.pointgained.map((p) => ({
                    refstereotype: { ...p.refstereotype },
                    point: p.point,
                  }));
                  return {
                    title: c.title,
                    pointgained,
                  };
                });
                return {
                  ...it,
                  choices,
                };
              });
              formData.append(e, JSON.stringify(questionsTransformed));
            } else {
              formData.append(e, k);
            }
          });

          await saveChallengeFormData(newData, formData, REF_MODEL);
        } else {
          await saveChallenge(
            {
              ...newData,
            },
            REF_MODEL
          );
        }
        await getStereotypeChallenges();
        notif("Sauvegarde réussie");
        navigate("/app/prog-stereotypechallenge");
      } catch (error) {
        console.error(error);
        notif(null, error);
        setSubmitting(false);
      }
    },
  });

  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setSubmitting,
    handleChange,
    setFieldValue,
  } = formik;

  useEffect(() => {
    if (!setFieldValue) return;
    if (!editQuestions) return;
    setFieldValue("questions", editQuestions);
  }, [editQuestions, setFieldValue]);

  if (!refs) return;
  const { refsubject } = refs;
  const handleCompleteQuestion = (data) => {
    try {
      const newQuestions = [...editQuestions];
      const questionIndexFounded = newQuestions.findIndex(
        (it) => it.id === data.id
      );
      if (questionIndexFounded > -1) {
        newQuestions[questionIndexFounded] = data;
      } else {
        newQuestions.push(data);
      }
      seteditQuestions(newQuestions);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDelQuestion = (data) => {
    try {
      const newQuestions = [...editQuestions].filter((it) => it.id !== data.id);
      seteditQuestions(newQuestions);
    } catch (error) {
      console.error(error);
    }
  };
  if (!refsubject) return <></>;
  return (
    <>
      <Helmet title="Edition Stereotype programme" />
      <BackBtn
        backUrl="/app/prog-stereotypechallenge"
        needConfirm={true}
        titleConfim="Demande de confirmation"
        msgConfirm="Des données saisies pourraient ne pas être enregistrées.. Voulez-vous quitter cette page ?"
      />
      <Divider my={6} />
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <AlertMsg errors={errors} />
          <TextField
            fullWidth
            type="text"
            label="Code"
            {...getFieldProps("code")}
            error={Boolean(touched.code && errors.code)}
            helperText={touched.code && errors.code}
            my={2}
          />
          <TextField
            fullWidth
            type="text"
            name="title"
            label="Titre"
            {...getFieldProps("title")}
            error={Boolean(touched.title && errors.title)}
            helperText={touched.title && errors.title}
            my={2}
          />

          <TextField
            fullWidth
            select
            label="Sujet"
            value={values.refsubject}
            onChange={handleChange("refsubject")}
            {...getFieldProps("refsubject")}
            error={Boolean(touched.refsubject && errors.refsubject)}
            helperText={touched.refsubject && errors.refsubject}
            my={2}
          >
            {refsubject.map((option, i) => (
              <MenuItem key={i} value={option.id}>
                {`${option.code}-${option.label}`}
              </MenuItem>
            ))}
          </TextField>
          <Stack
            alignItems="center"
            justifyContent="flex-start"
            sx={{ my: 6 }}
            direction="row"
            spacing={4}
          >
            {editData && editData.image && (
              <Item>
                <ImgBoxFromUrl
                  image={getMediaUrl(editData, "image", "stereotypechallenge")}
                  sx={{
                    borderRadius: 1,
                    objectFit: "cover",
                    width: "150px",
                    height: "auto",
                  }}
                />
              </Item>
            )}
            <Item>
              <UploadSingleFile
                value={values.image}
                label="Image d'illustration"
                onChange={(val) => setFieldValue("image", val)}
                caption="(Les formats *.jpeg and *.png sont acceptés)"
                error={Boolean(touched.image && errors.image)}
                sx={{ maxHeight: "150px" }}
                acceptFile={{
                  accept: {
                    "image/jpeg": [".jpeg", ".png"],
                  },
                }}
                mediaType="image"
              />
            </Item>
          </Stack>
          <Stack
            alignItems="center"
            justifyContent="flex-start"
            sx={{ my: 6 }}
            direction="row"
            spacing={4}
          >
            {editData && editData.bgimage && (
              <Item>
                <ImgBoxFromUrl
                  image={getMediaUrl(
                    editData,
                    "bgimage",
                    "stereotypechallenge"
                  )}
                  sx={{
                    borderRadius: 1,
                    objectFit: "cover",
                    width: "150px",
                    height: "auto",
                  }}
                />
              </Item>
            )}
            <Item>
              <UploadSingleFile
                value={values.bgimage}
                label="Image de fond"
                onChange={(val) => setFieldValue("bgimage", val)}
                caption="(Les formats *.jpeg and *.png sont acceptés)"
                error={Boolean(touched.bgimage && errors.bgimage)}
                sx={{ maxHeight: "150px" }}
                acceptFile={{
                  accept: {
                    "image/jpeg": [".jpeg", ".png"],
                  },
                }}
                mediaType="image"
              />
            </Item>
          </Stack>
          <Stack
            alignItems="center"
            justifyContent="flex-start"
            sx={{ my: 6 }}
            direction="row"
            spacing={4}
          >
            {editData && editData.femalevoice && (
              <Item>
                <AudioBoxFromUrl
                  audio={getMediaUrl(
                    editData,
                    "femalevoice",
                    "stereotypechallenge"
                  )}
                />
                {editData.femaletranscripts && (
                  <EditFormSubtitle
                    transcripts={editData.femaletranscripts}
                    model={REF_MODEL}
                    field="femaletranscripts"
                    idModel={editData.id}
                    onComplete={init}
                  />
                )}
              </Item>
            )}
            <Item>
              <UploadSingleFile
                value={values.femalevoice}
                label="Voix féminine"
                onChange={(val) => setFieldValue("femalevoice", val)}
                caption="(Les formats *.mp3 sont acceptés)"
                error={Boolean(touched.femalevoice && errors.femalevoice)}
                sx={{ maxHeight: "150px" }}
                acceptFile={{
                  accept: {
                    "audio/mp3": [".mp3"],
                  },
                }}
                mediaType="audio"
              />
            </Item>
          </Stack>
          <Stack
            alignItems="center"
            justifyContent="flex-start"
            sx={{ my: 6 }}
            direction="row"
            spacing={4}
          >
            {editData && editData.malevoice && (
              <Item>
                <AudioBoxFromUrl
                  audio={getMediaUrl(
                    editData,
                    "malevoice",
                    "stereotypechallenge"
                  )}
                />
                 {editData.maletranscripts && (
                  <EditFormSubtitle
                    transcripts={editData.maletranscripts}
                    model={REF_MODEL}
                    field="maletranscripts"
                    idModel={editData.id}
                    onComplete={init}
                  />
                )}
              </Item>
            )}
            <Item>
              <UploadSingleFile
                value={values.malevoice}
                label="Voix masculine"
                onChange={(val) => setFieldValue("malevoice", val)}
                caption="(Les formats *.mp3 sont acceptés)"
                error={Boolean(touched.malevoice && errors.malevoice)}
                sx={{ maxHeight: "150px" }}
                acceptFile={{
                  accept: {
                    "audio/mp3": [".mp3"],
                  },
                }}
                mediaType="audio"
              />
            </Item>
          </Stack>
          <Divider my={3} />
          <StereoChalQuestionEditBtn
            addLabel="Nouveau questionnaire"
            handleCompleteQuestion={handleCompleteQuestion}
          />
          <StereoChalQuestions
            questions={editQuestions}
            handleCompleteQuestion={handleCompleteQuestion}
            handleDelQuestion={handleDelQuestion}
          />
          <Button
            sx={{ my: 2 }}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
            endIcon={
              isSubmitting ? (
                <CircularProgress size={20} />
              ) : (
                <DoneIcon size={20} />
              )
            }
          >
            Valider
          </Button>
        </Form>
      </FormikProvider>
    </>
  );
}
