import { useState } from "react";
import { CircularProgress, Button } from "@mui/material";
import ListAltIcon from "@mui/icons-material/ListAlt";
import useDataUser from "hooks/useDataUser";
import useNotification from "hooks/useNotification";
// ----------------------------------------------------------------------

export default function DownloadUserPointsBtn({ idUser }) {
  const [loading, setloading] = useState(false);
  const { downloadUserPoints } = useDataUser();
  const { notif } = useNotification();
  const handleDownload = async () => {
    try {
      setloading(true);
      await downloadUserPoints(idUser);
    } catch (error) {
      notif(null, error);
    }
    setloading(false);
  };
  return (
    <Button
      variant="outlined"
      color={idUser ? "warning" : "error"}
      onClick={handleDownload}
      disabled={loading}
    >
      {loading ? (
        <CircularProgress size="1rem" color="secondary" sx={{ mx: 2 }} />
      ) : (
        <ListAltIcon sx={{ mx: 2 }} />
      )}
      {idUser ? "Points CSV" : "Rapport des points"}
    </Button>
  );
}
