import { createSlice } from "@reduxjs/toolkit";
// ----------------------------------------------------------------------

const initialChalFilters = {
  theme: "",
  subject: "",
  type: "",
  stereotype: "",
  updatedAt: null,
};
const initialState = {
  isLoading: false,
  isLoaded: false,
  publishedPrograms: [],
  programs: [],
  boosters: [],
  stereotypechallenges: [],
  challenges: [],
  chalFilters: { ...initialChalFilters },
  appProgram: {},
  appPublishProg: {},
  maxSubjsByDay: 3,
  challengesNumberBySubj: 3,
};
const progamSlice = createSlice({
  name: "program",
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
      state.isLoaded = false;
    },
    stopLoading(state) {
      state.isLoading = false;
      state.isLoaded = true;
    },
    initAppProgram(state, action) {
      state.isLoading = false;
      state.appProgram = action.payload;
      state.isLoaded = true;
    },
    initPrograms(state, action) {
      state.isLoading = false;
      state.programs = action.payload;
      state.isLoaded = true;
    },
    initPublishProg(state, action) {
      state.isLoading = false;
      state.appPublishProg = action.payload;
      state.isLoaded = true;
    },
    initPublishedPrograms(state, action) {
      state.isLoading = false;
      state.publishedPrograms = action.payload;
      state.isLoaded = true;
    },
    initStereoChallenges(state, action) {
      state.isLoading = false;
      state.stereotypechallenges = action.payload;
      state.isLoaded = true;
    },
    initBoosters(state, action) {
      state.isLoading = false;
      state.boosters = action.payload;
      state.isLoaded = true;
    },
    initChallenges(state, action) {
      state.isLoading = false;
      state.challenges = action.payload;
      state.isLoaded = true;
    },
    setChalFilters(state, action) {
      state.chalFilters = action.payload;
    },
    initSubjsNumberByDay(state, action) {
      state.maxSubjsByDay = action.payload;
    },
    initChallengesNumberBySubj(state, action) {
      state.challengesNumberBySubj = action.payload;
    },
  },
});
export const {
  startLoading,
  stopLoading,
  initPrograms,
  initPublishedPrograms,
  initStereoChallenges,
  initAppProgram,
  initPublishProg,
  initChallenges,
  initSubjsNumberByDay,
  initChallengesNumberBySubj,
  initBoosters,
  setChalFilters,
} = progamSlice.actions;
const programReducer = progamSlice.reducer;

export default programReducer;
