import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  IconButton,
  Card,
  Popover,
  CardActions,
  CardContent,
  Typography,
  Box,
  MenuItem,
  TextField as MuiTextField,
  Stack,
  Paper,
  Divider as MuiDivider,
} from "@mui/material";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import { spacing } from "@mui/system";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import styled from "@emotion/styled";
import useNotification from "hooks/useNotification";
import useGift from "hooks/useGift";
// ----------------------------------------------------------------------
export default function GiftStockBtn({ editData, stock, onComplete }) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleConfirm = async () => {
    try {
      console.log("coool");
    } catch (error) {
      console.error(error);
    }
  };
  const handleComplete = () => {
    onComplete();
    handleClose();
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <Button variant="contained" color="warning" onClick={handleClick}>
        {stock && stock.id ? `${stock.currentStock} unité(s)` : ""} Stock
        <AddBusinessIcon sx={{ mx: 3 }} />
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Card
          sx={{
            minWidth: 275,
            backgroundColor: "#1A2027",
          }}
        >
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              Gérer le stock du cadeau
            </Typography>

            <EditForm
              gift={editData}
              editData={stock}
              onComplete={handleComplete}
            />
          </CardContent>
          <CardActions sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button onClick={handleConfirm}>Valider</Button>
            <Button onClick={handleClose}>Annuler</Button>
          </CardActions>
        </Card>
      </Popover>
    </>
  );
}
// ----------------------------------------------------------------------
const Divider = styled(MuiDivider)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Item = styled(Paper)(({ theme }) => ({
  //backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
// ----------------------------------------------------------------------

const EditForm = ({ gift, editData, onComplete }) => {
  const { notif } = useNotification();
  const { getGifts, saveGiftStock } = useGift();
  const dataSchema = Yup.object().shape({
    currentStock: Yup.number()
      .max(1000, "Le stock ne devrait pas dépasser 1000")
      .required("Veuillez renseigner le stock"),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      currentStock:
        editData && editData.currentStock ? editData.currentStock : "",
    },
    validationSchema: dataSchema,
    onSubmit: async () => {
      try {
        const newValues = editData
          ? { ...editData, ...values }
          : { gift: gift.id, ...values };
        await saveGiftStock(newValues);
        await getGifts();
        notif("Sauvegarde réussie");
        onComplete();
      } catch (error) {
        console.error(error);
        notif(null, error);
        setSubmitting(false);
      }
    },
  });
  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setSubmitting,
    handleChange,
    setFieldValue,
  } = formik;
  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <TextField
          fullWidth
          type="number"
          label="Stock en cours"
          {...getFieldProps("currentStock")}
          error={Boolean(touched.currentStock && errors.currentStock)}
          helperText={touched.currentStock && errors.currentStock}
          my={2}
        />
        <Button
          sx={{ my: 2 }}
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          disabled={isSubmitting}
        >
          Valider
        </Button>
      </Form>
    </FormikProvider>
  );
};
