import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import styled from "@emotion/styled";
import {
  Checkbox,
  Button,
  MenuItem,
  TextField as MuiTextField,
  ListItemText,
  Grid,
} from "@mui/material";
import { spacing } from "@mui/system";
import useNotification from "hooks/useNotification";
import useAppmedia from "hooks/useAppmedia";
import VideoBoxFromUrl from "components/media/VideoBoxFromUrl";
import { getMediaUrl } from "utils/objectUtil";
// ----------------------------------------------------------------------
const TextField = styled(MuiTextField)(spacing);
export default function AppVideoForm({ editData, onComplete, refName }) {
  const { saveAppMedia, getAppVideos } = useAppmedia();
  const { notif } = useNotification();
  const dataSchema = Yup.object().shape({
    name: Yup.string()
      .max(20)
      .required("Veuillez renseigner le nom du fichier"),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: editData && editData.name ? editData.name : "",
    },
    validationSchema: dataSchema,
    onSubmit: async () => {
      try {
        const newData =
          editData && editData.id ? { ...editData, ...values } : { ...values };
        await saveAppMedia(
          {
            ...newData,
          },
          refName
        );
        await getAppVideos();
        notif("Sauvegarde réussie");
        onComplete();
      } catch (error) {
        console.error(error);
        notif(null, error);
        setSubmitting(false);
      }
    },
  });

  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setSubmitting,
  } = formik;
  if (!refName) return <></>;
  return (
    <Grid container sx={{ width: "100%" }} spacing={4}>
      <Grid item xs={12} sm={8} md={6}>
        <VideoBoxFromUrl video={getMediaUrl(editData, "filepath", refName)} />
      </Grid>
      <Grid item xs={12} sm={4} md={6}>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <TextField
              fullWidth
              type="text"
              label="Nom du fichier"
              {...getFieldProps("name")}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
              my={2}
            />

            <Button
              sx={{ my: 2 }}
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isSubmitting}
            >
              Valider
            </Button>
          </Form>
        </FormikProvider>
      </Grid>
    </Grid>
  );
}
