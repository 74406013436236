import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  isLoaded: false,
  appvideos: [],
  appmusics: [],
  appimages: [],
};
const appmediaSlice = createSlice({
  name: "appmedia",
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
      state.isLoaded = false;
    },
    stopLoading(state) {
      state.isLoading = false;
      state.isLoaded = true;
    },
    initAppvideos(state, action) {
      state.isLoading = false;
      state.appvideos = action.payload;
      state.isLoaded = true;
    },
    initAppmusics(state, action) {
      state.isLoading = false;
      state.appmusics = action.payload;
      state.isLoaded = true;
    },
    initAppimages(state, action) {
      state.isLoading = false;
      state.appimages = action.payload;
      state.isLoaded = true;
    },
  },
});
export const {
  startLoading,
  stopLoading,
  initAppvideos,
  initAppmusics,
  initAppimages,
} = appmediaSlice.actions;
const appmediaReducer = appmediaSlice.reducer;

export default appmediaReducer;
