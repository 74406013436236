import React, { useEffect, useState } from "react";
import { CardMedia as MuiCardMedia } from "@mui/material";
import styled from "@emotion/styled";
import axios from "axios";

// ----------------------------------------------------------------------
const CardMedia = styled(MuiCardMedia)`
  height: 220px;
`;
export default function CardMediaFromUrl({ image, title, sx }) {
  const [imageObj, setimageObj] = useState(null);

  useEffect(() => {
    return () => {
      setimageObj(null);
    };
  }, []);

  useEffect(() => {
    if (!image) return;
    const init = async () => {
      try {
        const response = await axios.get(`${image}`, {
          responseType: "arraybuffer",
        });
        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });

        const imageDataUrl = URL.createObjectURL(blob);
        setimageObj(imageDataUrl);
      } catch (error) {
        console.error(error);
      }
    };
    init();
  }, [image]);
  if (!imageObj) return <></>;
  return (
    <>
      <CardMedia image={imageObj} title={title} sx={sx} />
    </>
  );
}
