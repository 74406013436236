import React, { useEffect, useState, useMemo } from "react";
import { NavLink, useParams } from "react-router-dom";
import styled from "@emotion/styled";
import {
  Box,
  TextField as MuiTextField,
  Button,
  Stack,
  Paper,
  Divider as MuiDivider,
  Container,
  Typography,
  Grid,
  Tabs,
  Tab,
  Card,
  CardContent,
} from "@mui/material";
import WidgetsIcon from "@mui/icons-material/Widgets";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import PlayLessonIcon from "@mui/icons-material/PlayLesson";
import { Helmet } from "react-helmet-async";
import { spacing } from "@mui/system";
import SpatialAudioIcon from "@mui/icons-material/SpatialAudio";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import useProgram from "hooks/useProgram";
import BackBtn from "components/custom/BackBtn";
import useChallenge from "hooks/useChallenge";
import useReferentiel from "hooks/useReferentiel";
import TabInfoForm from "./TabInfoForm";
import TabThemesConfig from "./TabThemesConfig";
import ProgramCreateBtn from "./ProgramCreateBtn";
import PublishBtn from "./PublishBtn";
import TabBoostersConfig from "./TabBoostersConfig";
import TabCoachConfig from "./TabCoachConfig";
import TabStereoConfig from "./TabStereoConfig";
// ----------------------------------------------------------------------
const REF_MODEL = "programs";

const Divider = styled(MuiDivider)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Item = styled(Paper)(({ theme }) => ({
  //backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
export default function ProgramEditPage() {
  const [value, setValue] = React.useState(0);
  const { getStereotypeChallenges, getChallenges } = useChallenge();
  const { appProgram, isLoaded, initProgramById, initConfigProgram } =
    useProgram();
  const { appconfigs } = useReferentiel();
  useEffect(() => {
    getStereotypeChallenges();
    getChallenges();
    initConfigProgram();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const { id } = useParams();

  useEffect(() => {
    if (!id) return;
    initProgramById(id);
  }, [id]);

  if (!appProgram && isLoaded) return <EmptyProgram />;
  return (
    <>
      <Helmet title="Edition Programme" />
      <Box display="flex" justifyContent="justify-between" alignItems="center">
        <BackBtn
          backUrl="/app/program"
          needConfirm={true}
          titleConfim="Demande de confirmation"
          msgConfirm="Des données saisies pourraient ne pas être enregistrées. Voulez-vous quitter cette page ?"
        />
        <Box sx={{ width: "100%" }}>
          <Tabs value={value} onChange={handleChange} centered>
            <Tab icon={<TipsAndUpdatesIcon />} label="Informations" />
            <Tab icon={<WidgetsIcon />} label="Thèmes du programme" />
            <Tab icon={<PlayLessonIcon />} label="Boosters" />
            <Tab icon={<SpatialAudioIcon />} label="Voix des coachs" />
            <Tab icon={<SupervisedUserCircleIcon />} label="Stereotype" />
          </Tabs>
        </Box>
        <PublishBtn editProgram={appProgram} />
      </Box>
      <Divider my={6} />

      {value === 0 && (
        <Card mb={6}>
          <CardContent>
            <TabInfoForm editProgram={appProgram} />
          </CardContent>
        </Card>
      )}
      {value === 1 && <TabThemesConfig editProgram={appProgram} />}
      {value === 2 && <TabBoostersConfig editProgram={appProgram} />}
      {value === 3 && <TabCoachConfig editProgram={appProgram} />}
      {value === 4 && <TabStereoConfig editProgram={appProgram} />}
    </>
  );
}

const EmptyProgram = () => {
  const [display, setdisplay] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setdisplay(true);
    }, 2000);
    return () => {
      clearTimeout(timeout);
    };
  }, []);
  return (
    <Container>
      {display && (
        <>
          <Typography variant="h2" component="h3" gutterBottom>
            Le programme demandé n'est pas disponible.
          </Typography>
          <Box mb={8} />
          <Grid container spacing={6}>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <Button
                component={NavLink}
                to="/app/program"
                variant="contained"
                color="primary"
              >
                <ArrowBackIosIcon />
                Retourner au catalogue
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <ProgramCreateBtn />
            </Grid>
          </Grid>
        </>
      )}
    </Container>
  );
};
