import React from "react";
import styled from "@emotion/styled";
import { Grid, Box, Tabs as MuiTabs, Tab, Typography } from "@mui/material";
import Chip from "@mui/material/Chip";
import SelfImprovementIcon from "@mui/icons-material/SelfImprovement";
import MoveUpIcon from "@mui/icons-material/MoveUp";
import ShareIcon from "@mui/icons-material/Share";
import HighlightIcon from "@mui/icons-material/Highlight";
import LandslideIcon from "@mui/icons-material/Landslide";

import ThemeConfig from "./ThemeConfig";
// ----------------------------------------------------------------------
const Tabs = styled(MuiTabs)`
  .MuiTabs-flexContainer {
    align-items: flex-start;
  }
`;

export default function TabThemesConfig({ editProgram }) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getContentTab = () => {
    try {
      if (!editProgram.themes) return <></>;

      const theme = editProgram.themes.find(
        (it, i) => Number(i) === Number(value)
      );
      if (!theme) return <></>;
      return <ThemeConfig editProgram={editProgram} editTheme={theme} />;
    } catch (error) {
      console.error(error);
      return <></>;
    }
  };
  const getIcon = (theme) => {
    try {
      if (!theme) return <></>;
      if (Number(theme.reftheme.code) === 1) {
        return <SelfImprovementIcon />;
      } else if (Number(theme.reftheme.code) === 2) {
        return <MoveUpIcon />;
      } else if (Number(theme.reftheme.code) === 3) {
        return <ShareIcon />;
      } else if (Number(theme.reftheme.code) === 4) {
        return <HighlightIcon />;
      } else if (Number(theme.reftheme.code) === 5) {
        return <LandslideIcon />;
      }

      return <></>;
    } catch (error) {
      return <></>;
    }
  };
  if (!editProgram) return <></>;
  if (!editProgram.themes) return <></>;
  return (
    <Box
      sx={{
        flexGrow: 1,
        bgcolor: "background.paper",
        display: "flex",
      }}
    >
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{ borderRight: 1, borderColor: "divider" }}
      >
        {editProgram.themes.map((it, i) => {
          return (
            <Tab
              key={i}
              icon={getIcon(it)}
              iconPosition="start"
              label={it.reftheme.label}
              deleteIcon={getIcon(it)}
            />
          );
        })}
      </Tabs>
      <Box sx={{ width: "100%", height: "100%" }}>{getContentTab()}</Box>
    </Box>
  );
}
