import React from "react";
import styled from "@emotion/styled";
import {
  Card as MuiCard,
  CardContent,
  Chip as MuiChip,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography as MuiTypography,
  Button,
} from "@mui/material";
import { spacing } from "@mui/system";
import { fDateTime } from "utils/formatTime";
import { NavLink } from "react-router-dom";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
// ----------------------------------------------------------------------
const Card = styled(MuiCard)(spacing);
const Chip = styled(MuiChip)(spacing);
const Typography = styled(MuiTypography)(spacing);
const ProductsChip = styled(Chip)`
  height: 20px;
  padding: 4px 0;
  font-size: 90%;
  background-color: ${(props) =>
    props.theme.palette[props.color ? props.color : "primary"].light};
  color: ${(props) => props.theme.palette.common.white};
`;
const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;

export default function UserAppcodes({ userData }) {
  const { appcodes } = userData;

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Codes d'applications reçus
        </Typography>
        {Array.isArray(appcodes) && (
          <TableWrapper>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Code</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Statut</TableCell>
                  <TableCell>Date d'activation</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {appcodes.map((it, i) => {
                  return (
                    <TableRow key={i}>
                      <TableCell component="th" scope="row">
                        {it.code}
                      </TableCell>
                      <TableCell>{it.description}</TableCell>
                      <TableCell>
                        {it.enable ? (
                          <ProductsChip
                            size="small"
                            label="Actif"
                            color="success"
                          />
                        ) : (
                          <ProductsChip
                            size="small"
                            label="Désactivé"
                            color="error"
                          />
                        )}
                      </TableCell>
                      <TableCell>{fDateTime(it.enabledat)} </TableCell>
                      <TableCell>
                        <Button
                          component={NavLink}
                          to={`/app/userprog-edit/${it.id}`}
                          variant="outlined"
                          color="info"
                        >
                          <TravelExploreIcon /> Détail du programme
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableWrapper>
        )}
      </CardContent>
    </Card>
  );
}
