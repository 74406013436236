import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import styled from "@emotion/styled";
import {
  Alert as MuiAlert,
  Box,
  MenuItem,
  TextField as MuiTextField,
  ListItemText,
  Button,
  IconButton,
  Stack,
  Paper,
  Card,
  Popover,
  CardActions,
  CardContent,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { Add as AddIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";
import useNotification from "hooks/useNotification";
import AlertMsg from "components/custom/AlertMsg";
import useReferentiel from "hooks/useReferentiel";
import ChoicesTable from "./ChoicesTable";
import ChoiceEditBtn from "./ChoiceEditBtn";
// ----------------------------------------------------------------------
const TextField = styled(MuiTextField)(spacing);
const Alert = styled(MuiAlert)(spacing);
export default function StereoChalQuestionForm({
  editData,
  onCompleteQuestion,
}) {
  const [editChoices, seteditChoices] = useState([]);
  const { refs } = useReferentiel();
  const { notif } = useNotification();
  const { refstereotype } = refs;

  useEffect(() => {
    if (!editData) return;
    if (!editData.choices) return;
    seteditChoices(editData.choices);
  }, [editData]);

  const dataSchema = Yup.object().shape({
    title: Yup.string().max(200).required("Veuillez renseigner la question"),
    choices: Yup.array()
      .required("Veuillez renseigner les choix de réponse")
      .min(1, "Les choix de réponse ne sont pas valides")
      .max(50, "Les choix de réponse ne sont pas valides"),
  });
  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      title: editData && editData.title ? editData.title : "",
      choices: editData && editData.choices ? editData.choices : "",
    },
    validationSchema: dataSchema,
    onSubmit: async () => {
      try {
        const newData =
          editData && editData.id
            ? { ...editData, ...values }
            : { ...values, id: "temp_" + new Date().getTime() };
        onCompleteQuestion({
          ...newData,
        });
      } catch (error) {
        console.error(error);
        notif(null, error);
        setSubmitting(false);
      }
    },
  });

  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setSubmitting,
    handleChange,
    setFieldValue,
  } = formik;

  useEffect(() => {
    if (!setFieldValue) return;
    if (!editChoices) return;
    setFieldValue("choices", editChoices);
  }, [editChoices, setFieldValue]);

  if (!refs) return;
  if (!refstereotype) return;

  const handleCompleteChoice = (data) => {
    try {
      const newChoices = [...editChoices];
      const choiceIndexFounded = newChoices.findIndex(
        (it) => it.id === data.id
      );
      if (choiceIndexFounded > -1) {
        newChoices[choiceIndexFounded] = data;
      } else {
        newChoices.push(data);
      }
      seteditChoices(newChoices);
    } catch (error) {
      console.error(error);
    }
  };
  const handleDelChoice = (data) => {
    try {
      const newChoices = [...editChoices].filter((it) => it.id !== data.id);
      seteditChoices(newChoices);
    } catch (error) {
      console.error();
    }
  };
  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <AlertMsg errors={errors} />
          <TextField
            fullWidth
            type="text"
            label="Question"
            {...getFieldProps("title")}
            error={Boolean(touched.title && errors.title)}
            helperText={touched.title && errors.title}
            my={2}
          />
          <ChoiceEditBtn
            addLabel="Ajouter une réponse et ses paramètres"
            handleCompleteChoice={handleCompleteChoice}
          />
          {editChoices && (
            <ChoicesTable
              choices={editChoices}
              handleCompleteChoice={handleCompleteChoice}
              handleDelChoice={handleDelChoice}
            />
          )}
          <Button
            sx={{ mt: 10 }}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            Valider
          </Button>
        </Form>
      </FormikProvider>
    </>
  );
}
