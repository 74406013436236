import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { fDateTime } from "utils/formatTime";
import ImgBoxFromUrl from "components/media/ImgBoxFromUrl";
import AudioBoxFromUrl from "components/media/AudioBoxFromUrl";

import CardMediaFromUrl from "components/media/CardMediaFromUrl";
// ----------------------------------------------------------------------
export default function CardRefSubject({ refsubject }) {
  if (!refsubject) return <></>;
  return (
    <Grid container spacing={{ xs: 2, md: 3 }}>
      <Grid item sm={12} md={6}>
        <Card sx={{ maxWidth: 345 }}>
          {refsubject.bgimage && (
            <CardMediaFromUrl
              image={refsubject.bgimage}
              title={refsubject.label}
            />
          )}
          <CardContent>
            <Typography variant="body2" color="text.secondary">
              Image de fond
            </Typography>
          </CardContent>
        </Card>

        {/*    <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 1, width: "25ch" },
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            label="Code"
            defaultValue={editProgram.code}
            InputProps={{
              readOnly: true,
            }}
            sx={{ my: 3 }}
          />
          <TextField
            label="Nom du progamme"
            defaultValue={editProgram.title}
            InputProps={{
              readOnly: true,
            }}
            sx={{ my: 3 }}
          />
          <TextField
            label="Nom du progamme"
            defaultValue={editProgram.title}
            InputProps={{
              readOnly: true,
            }}
            sx={{ my: 3 }}
          />
          <TextField
            label="Nombre de jour"
            defaultValue={editProgram.numberoffdays}
            InputProps={{
              readOnly: true,
            }}
            sx={{ my: 3 }}
          />
          <TextField
            label="Description"
            defaultValue={editProgram.description}
            InputProps={{
              readOnly: true,
            }}
            sx={{ my: 3 }}
          />
          <TextField
            label="Date de publication"
            defaultValue={fDateTime(editProgram.publishedat)}
            InputProps={{
              readOnly: true,
            }}
            sx={{ my: 3 }}
          />

          <TextField
            label="Société"
            defaultValue={editProgram.company.name}
            InputProps={{
              readOnly: true,
            }}
            sx={{ my: 3 }}
          />
          <TextField
            label="Créé par"
            defaultValue={editProgram.createdby.pseudo}
            InputProps={{
              readOnly: true,
            }}
            sx={{ my: 3 }}
          />
          <TextField
            label="Modifié par"
            defaultValue={editProgram.modifiedby.pseudo}
            InputProps={{
              readOnly: true,
            }}
            sx={{ my: 3 }}
          />
        </Box> */}
      </Grid>
      <Grid item sm={12} md={6}>
        <Card sx={{ maxWidth: 345 }}>
          {refsubject.image && (
            <CardMediaFromUrl
              image={refsubject.image}
              title={refsubject.label}
            />
          )}
          <CardContent>
            <Typography variant="body2" color="text.secondary">
              Image de présentation
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item sm={12} md={6}>
        <Card sx={{ maxWidth: 345 }}>
          {refsubject.femalevoice && (
            <AudioBoxFromUrl audio={refsubject.femalevoice} />
          )}

          <CardContent>
            <Typography variant="body2" color="text.secondary">
              Voix féminine
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item sm={12} md={6}>
        <Card sx={{ maxWidth: 345 }}>
          {refsubject.malevoice && (
            <AudioBoxFromUrl audio={refsubject.malevoice} />
          )}

          <CardContent>
            <Typography variant="body2" color="text.secondary">
              Voix masculine
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
