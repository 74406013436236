import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import styled from "@emotion/styled";
import {
  Alert as MuiAlert,
  Box,
  MenuItem,
  TextField as MuiTextField,
  ListItemText,
  Button,
  IconButton,
  Stack,
  Paper,
  Card,
  Popover,
  CardActions,
  CardContent,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { Add as AddIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";
import useReferentiel from "hooks/useReferentiel";
// ----------------------------------------------------------------------
const TextField = styled(MuiTextField)(spacing);
const Alert = styled(MuiAlert)(spacing);

export default function ChoiceEditBtn({
  editData,
  addLabel,
  handleCompleteChoice,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const getTitle = () => {
    try {
      if (editData && editData.id) {
        return `Modification`;
      } else {
        return addLabel;
      }
    } catch (error) {
      return "";
    }
  };
  const getIcon = () => {
    try {
      if (editData && editData.id) {
        return (
          <IconButton
            aria-label="details"
            size="small"
            color="info"
            variant="contained"
            onClick={handleClick}
          >
            <EditIcon size="small" />
          </IconButton>
        );
      } else {
        return (
          <Button
            variant="outlined"
            color="info"
            onClick={handleClick}
            sx={{ my: 3 }}
          >
            <AddIcon />
            {getTitle()}
          </Button>
        );
      }
    } catch (error) {
      return <></>;
    }
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const onCompleteChoice = (data) => {
    try {
      handleCompleteChoice(data);
      handleClose();
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      {getIcon()}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Card
          sx={{
            minWidth: 275,
            backgroundColor: "#1A2027",
          }}
        >
          <CardContent>
            <ChoiceForm
              onCompleteChoice={onCompleteChoice}
              editData={editData}
            />
          </CardContent>
          <CardActions sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button onClick={handleClose}>Annuler</Button>
          </CardActions>
        </Card>
      </Popover>
    </>
  );
}

const ChoiceForm = ({ editData, onCompleteChoice }) => {
  const { refs } = useReferentiel();

  const { refstereotype } = refs;

  const getValidationParams = () => {
    try {
      if (!refstereotype) return;
      const obj = {};
      refstereotype.forEach((it) => {
        obj[`point_${it.code}`] = Yup.string()
          .max(200)
          .required("Veuillez renseigner le point gagné pour " + it.label);
      });
      return obj;
    } catch (error) {
      return {};
    }
  };

  const getInitValues = () => {
    try {
      const obj = {};
      refstereotype.forEach((it) => {
        const pointField = `point_${it.code}`;
        obj[pointField] = "";
      });
      return obj;
    } catch (error) {
      return {};
    }
  };

  const getFieldParams = () => {
    try {
      if (!refstereotype) return;
      return (
        <>
          {refstereotype.map((it, i) => {
            const pointField = `point_${it.code}`;
            return (
              <TextField
                key={i}
                fullWidth
                type="number"
                label={`Point gagné pour le ${it.label}`}
                {...getFieldProps(pointField)}
                error={Boolean(touched[pointField] && errors[pointField])}
                helperText={touched[pointField] && errors[pointField]}
                my={2}
              />
            );
          })}
        </>
      );
    } catch (error) {
      return <></>;
    }
  };

  const dataSchema = Yup.object().shape({
    title: Yup.string().max(200).required("Veuillez renseigner la réponse"),
    ...getValidationParams(),
  });
  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      title: editData && editData.title ? editData.title : "",
      ...getInitValues(),
    },
    validationSchema: dataSchema,
    onSubmit: async () => {
      try {
        const pointgained = [];
        refstereotype.forEach((it) => {
          const keyField = `point_${it.code}`;
          pointgained.push({
            refstereotype: { ...it },
            point: values[keyField],
          });
        });
        const newData =
          editData && editData.id
            ? { ...editData, title: values.title, pointgained }
            : {
                id: "temp_" + new Date().getTime(),
                title: values.title,
                pointgained,
              };
        onCompleteChoice({
          ...newData,
        });
      } catch (error) {
        console.error(error);
        setSubmitting(false);
      }
    },
  });

  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setSubmitting,
    setFieldValue,
  } = formik;

  useEffect(() => {
    if (!refstereotype) return;
    if (!editData) return;
    if (!editData.pointgained) return;
    if (!setFieldValue) return;

    refstereotype.forEach((it) => {
      const pointField = `point_${it.code}`;
      const pointGainedFound = editData.pointgained.find(
        (p) => p.refstereotype.code === it.code
      );

      if (pointGainedFound) {
        setFieldValue(pointField, pointGainedFound.point);
      }
    });
  }, [refstereotype, editData, setFieldValue]);

  if (!refstereotype) return <></>;

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <TextField
            fullWidth
            type="text"
            label="Réponse"
            {...getFieldProps("title")}
            error={Boolean(touched.title && errors.title)}
            helperText={touched.title && errors.title}
            my={2}
          />
          {getFieldParams()}
          <Button
            sx={{ mt: 10 }}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            Valider
          </Button>
        </Form>
      </FormikProvider>
    </>
  );
};
