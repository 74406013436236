import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { withTheme } from "@emotion/react";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Bar } from "react-chartjs-2";

import { MoreVertical } from "react-feather";

import {
  Avatar,
  AvatarGroup as MuiAvatarGroup,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardMedia as MuiCardMedia,
  CardContent,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid as MuiGrid,
  LinearProgress as MuiLinearProgress,
  Link,
  Table,
  CardHeader,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography as MuiTypography,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import axios from "axios";
import { API_URL } from "config";
import { Add as AddIcon } from "@mui/icons-material";
import SportsGymnasticsIcon from "@mui/icons-material/SportsGymnastics";
import MicExternalOnIcon from "@mui/icons-material/MicExternalOn";
import MissedVideoCallIcon from "@mui/icons-material/MissedVideoCall";
import DraftsIcon from "@mui/icons-material/Drafts";
import AppShortcutIcon from "@mui/icons-material/AppShortcut";
import BookmarkAddedIcon from "@mui/icons-material/BookmarkAdded";
import EditIcon from "@mui/icons-material/Edit";
import { spacing } from "@mui/system";
import useProgram from "hooks/useProgram";
import { getMediaUrl } from "utils/objectUtil";
import CardMediaFromUrl from "components/media/CardMediaFromUrl";
import ProgramDelBtn from "./ProgramDelBtn";
import ProgramCreateBtn from "./ProgramCreateBtn";
import ProgramCopyBtn from "./ProgramCopyBtn";
// ----------------------------------------------------------------------
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Button = styled(MuiButton)(spacing);

const Card = styled(MuiCard)(spacing);

const Chip = styled(MuiChip)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Grid = styled(MuiGrid)(spacing);

const Typography = styled(MuiTypography)(spacing);
const AvatarGroup = styled(MuiAvatarGroup)`
  margin-left: ${(props) => props.theme.spacing(2)};
`;
const REF_MODEL = "programs";
export default function ProgramPage() {
  const { programs, getPrograms } = useProgram();
  useEffect(() => {
    getPrograms();
  }, []);
  return (
    <>
      <Helmet title="Catalogue des programmes" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Catalogue des programmes
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/app">
              Accueil
            </Link>
            <Typography>Tous les programmes</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <ProgramCreateBtn />
        </Grid>
      </Grid>

      <Divider my={6} />
      <Grid container spacing={6}>
        <>
          {Array.isArray(programs) && (
            <>
              {programs.map((it, i) => {
                return (
                  <Grid item xs={12} lg={6} xl={3} key={i}>
                    <Detail data={it} />
                  </Grid>
                );
              })}
            </>
          )}
        </>
      </Grid>
    </>
  );
}
/* item card */
const Detail = ({ data }) => {
  const getChip = () => {
    try {
      if (!data) return <></>;
      if (data.isPublished) {
        return (
          <Chip
            label="Publié"
            onClick={() => console.log()}
            onDelete={() => console.log()}
            color="success"
            variant="contained"
            deleteIcon={<AppShortcutIcon />}
            sx={{ my: 1 }}
          />
        );
      } else if (data.isValidated) {
        return (
          <Chip
            label="Validé"
            onClick={() => console.log()}
            onDelete={() => console.log()}
            color="primary"
            variant="contained"
            deleteIcon={<BookmarkAddedIcon />}
            sx={{ my: 1 }}
          />
        );
      } else if (data.isDraff) {
        return (
          <Chip
            label="Brouillon"
            onClick={() => console.log()}
            onDelete={() => console.log()}
            color="warning"
            variant="contained"
            deleteIcon={<DraftsIcon />}
            sx={{ my: 1 }}
          />
        );
      }
      return <></>;
    } catch (error) {
      return <></>;
    }
  };

  if (!data) return <></>;
  return (
    <Card>
      <CardHeader action={<PositionedMenu data={data} />} title={data.title} />
      {data.image ? (
        <CardMediaFromUrl
          image={getMediaUrl(data, "image", REF_MODEL)}
          title={data.title}
        />
      ) : null}
      <CardContent>
        {getChip()}
        <Typography mb={4} color="textSecondary" component="h5">
          {`${data.company.name}`}
        </Typography>
        <Typography mb={4} color="textSecondary" component="p">
          {`${data.description}`}
        </Typography>
        <AvatarGroup max={3}>
          <Avatar alt="Avatar" src="/static/img/avatars/avatar-1.jpg" />
        </AvatarGroup>
      </CardContent>
    </Card>
  );
};

const PositionedMenu = ({ data }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        id="demo-positioned-button"
        aria-controls={open ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreVertical />
      </IconButton>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem component={NavLink} to={`/app/edit-program/${data.id}`}>
          <Button
            aria-label="details"
            size="small"
            onClick={handleClick}
            color="primary"
          >
            <EditIcon /> Modifier
          </Button>
        </MenuItem>
        <MenuItem>
          <ProgramDelBtn
            editData={data}
            refName={REF_MODEL}
            onComplete={handleClose}
          />
        </MenuItem>
        <MenuItem>
          <ProgramCopyBtn editData={data} onComplete={handleClose} />
        </MenuItem>
      </Menu>
    </div>
  );
};
