import { useDispatch, useSelector } from "react-redux";

import axios from "axios";
import { API_URL } from "config";
import {
  startLoading,
  stopLoading,
  initStereoChallenges,
  initChallenges,
  setChalFilters,
} from "redux/slices/programSlice";

// ----------------------------------------------------------------------

export default function useChallenge() {
  const dispatch = useDispatch();
  const { chalFilters, challenges, stereotypechallenges } = useSelector(
    (state) => state.programs
  );

  return {
    stereotypechallenges,
    challenges,
    chalFilters,
    getStereotypeChallenges: async () => {
      try {
        dispatch(startLoading());
        const res = await axios.get(API_URL.stereotypechallenge);
        if (res && res.data) {
          dispatch(initStereoChallenges(res.data));
        } else {
          throw new Error("Stereotype programme ne sont pas disponibles");
        }
      } catch (error) {
        dispatch(stopLoading());
        console.error(error);
        throw new Error("Stereotype programme ne sont pas disponibles");
      }
    },
    handleChalFilters: (filters) => {
      try {
        dispatch(setChalFilters({ ...filters }));
      } catch (error) {
        console.error(error);
      }
    },
    getChallenges: async () => {
      try {
        dispatch(startLoading());
        const res = await axios.get(API_URL.challenge);
        if (res && res.data) {
          dispatch(initChallenges(res.data));
        } else {
          throw new Error("Challenges ne sont pas disponibles");
        }
      } catch (error) {
        dispatch(stopLoading());
        console.error(error);
        throw new Error("Challenges ne sont pas disponibles");
      }
    },
    findChallengeById: async (id) => {
      try {
        if (!id) return;

        const res = await axios.get(`${API_URL.challenge}/${id}`);
        if (res && res.data) {
          return res.data;
        } else {
          throw new Error(`Challenge n'est pas valide`);
        }
      } catch (error) {
        dispatch(stopLoading());
        console.error(error);
        throw new Error(`Challengee n'est pas valide`);
      }
    },
    findStereoChallengeById: async (id) => {
      try {
        if (!id) return;

        const res = await axios.get(`${API_URL.stereotypechallenge}/${id}`);
        if (res && res.data) {
          return res.data;
        } else {
          throw new Error(`Stereotype programme n'est pas valide`);
        }
      } catch (error) {
        dispatch(stopLoading());
        console.error(error);
        throw new Error(`Stereotype programme n'est pas valide`);
      }
    },
    saveChallenge: async (o, refModel) => {
      try {
        dispatch(startLoading());
        delete o.modifiedby;
        delete o.createdby;
        delete o.maletranscripts;
        delete o.femaletranscripts;
        if (o && o.id) {
          await axios.patch(API_URL[refModel] + "/" + o.id, o);
        } else {
          await axios.post(API_URL[refModel], o);
        }
      } catch (error) {
        dispatch(stopLoading());
        console.error();
        throw error;
      }
    },
    cloneChallenge: async (o) => {
      try {
        if (!o || !o.id) return;
        await axios.get(`${API_URL.challenge}/clone/${o.id}`);
      } catch (error) {
        dispatch(stopLoading());
        console.error(error);
        throw error;
      }
    },
    saveChallengeFormData: async (o, formdata, refModel) => {
      try {
        dispatch(startLoading());
        delete o.modifiedby;
        delete o.createdby;
        delete o.maletranscripts;
        delete o.femaletranscripts;
        if (o && o.id) {
          await axios.patch(API_URL[refModel] + "/formdata/" + o.id, formdata);
        } else {
          await axios.post(API_URL[refModel] + "/formdata", formdata);
        }
      } catch (error) {
        dispatch(stopLoading());
        console.error();
        throw error;
      }
    },
    delChallenge: async (o, refName) => {
      try {
        dispatch(startLoading());
        if (o && o.id) {
          await axios.delete(API_URL[refName] + "/" + o.id);
        }
      } catch (error) {
        dispatch(stopLoading());
        console.error();
        throw error;
      }
    },
    delVideo: async (o, files, refName) => {
      try {
        dispatch(startLoading());
        if (o && o.id) {
          await axios.post(API_URL[refName] + "/delmedia/" + o.id, files);
        }
        dispatch(stopLoading());
      } catch (error) {
        dispatch(stopLoading());
        console.error();
        throw error;
      }
    },
  };
}
