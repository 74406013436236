import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useTheme } from "@mui/material/styles";

import { withTheme } from "@emotion/react";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Bar } from "react-chartjs-2";

import { MoreVertical } from "react-feather";

import {
  Avatar,
  AvatarGroup as MuiAvatarGroup,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardMedia as MuiCardMedia,
  CardContent,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid as MuiGrid,
  LinearProgress as MuiLinearProgress,
  Link,
  CardHeader,
  Typography as MuiTypography,
  IconButton,
  Menu,
  Pagination,
  Stack,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
} from "@mui/material";

import SportsGymnasticsIcon from "@mui/icons-material/SportsGymnastics";
import MicExternalOnIcon from "@mui/icons-material/MicExternalOn";
import MissedVideoCallIcon from "@mui/icons-material/MissedVideoCall";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";
import EditIcon from "@mui/icons-material/Edit";
import { spacing } from "@mui/system";
import { getMediaUrl } from "utils/objectUtil";
import CardMediaFromUrl from "components/media/CardMediaFromUrl";
import ChallengeDelBtn from "./ChallengeDelBtn";
import ChallengeCopyBtn from "./ChallengeCopyBtn";

// ----------------------------------------------------------------------

const Button = styled(MuiButton)(spacing);

const Card = styled(MuiCard)(spacing);

const Chip = styled(MuiChip)(spacing);

const Grid = styled(MuiGrid)(spacing);

const Typography = styled(MuiTypography)(spacing);
const AvatarGroup = styled(MuiAvatarGroup)`
  margin-left: ${(props) => props.theme.spacing(2)};
`;
const REF_MODEL = "challenge";
const DF_ITEMS_PER_PAGE = [20, 30, 50, 100];
export default function PaginationGridChallenge({ filteredChallenges }) {
  const [itemsperpage, setitemsperpage] = useState(DF_ITEMS_PER_PAGE[0]);
  const [page, setPage] = useState(1);
  const [count, setcount] = useState(null);
  const [items, setitems] = useState(null);
  useEffect(() => {
    if (!Array.isArray(filteredChallenges)) return;
    const countNb =
      filteredChallenges.length <= itemsperpage
        ? 1
        : Math.ceil(filteredChallenges.length / itemsperpage);
    setcount(countNb);
  }, [filteredChallenges, itemsperpage]);

  useEffect(() => {
    if (!Array.isArray(filteredChallenges)) return;
    if (!page) return;

    const fromIndex = itemsperpage * (Number(page) - 1);
    const toIndex = fromIndex + itemsperpage;
    const newItems = filteredChallenges.slice(fromIndex, toIndex);
    setitems(newItems);
  }, [filteredChallenges, page, itemsperpage]);

  const handleChange = (event, value) => {
    setPage(value);
  };
  const handleChangeItemsPerPage = (e) => {
    setitemsperpage(e.target.value);
    setPage(1); //reset page
  };

  if (!items) return <></>;
  return (
    <>
      <Box
        display="flex"
        justifyContent="flex-end"
        sx={{ mb: 3 }}
        alignItems="center"
      >
        <Stack
          spacing={2}
          direction="row"
          style={{ minWidth: "200px" }}
          sx={{ alignItems: "center" }}
        >
          <Pagination count={count} page={page} onChange={handleChange} />
          <FormControl sx={{ ml: 8 }}>
            <InputLabel id="demo-simple-select-label">Par page</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={itemsperpage}
              label="Par page"
              onChange={handleChangeItemsPerPage}
            >
              {DF_ITEMS_PER_PAGE.map((it) => {
                return (
                  <MenuItem key={it} value={it}>
                    {it}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Stack>
      </Box>
      <Grid container spacing={6}>
        <>
          {Array.isArray(items) && (
            <>
              {items.map((it, i) => {
                return (
                  <Grid item xs={12} lg={6} xl={3} key={i}>
                    <Detail data={it} />
                  </Grid>
                );
              })}
            </>
          )}
        </>
      </Grid>
    </>
  );
}
/* item card */
const Detail = ({ data }) => {
  const theme = useTheme();
  const getChip = () => {
    try {
      if (!data) return <></>;
      if (!data.refchallengetype) return <></>;
      if (String(data.refchallengetype.code) === "2") {
        return (
          <Chip
            label={data.refchallengetype.label}
            onClick={() => console.log()}
            onDelete={() => console.log()}
            color="info"
            variant="contained"
            deleteIcon={<SpeakerNotesIcon />}
            sx={{ my: 1 }}
          />
        );
      } else if (String(data.refchallengetype.code) === "4") {
        return (
          <Chip
            label={data.refchallengetype.label}
            onClick={() => console.log()}
            onDelete={() => console.log()}
            color="primary"
            variant="contained"
            deleteIcon={<SportsGymnasticsIcon />}
            sx={{ my: 1 }}
          />
        );
      } else if (String(data.refchallengetype.code) === "1") {
        return (
          <Chip
            label={data.refchallengetype.label}
            onClick={() => console.log()}
            onDelete={() => console.log()}
            color="warning"
            variant="contained"
            deleteIcon={<MicExternalOnIcon />}
            sx={{ my: 1 }}
          />
        );
      } else if (String(data.refchallengetype.code) === "3") {
        return (
          <Chip
            label={data.refchallengetype.label}
            onClick={() => console.log()}
            onDelete={() => console.log()}
            color="error"
            variant="contained"
            deleteIcon={<MissedVideoCallIcon />}
            sx={{ my: 1 }}
          />
        );
      } else if (String(data.refchallengetype.code) === "5") {
        return (
          <Chip
            label={data.refchallengetype.label}
            onClick={() => console.log()}
            onDelete={() => console.log()}
            color="error"
            variant="contained"
            deleteIcon={<SportsGymnasticsIcon />}
            sx={{ my: 1 }}
          />
        );
      }
    } catch (error) {
      return <></>;
    }
  };

  const getAvatarStereo = (stereotype) => {
    try {
      if (!stereotype) return <></>;
      if (!theme.palette) return <></>;
      const { primary, error, warning } = theme.palette;
      let bgcolor = primary.dark;
      if (stereotype.code === "strong") {
        bgcolor = error.dark;
      } else if (stereotype.code === "medium") {
        bgcolor = warning.dark;
      }
      return (
        <Box display="flex" justifyContent="space-between">
          {getChip()}
          <AvatarGroup max={3}>
            <Avatar alt="Avatar" sx={{ bgcolor }}>
              {`${stereotype.code.charAt(0).toUpperCase()}${stereotype.code
                .charAt(1)
                .toUpperCase()}`}
            </Avatar>
          </AvatarGroup>
        </Box>
      );
    } catch (error) {
      return <></>;
    }
  };
  if (!data) return <></>;
  return (
    <Card>
      <CardHeader action={<PositionedMenu data={data} />} title={data.title} />
      {data.image ? (
        <CardMediaFromUrl
          image={getMediaUrl(data, "image", REF_MODEL)}
          title={data.title}
        />
      ) : null}
      <CardContent>
        {data.refsubject && data.refsubject.code && (
          <Typography mb={4} color="textSecondary" component="p">
            {`${data.refsubject.code} - ${data.refsubject.label}`}
          </Typography>
        )}
        {getAvatarStereo(data.refstereotype)}
      </CardContent>
    </Card>
  );
};

const PositionedMenu = ({ data }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        id="demo-positioned-button"
        aria-controls={open ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreVertical />
      </IconButton>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem component={NavLink} to={`/app/edit-challenge/${data.id}`}>
          <Button
            aria-label="details"
            size="small"
            onClick={handleClick}
            color="primary"
          >
            <EditIcon /> Modifier
          </Button>
        </MenuItem>
        <MenuItem>
          <ChallengeDelBtn
            editData={data}
            refName={REF_MODEL}
            onComplete={handleClose}
          />
        </MenuItem>
        <MenuItem>
          <ChallengeCopyBtn
            editData={data}
            refName={REF_MODEL}
            onComplete={handleClose}
          />
        </MenuItem>
      </Menu>
    </div>
  );
};
