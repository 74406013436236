import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import styled from "@emotion/styled";
import {
  Alert as MuiAlert,
  Box,
  MenuItem,
  TextField as MuiTextField,
  ListItemText,
  Button,
  Stack,
  Paper,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import CircularProgress from "@mui/material/CircularProgress";
import { spacing } from "@mui/system";
import useNotification from "hooks/useNotification";
import useReferentiel from "hooks/useReferentiel";
import UploadSingleFile from "components/file/UploadSingleFile";
import ImgBoxFromUrl from "components/media/ImgBoxFromUrl";
import AudioBoxFromUrl from "components/media/AudioBoxFromUrl";
import EditFormSubtitle from "components/media/EditFormSubtitle";
import { API_URL } from "config";
import { init } from "i18next";
// ----------------------------------------------------------------------
const TextField = styled(MuiTextField)(spacing);
const Item = styled(Paper)(({ theme }) => ({
  //backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
export default function RefCoachVoiceForm({ editData, onComplete }) {
  const { saveRefVoiceCoachFormData, saveRefVoiceCoach, initRefs } =
    useReferentiel();
  const { notif } = useNotification();
  const { appconfigs, refs } = useReferentiel();

  const dataSchema = Yup.object().shape({
    code: Yup.string().max(20).required("Veuillez renseigner le code"),
    label: Yup.string().max(100).required(`Veuillez renseigner le libellé`),
    voice:
      editData && editData.id
        ? Yup.string()
        : Yup.mixed().required("Veuillez renseigner l'audio"),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      code: editData && editData.code ? editData.code : "",
      label: editData && editData.label ? editData.label : "",
    },
    validationSchema: dataSchema,
    onSubmit: async () => {
      try {
        const newData =
          editData && editData.id ? { ...editData, ...values } : { ...values };
        /* in case file upload is choiced we need to make formdata */
        const isFormdata = values.voice;
        if (isFormdata) {
          const formData = new FormData();
          delete newData.modifiedby;
          delete newData.createdby;
          if (values.voice) {
            delete newData.voice;
            formData.append("voice", values.voice);
          }
          Object.entries(newData).forEach(([e, k]) => {
            formData.append(e, k);
          });

          await saveRefVoiceCoachFormData(newData, formData);
        } else {
          await saveRefVoiceCoach({
            ...newData,
          });
        }

        await initRefs();
        notif("Sauvegarde réussie");
        onComplete();
      } catch (error) {
        console.error(error);
        notif(null, error);
        setSubmitting(false);
      }
    },
  });
  const handleCompleteTranscript = async () => {
    await initRefs();
  };
  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setSubmitting,
    handleChange,
    setFieldValue,
  } = formik;

  if (!refs) return;
  const { reftheme } = refs;
  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <TextField
            fullWidth
            type="text"
            label="Code"
            {...getFieldProps("code")}
            error={Boolean(touched.code && errors.code)}
            helperText={touched.code && errors.code}
            my={2}
          />
          <TextField
            fullWidth
            type="text"
            name="label"
            label="Libellé"
            {...getFieldProps("label")}
            error={Boolean(touched.label && errors.label)}
            helperText={touched.label && errors.label}
            my={2}
          />
          <Stack
            spacing={2}
            direction="row"
            alignItems="center"
            justifyContent="center"
            sx={{ my: 2 }}
          >
            {editData && editData.voice && (
              <Item>
                <AudioBoxFromUrl
                  audio={`${API_URL.getstream}/refCoachVoice_${editData.id}/${editData.voice}`}
                />
                {editData.transcript && (
                  <EditFormSubtitle
                    transcripts={editData.transcript}
                    model="refCoachVoice"
                    field="transcript"
                    idModel={editData.id}
                    onComplete={handleCompleteTranscript}
                    hideSameThing={true} //only one voice
                  />
                )}
              </Item>
            )}
            <Item>
              <UploadSingleFile
                value={values.voice}
                label="Fichier audio"
                onChange={(val) => setFieldValue("voice", val)}
                caption="(Les formats *.mp3 sont acceptés)"
                error={Boolean(touched.voice && errors.voice)}
                sx={{ maxHeight: "150px" }}
                acceptFile={{
                  accept: {
                    "audio/mp3": [".mp3"],
                  },
                }}
                mediaType="audio"
              />
            </Item>
          </Stack>
          <Button
            sx={{ my: 2 }}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
            endIcon={
              isSubmitting ? (
                <CircularProgress size={20} />
              ) : (
                <DoneIcon size={20} />
              )
            }
          >
            Valider
          </Button>
        </Form>
      </FormikProvider>
    </>
  );
}
