import { useDispatch, useSelector } from "react-redux";
import {
  startLoading,
  stopLoading,
  setInitialize,
} from "redux/slices/appcodeSlice";
import axios from "axios";
import { API_URL } from "config";

// ----------------------------------------------------------------------

export default function useDataAppcode() {
  const dispatch = useDispatch();
  const { appcodes, isLoading } = useSelector((state) => state.appcodes);

  return {
    appcodes,
    isLoading,
    initAppcodes: async () => {
      try {
        dispatch(startLoading());
        const res = await axios.get(API_URL.appcodes);
        if (res && res.data) {
          const dataRes = res.data;
          dispatch(setInitialize([...dataRes]));
        } else {
          throw new Error("Codes d'application ne sont pas disponibles");
        }
        dispatch(stopLoading());
      } catch (error) {
        dispatch(stopLoading());
        console.error();
        throw new Error("Codes d'application ne sont pas disponibles");
      }
    },
    saveAppcode: async (o) => {
      try {
        console.log("save", o);
        delete o.createdby;
        delete o.modifiedby;
        if (o && o.id) {
          const res = await axios.patch(API_URL.appcodes + "/" + o.id, o);
        } else {
          const res = await axios.post(API_URL.appcodes, o);
        }
      } catch (error) {
        console.error();
        throw error;
      }
    },

    delAppcode: async (u) => {
      try {
        dispatch(startLoading());
        if (u && u.id) {
          const res = await axios.delete(API_URL.appcodes + "/" + u.id);
        }
        dispatch(stopLoading());
      } catch (error) {
        dispatch(stopLoading());
        console.error();
        throw error;
      }
    },
    resetAppcode: async (u) => {
      try {
        dispatch(startLoading());
        if (u && u.id) {
          const res = await axios.get(API_URL.appcodes + "/reset/" + u.code);
        }
        dispatch(stopLoading());
      } catch (error) {
        dispatch(stopLoading());
        console.error();
        throw error;
      }
    },
  };
}
