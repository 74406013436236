import React from "react";
import styled from "@emotion/styled";
import { ExternalLink, Facebook, Instagram, Twitter } from "react-feather";
import {
  Box,
  Card as MuiCard,
  CardContent,
  Grid as MuiGrid,
  Link,
  Typography as MuiTypography,
} from "@mui/material";
import { spacing } from "@mui/system";
// ----------------------------------------------------------------------
const Card = styled(MuiCard)(spacing);
const Grid = styled(MuiGrid)(spacing);
const Spacer = styled.div(spacing);
const Typography = styled(MuiTypography)(spacing);
const AboutIcon = styled.span`
  display: flex;
  padding-right: ${(props) => props.theme.spacing(2)};

  svg {
    width: 14px;
    height: 14px;
  }
`;

export default function UserStereotype({ userData }) {
  const { refstereotype } = userData;
  if (!refstereotype) return;
  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Stéréotype
        </Typography>

        <Spacer mb={4} />

        <Grid container direction="row" alignItems="center" mb={2}>
          <Grid item>
            <AboutIcon>
              <ExternalLink />
            </AboutIcon>
          </Grid>
          <Grid item>
            <Typography variant="p" gutterBottom>
              {refstereotype.label}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
