import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  appcodes: {},
};
const appcodeSlice = createSlice({
  name: "appcodes",
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    stopLoading(state) {
      state.isLoading = false;
    },
    setInitialize(state, action) {
      state.isLoading = false;
      state.appcodes = action.payload;
    },
  },
});
export const { startLoading, stopLoading, setInitialize } =
  appcodeSlice.actions;
const appcodeReducer = appcodeSlice.reducer;

export default appcodeReducer;
