import React from "react";

import {
  Button,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { Add as AddIcon } from "@mui/icons-material";
import PointgainedForm from "./PointgainedForm";

// ----------------------------------------------------------------------
export default function PointgainedEditBtn({
  editPoints,
  editData,
  addLabel,
  handleCompletePoint,
}) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getIcon = () => {
    try {
      if (editData && editData.id) {
        return (
          <Button
            variant="outlined"
            color="warning"
            onClick={handleClickOpen}
            fullWidth
            sx={{ my: 3 }}
          >
            <EditIcon />
            {addLabel}
          </Button>
        );
      } else {
        return (
          <Button
            variant="outlined"
            color="warning"
            onClick={handleClickOpen}
            fullWidth
            sx={{ my: 3 }}
          >
            <AddIcon />
            {addLabel}
          </Button>
        );
      }
    } catch (error) {
      return <></>;
    }
  };
  const onCompletePoint = (data) => {
    handleCompletePoint(data);
    handleClose();
  };
  return (
    <div>
      {getIcon()}
      <Dialog open={open} fullWidth maxWidth="lg">
        <DialogTitle> {addLabel}</DialogTitle>
        <DialogContent>
          <PointgainedForm
            onCompletePoint={onCompletePoint}
            editPoints={editPoints}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Annuler</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
