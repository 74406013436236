import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { withTheme } from "@emotion/react";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Bar } from "react-chartjs-2";

import { MoreVertical } from "react-feather";

import {
  Avatar as MuiAvatar,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardMedia as MuiCardMedia,
  CardContent,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid as MuiGrid,
  LinearProgress as MuiLinearProgress,
  Link,
  Table,
  CardHeader,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography as MuiTypography,
  IconButton,
  Menu,
  MenuItem,
  CardActions,
} from "@mui/material";
import axios from "axios";
import { API_URL } from "config";
import { Add as AddIcon } from "@mui/icons-material";
import SportsGymnasticsIcon from "@mui/icons-material/SportsGymnastics";
import MicExternalOnIcon from "@mui/icons-material/MicExternalOn";
import MissedVideoCallIcon from "@mui/icons-material/MissedVideoCall";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";
import BookmarkAddedIcon from "@mui/icons-material/BookmarkAdded";
import EditIcon from "@mui/icons-material/Edit";
import { spacing } from "@mui/system";
import useProgram from "hooks/useProgram";
import { getMediaUrl } from "utils/objectUtil";
import BoosterEditBtn from "./BoosterEditBtn";

// ----------------------------------------------------------------------
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Button = styled(MuiButton)(spacing);

const Card = styled(MuiCard)(spacing);

const Chip = styled(MuiChip)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Grid = styled(MuiGrid)(spacing);

const Typography = styled(MuiTypography)(spacing);

const REF_MODEL = "booster";

export default function BoosterPage() {
  const { boosters, getBoosters } = useProgram();
  useEffect(() => {
    getBoosters();
  }, []);
  return (
    <>
      <Helmet title="Catalogue des boosters" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Boosters
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/app">
              Accueil
            </Link>
            <Typography>Tous les boosters</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <BoosterEditBtn />
        </Grid>
      </Grid>

      <Divider my={6} />
      <Grid container spacing={6}>
        <>
          {Array.isArray(boosters) && (
            <>
              {boosters.map((it, i) => {
                return (
                  <Grid item xs={12} lg={6} xl={3} key={i}>
                    <Detail data={it} />
                  </Grid>
                );
              })}
            </>
          )}
        </>
      </Grid>
    </>
  );
}
/* item card */
/* item card */
const Detail = ({ data }) => {
  const getChip = () => {
    try {
      if (!data) return <></>;
      if (!data.refboostertype) return <></>;
      if (data.refboostertype.code === "note") {
        return (
          <Chip
            label={data.refboostertype.label}
            onClick={() => console.log()}
            onDelete={() => console.log()}
            color="info"
            variant="contained"
            deleteIcon={<SpeakerNotesIcon />}
            sx={{ my: 1 }}
          />
        );
      } else if (data.refboostertype.code === "exe") {
        return (
          <Chip
            label={data.refboostertype.label}
            onClick={() => console.log()}
            onDelete={() => console.log()}
            color="primary"
            variant="contained"
            deleteIcon={<SportsGymnasticsIcon />}
            sx={{ my: 1 }}
          />
        );
      } else if (data.refboostertype.code === "audio") {
        return (
          <Chip
            label={data.refboostertype.label}
            onClick={() => console.log()}
            onDelete={() => console.log()}
            color="warning"
            variant="contained"
            deleteIcon={<MicExternalOnIcon />}
            sx={{ my: 1 }}
          />
        );
      } else if (data.refboostertype.code === "video") {
        return (
          <Chip
            label={data.refboostertype.label}
            onClick={() => console.log()}
            onDelete={() => console.log()}
            color="error"
            variant="contained"
            deleteIcon={<MissedVideoCallIcon />}
            sx={{ my: 1 }}
          />
        );
      }
    } catch (error) {
      return <></>;
    }
  };

  if (!data) return <></>;
  if (!data.refboostertype) return <></>;
  return (
    <Card>
      <CardHeader title={data.label} />

      <CardContent>
        {getChip()}
        <Typography mb={4} color="textSecondary" component="p">
          {`${data.refboostertype.code} - ${data.refboostertype.label}`}
        </Typography>
      </CardContent>
      <CardActions sx={{ display: "flex", justifyContent: "flex-end" }}>
        <BoosterEditBtn editData={data} onComplet={() => console.log()} />
      </CardActions>
    </Card>
  );
};
