import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { NavLink } from "react-router-dom";
import styled from "@emotion/styled";
import {
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Typography,
  Link,
  Grid,
} from "@mui/material";
import { spacing } from "@mui/system";
import useDataAppcode from "hooks/useDataAppcode";
import useDataUser from "hooks/useDataUser";
import AppcodesGrid from "./AppcodesGrid";
import AppcodeEditBtn from "./AppcodeEditBtn";

// ----------------------------------------------------------------------
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
export default function AppcodesPage() {
  const { initUsers } = useDataUser();
  const { initAppcodes } = useDataAppcode();
  useEffect(() => {
    initUsers();
    initAppcodes();
  }, []);
  return (
    <>
      <Helmet title="Gestion des codes d'application" />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Codes d'application
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/app">
              Accueil
            </Link>
            <Typography>Codes d'application</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <AppcodeEditBtn />
        </Grid>
      </Grid>

      <Divider my={6} />
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <AppcodesGrid />
        </Grid>
      </Grid>
    </>
  );
}
