import { useDispatch, useSelector } from "react-redux";
import {
  startLoading,
  initUsers,
  initUserApp,
  stopLoading,
} from "redux/slices/userSlice";
import axios from "axios";
import { API_URL } from "config";
import { getObjectWithoutPropsNull } from "utils/objectUtil";
// ----------------------------------------------------------------------

export default function useDataUser() {
  const dispatch = useDispatch();
  const { userApp, users, isLoading, isLoaded } = useSelector(
    (state) => state.users
  );

  return {
    users,
    userApp,
    isLoaded,
    isLoading,
    initUsers: async () => {
      try {
        dispatch(startLoading());
        const res = await axios.get(API_URL.userapp);
        if (res && res.data) {
          const users = res.data;
          dispatch(initUsers({ users }));
        } else {
          throw new Error("UsersData ne sont pas disponibles");
        }
      } catch (error) {
        dispatch(stopLoading());
        console.error();
        throw new Error("Référentiels ne sont pas disponibles");
      }
    },
    initUserAppById: async (id) => {
      try {
        dispatch(startLoading());
        const res = await axios.get(API_URL.userapp + "/" + id);
        if (res && res.data) {
          dispatch(initUserApp(res.data));
        } else {
          throw new Error("UsersData ne sont pas disponibles");
        }
      } catch (error) {
        dispatch(stopLoading());
        console.error();
        throw new Error("Référentiels ne sont pas disponibles");
      }
    },
    saveUser: async (u) => {
      try {
        delete u.createdby;
        delete u.modifiedby;
        const newData = getObjectWithoutPropsNull(u);
        if (u && u.id) {
          const res = await axios.patch(API_URL.userapp + "/" + u.id, newData);
        } else {
          const res = await axios.post(API_URL.userapp, newData);
        }
      } catch (error) {
        dispatch(stopLoading());
        console.error();
        throw error;
      }
    },
    delUser: async (u) => {
      try {
        if (u && u.id) {
          const res = await axios.delete(API_URL.userapp + "/" + u.id);
        }
      } catch (error) {
        console.error();
        throw error;
      }
    },
    changePassword: async (u, password) => {
      try {
        if (!u) return;
        const res = await axios.post(
          API_URL.userapp + "/change-password/" + u.id,
          { password }
        );
      } catch (error) {
        console.error();
        throw error;
      }
    },
    submitImport: async (formData) => {
      try {
        if (!formData) return;
        const res = await axios.post(API_URL.userapp + "/import", formData);
        return res.data;
      } catch (error) {
        console.error();
        throw error;
      }
    },
    runImport: async (arr) => {
      try {
        if (!arr) return;
        const res = await axios.post(API_URL.userapp + "/run-import", {
          users: [...arr],
        });
        return res.data;
      } catch (error) {
        console.error();
        throw error;
      }
    },
    downloadProgUser: async (id) => {
      try {
        let res = null;
        let fileName = "";
        if (id) {
          res = await axios.get(
            API_URL.manageUserApp + "/downloadProgram/" + id
          );
          fileName = "user_" + id;
        } else {
          res = await axios.get(API_URL.manageUserApp + "/downloadAllProgram");
          fileName = "all";
        }
        if (res && res.data) {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `${fileName}_${new Date().getTime()}.csv`
          );
          document.body.appendChild(link);
          link.click();
          link.remove();
        }
      } catch (error) {
        console.error();
        throw error;
      }
    },
    downloadUserPoints: async (id) => {
      try {
        let res = null;
        let fileName = "";
        if (id) {
          res = await axios.get(
            API_URL.manageUserApp + "/downloadPoints/" + id
          );
          fileName = "user_points_" + id;
        } else {
          res = await axios.get(API_URL.manageUserApp + "/downloadAllPoints");
          fileName = "all_points";
        }
        if (res && res.data) {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `${fileName}_${new Date().getTime()}.csv`
          );
          document.body.appendChild(link);
          link.click();
          link.remove();
        }
      } catch (error) {
        console.error();
        throw error;
      }
    },
  };
}
