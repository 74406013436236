import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import axios from "axios";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
// ----------------------------------------------------------------------
export default function AudioBoxFromUrl({ audio, sx }) {
  const [imageObj, setimageObj] = useState(null);
  useEffect(() => {
    return () => {
      setimageObj(null);
    };
  }, []);
  useEffect(() => {
    if (!audio) return;
    const init = async () => {
      try {
        const response = await axios.get(`${audio}`, {
          responseType: "arraybuffer",
        });
        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });

        const imageDataUrl = URL.createObjectURL(blob);
        setimageObj(imageDataUrl);
      } catch (error) {
        console.error(error);
      }
    };
    init();
  }, [audio]);
  if (!imageObj) return <></>;
  return (
    <>
      <AudioPlayer
        autoPlayAfterSrcChange={false}
        autoPlay={false}
        src={imageObj}
        onPlay={(e) => console.log("onPlay")}
      />
    </>
  );
}
