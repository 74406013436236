import * as React from "react";

import styled from "@emotion/styled";
import {
  Avatar as MuiAvatar,
  Card as MuiCard,
  CardHeader,
  CardContent,
  Grid,
  Divider as MuiDivider,
  Typography,
  Chip as MuiChip,
  CardActions,
  Button,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Box,
} from "@mui/material";
import { spacing } from "@mui/system";
import LoupeIcon from "@mui/icons-material/Loupe";
import { red, green } from "@mui/material/colors";

import CardRefSubject from "./CardRefSubject";
import CardDayStereoChallenge from "./CardDayStereoChallenge";
import CardDayChallenge from "./CardDayChallenge";
// ----------------------------------------------------------------------
const REF_MODEL_REFSUBJECT = "refsubject";
const REF_MODEL_CHALLENGE = "challenge";
const REF_MODEL_STREOTYPECHALLENGE = "stereotypechallenge";
const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Avatar = styled(MuiAvatar)(spacing);
const Chip = styled(MuiChip)(spacing);

export default function PublishDayflow({ editProgram, dayflow }) {
  if (!editProgram) return <></>;
  if (!dayflow) return <></>;
  const { daystep } = dayflow;
  const title =
    dayflow && dayflow.daythemes && dayflow.daythemes.length > 1
      ? ` ${dayflow.daythemes.length} thèmes proposés pour le jour ${daystep}`
      : null;
  return (
    <>
      {title && (
        <Box
          sx={{ p: 3, my: 2 }}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Typography variant="h4" gutterBottom>
            {title}
          </Typography>
        </Box>
      )}
      {dayflow.daythemes.map((theme, i) => {
        const { daychoices, reftheme } = theme;

        const cardTitle =
          dayflow.daythemes.length === 1
            ? reftheme.label
            : `Thème ${i + 1}: ${reftheme.label}`;
        return (
          <React.Fragment key={i}>
            {i > 0 && <Divider my={3} />}
            <Card>
              <CardHeader title={cardTitle} />
              <CardContent>
                <GridDaychoices
                  dayflow={dayflow}
                  daytheme={theme}
                  daychoices={daychoices}
                  daystep={daystep}
                  editProgram={editProgram}
                />
              </CardContent>
            </Card>
          </React.Fragment>
        );
      })}
    </>
  );
}
const GridDaychoices = ({
  dayflow,
  daytheme,
  daychoices,
  daystep,
  editProgram,
}) => {
  return (
    <Grid container spacing={6} sx={{ p: 4 }}>
      {daychoices.map((daychoice, i) => {
        const { refsubject, challenges, stereotypechallenges } = daychoice;
        let arrChallenge = null;
        let refModel = null;
        if (Number(daystep) === 1) {
          arrChallenge = stereotypechallenges;
          refModel = REF_MODEL_STREOTYPECHALLENGE;
        } else {
          arrChallenge = challenges;
          refModel = REF_MODEL_CHALLENGE;
        }
        try {
          return (
            <Grid key={i} item xs={12} lg={12} xl={12}>
              <Card>
                <CardHeader
                  avatar={
                    <Avatar sx={{ bgcolor: green[500] }} aria-label="recipe">
                      {i + 1}
                    </Avatar>
                  }
                  title={refsubject.label}
                />
                <CardRefSubject refsubject={refsubject} />
                {Array.isArray(arrChallenge) && (
                  <>
                    <CardContent>
                      {Number(daystep) !== 1 && (
                        <Typography mb={4} variant="h4">
                          {`${arrChallenge.length} défis`}
                        </Typography>
                      )}
                      {Number(daystep) === 1 ? (
                        <>
                          {arrChallenge.map((c, idx) => {
                            return (
                              <CardDayStereoChallenge
                                key={idx}
                                challengeDay={c}
                                refModel={refModel}
                              />
                            );
                          })}
                        </>
                      ) : (
                        <Grid container spacing={6}>
                          {arrChallenge.map((c, idx) => {
                            return (
                              <Grid key={idx} item xs={6} lg={4}>
                                <ItemCardChallenge
                                  challengeDay={c}
                                  refModel={refModel}
                                />
                              </Grid>
                            );
                          })}
                        </Grid>
                      )}
                    </CardContent>
                  </>
                )}
              </Card>
            </Grid>
          );
        } catch (error) {
          console.error(error);
          return <></>;
        }
      })}
    </Grid>
  );
};
const ItemCardChallenge = ({ challengeDay }) => {
  return (
    <Card variant="outlined" sx={{ minWidth: 275 }}>
      <CardContent>
        <Typography variant="h5" component="div">
          {challengeDay.title}
        </Typography>
        <Typography variant="body2">{challengeDay.description}</Typography>
      </CardContent>
      <CardActions>
        <ViewChallengeBtn challengeDay={challengeDay} />
      </CardActions>
    </Card>
  );
};

const ViewChallengeBtn = ({ challengeDay }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button aria-label="details" size="small" onClick={handleClickOpen}>
        <LoupeIcon size="small" sx={{ mr: 2 }} /> Détail du défi
      </Button>
      <Dialog open={open} fullWidth maxWidth="xl">
        <DialogTitle>
          {`Détail du défid : ${challengeDay.code} ${challengeDay.title}`}
        </DialogTitle>
        <DialogContent>
          <CardDayChallenge challengeDay={challengeDay} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Annuler</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
