import {
  addDays,
  format,
  formatDistanceToNow,
  intervalToDuration,
} from "date-fns";
import { fr } from "date-fns/locale";
// ----------------------------------------------------------------------

export function fDate(date) {
  return format(new Date(date), "dd MMMM yyyy", {
    awareOfUnicodeTokens: true,
    locale: fr,
  });
}

export function fDateTime(date) {
  try {
    return format(new Date(date), "dd MMM yyyy HH:mm", {
      awareOfUnicodeTokens: true,
      locale: fr,
    });
  } catch (error) {
    return "";
  }
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), "dd/MM/yyyy hh:mm p");
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  });
}
export function durationBetween2Dates(d1, d2) {
  try {
    const obj = intervalToDuration({
      start: new Date(d1),
      end: new Date(d2),
    });
    return obj;
  } catch (error) {
    console.error(error);
    return 0;
  }
}
export function addDaysToDate(dt, d) {
  try {
    const newDt = addDays(new Date(dt), d);
    return newDt;
  } catch (error) {
    console.error(error);
  }
}
