import React, { useEffect, useState } from "react";
import { Box, TextField, MenuItem, IconButton } from "@mui/material";
import useReferentiel from "hooks/useReferentiel";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import useChallenge from "hooks/useChallenge";
// ----------------------------------------------------------------------
const initialFilters = {
  theme: "",
  subject: "",
  type: "",
  stereotype: "",
  updatedAt: null,
};
export default function ChallengeFilters({ onChangeFilters }) {
  const [filters, setfilters] = useState(initialFilters);
  const { chalFilters } = useChallenge();

  useEffect(() => {
    if (!chalFilters) return;
    setfilters(chalFilters);
  }, [chalFilters]);
  const handleChange = (field, e) => {
    try {
      const newFilters = { ...filters };
      newFilters[field] = e.target.value;
      if (field === "theme") {
        newFilters.subject = "";
      }
      newFilters.updatedAt = new Date().getTime();
      setfilters(newFilters);
    } catch (error) {
      console.error(error);
    }
  };
  const handeReset = () => {
    setfilters({ ...initialFilters, updatedAt: new Date().getTime() });
  };
  useEffect(() => {
    if (!filters) return;
    if (!filters.updatedAt) return;
    const newFilters = { ...filters };
    delete newFilters.updatedAt;
    onChangeFilters(newFilters);
  }, [filters.updatedAt]);
  const { refs } = useReferentiel();

  const { reftheme, refsubject, refchallengetype, refstereotype } = refs;

  if (!refs) return;
  const filteredSubjects = !Array.isArray(refsubject)
    ? []
    : filters && filters.theme !== ""
    ? refsubject.filter((it) => {
        return String(it.reftheme.id) === String(filters.theme);
      })
    : refsubject;
  return (
    <>
      <Box
        component="form"
        sx={{ display: "flex", flexWrap: "wrap", mb: 3 }}
        noValidate
        autoComplete="off"
      >
        {/* theme */}
        {Array.isArray(reftheme) && (
          <TextField
            size="small"
            sx={{ m: 1, width: "25ch" }}
            select
            label="Thème"
            value={filters.theme}
            onChange={(e) => handleChange("theme", e)}
          >
            <MenuItem value="">Tous</MenuItem>
            {reftheme.map((option, i) => (
              <MenuItem key={i} value={option.id}>
                {`${option.code}-${option.label}`}
              </MenuItem>
            ))}
          </TextField>
        )}
        {/* subject */}
        {Array.isArray(filteredSubjects) && (
          <TextField
            size="small"
            sx={{ m: 1, width: "25ch" }}
            select
            label="Sujet"
            value={filters.subject}
            onChange={(e) => handleChange("subject", e)}
          >
            <MenuItem value="">Tous</MenuItem>
            {filteredSubjects.map((option, i) => (
              <MenuItem key={i} value={option.id}>
                {`${option.code}-${option.label}`}
              </MenuItem>
            ))}
          </TextField>
        )}
        {/* Challenge type */}

        {Array.isArray(refchallengetype) && (
          <TextField
            size="small"
            sx={{ m: 1, width: "25ch" }}
            select
            label="Type de défi"
            value={filters.type}
            onChange={(e) => handleChange("type", e)}
          >
            <MenuItem value="">Tous</MenuItem>
            {refchallengetype.map((option, i) => (
              <MenuItem key={i} value={option.id}>
                {`${option.code}-${option.label}`}
              </MenuItem>
            ))}
          </TextField>
        )}
        {/* Stereo type */}
        {Array.isArray(refstereotype) && (
          <TextField
            size="small"
            sx={{ m: 1, width: "25ch" }}
            select
            label="Stéreotype"
            value={filters.stereotype}
            onChange={(e) => handleChange("stereotype", e)}
          >
            <MenuItem value="">Tous</MenuItem>
            {refstereotype.map((option, i) => (
              <MenuItem key={i} value={option.id}>
                {`${option.code}-${option.label}`}
              </MenuItem>
            ))}
          </TextField>
        )}
        <IconButton
          variant="contained"
          color="error"
          onClick={handeReset}
          sx={{ ml: 6 }}
        >
          <DeleteForeverIcon />
        </IconButton>
      </Box>
    </>
  );
}
