import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { MessageCircle } from "react-feather";
import {
  Avatar,
  AvatarGroup as MuiAvatarGroup,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Card as MuiCard,
  CardHeader,
  CardActions,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Grid,
  Typography as MuiTypography,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Box,
  Stack,
} from "@mui/material";
import LoupeIcon from "@mui/icons-material/Loupe";
import EmergencyRecordingIcon from "@mui/icons-material/EmergencyRecording";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";
import SmartButtonIcon from "@mui/icons-material/SmartButton";
import { spacing } from "@mui/system";
import { orange, green, blue } from "@mui/material/colors";
import { Done as DoneIcon } from "@mui/icons-material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import axios from "axios";
import { API_URL } from "config";
import AvatarImgFromUrl from "components/media/AvatarImgFromUrl";
import useNotification from "hooks/useNotification";
import useProgram from "hooks/useProgram";
import CardRefSubject from "./CardRefSubject";
import CardDayChallenge from "./CardDayChallenge";
// ----------------------------------------------------------------------

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)`
  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(4)};
  }
`;

const AvatarGroup = styled(MuiAvatarGroup)`
  display: inline-flex;
`;

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const TaskWrapper = styled(Card)`
  border: 1px solid ${(props) => props.theme.palette.grey[300]};
  margin-bottom: ${(props) => props.theme.spacing(4)};
  &:hover {
    background: ${(props) => props.theme.palette.background.default};
  }
`;

const TaskWrapperContent = styled(CardContent)`
  position: relative;

  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(4)};
  }
`;

const TaskAvatars = styled.div`
  margin-top: ${(props) => props.theme.spacing(1)};
`;

const MessageCircleIcon = styled(MessageCircle)`
  color: ${(props) => props.theme.palette.grey[500]};
  vertical-align: middle;
`;

const TaskBadge = styled.div`
  background: ${(props) => props.color};
  width: 40px;
  height: 6px;
  border-radius: 6px;
  display: inline-block;
  margin-right: ${(props) => props.theme.spacing(2)};
`;

const TaskNotifications = styled.div`
  display: flex;
  position: absolute;
  bottom: ${(props) => props.theme.spacing(4)};
  right: ${(props) => props.theme.spacing(4)};
`;

const TaskNotificationsAmount = styled.div`
  color: ${(props) => props.theme.palette.grey[500]};
  font-weight: 600;
  margin-right: ${(props) => props.theme.spacing(1)};
  line-height: 1.75;
`;

const Typography = styled(MuiTypography)(spacing);

const TaskTitle = styled(Typography)`
  font-weight: 600;
  font-size: 15px;
  margin-right: ${(props) => props.theme.spacing(10)};
`;
const REF_MODEL_CHALLENGE = "challenge";
export default function ThemeConfig({ editProgram, editTheme }) {
  if (!editProgram) return <></>;
  if (!editTheme) return <></>;

  return (
    <>
      <Grid container spacing={6} sx={{ p: 4 }}>
        {editTheme.subjects.map((subject, i) => {
          const { refsubject, challenges } = subject;
          try {
            return (
              <Grid key={i} item xs={12} lg={12} xl={12}>
                <Card>
                  <CardHeader
                    avatar={
                      <Avatar sx={{ bgcolor: green[500] }} aria-label="recipe">
                        {i + 1}
                      </Avatar>
                    }
                    title={refsubject.label}
                  />
                  <CardRefSubject refsubject={refsubject} />
                  {Array.isArray(challenges) && (
                    <>
                      <CardContent>
                        <Typography mb={4} variant="h4">
                          {`${challenges.length} défis`}
                        </Typography>

                        <Grid container spacing={6}>
                          {challenges.map((c, idx) => {
                            return (
                              <Grid key={idx} item xs={6} lg={4}>
                                <ItemCardChallenge
                                  challengeDay={c}
                                  refModel={REF_MODEL_CHALLENGE}
                                />
                              </Grid>
                            );
                          })}
                        </Grid>
                      </CardContent>
                    </>
                  )}
                </Card>
              </Grid>
            );
          } catch (error) {
            console.error(error);
            return <></>;
          }
        })}
      </Grid>
    </>
  );
}

const ItemCardChallenge = ({ challengeDay }) => {
  const getIcon = () => {
    try {
      if (!challengeDay) return <></>;
      if (Number(challengeDay.refchallengetype.code) === 2) {
        /* note */
        return <SpeakerNotesIcon />;
      } else if (Number(challengeDay.refchallengetype.code) === 3) {
        /* video */
        return <EmergencyRecordingIcon />;
      } else if (Number(challengeDay.refchallengetype.code) === 5) {
        /* specific */
        return <SmartButtonIcon />;
      }
      return <></>;
    } catch (error) {
      return <></>;
    }
  };
  if (!challengeDay) return <></>;
  return (
    <Card variant="outlined" sx={{ minWidth: 275 }}>
      <CardContent>
        <Typography variant="h5" component="div">
          {challengeDay.title}
        </Typography>
        <Typography variant="body2">{challengeDay.description}</Typography>
      </CardContent>
      <CardActions>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
          sx={{ width: "100%", px: 2 }}
        >
          {getIcon()}
          <ViewChallengeBtn challengeDay={challengeDay} />
        </Stack>
      </CardActions>
    </Card>
  );
};

const ViewChallengeBtn = ({ challengeDay }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button aria-label="details" size="small" onClick={handleClickOpen}>
        <LoupeIcon size="small" sx={{ mr: 2 }} /> Détail du défi
      </Button>
      <Dialog open={open} fullWidth maxWidth="xl">
        <DialogTitle>
          {`Détail du défid : ${challengeDay.code} ${challengeDay.title}`}
        </DialogTitle>
        <DialogContent>
          <CardDayChallenge challengeDay={challengeDay} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Annuler</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
