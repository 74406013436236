import { createSlice } from "@reduxjs/toolkit";
import { isSwitchStatement } from "typescript";

const initialState = {
  isLoading: false,
  isLoaded: false,
  users: null,
  userApp: null,
};
const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
      state.isLoaded = false;
    },
    stopLoading(state) {
      state.isLoading = false;
      state.isLoaded = true;
    },
    initUsers(state, action) {
      state.isLoaded = true;
      state.isLoading = false;
      state.users = action.payload.users;
    },
    initUserApp(state, action) {
      state.isLoaded = true;
      state.isLoading = false;
      state.userApp = action.payload;
    },
  },
});
export const { startLoading, stopLoading, initUsers, initUserApp } =
  userSlice.actions;
const userReducer = userSlice.reducer;

export default userReducer;
