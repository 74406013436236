import { createSlice } from "@reduxjs/toolkit";
import { isSwitchStatement } from "typescript";

const initialState = {
  isLoading: false,
  isLoaded: false,
  gifts: null,
};
const giftSlice = createSlice({
  name: "gift",
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
      state.isLoaded = false;
    },
    stopLoading(state) {
      state.isLoading = false;
      state.isLoaded = true;
    },
    initGifts(state, action) {
      state.isLoaded = true;
      state.isLoading = false;
      state.gifts = action.payload;
    },
  },
});
export const { startLoading, stopLoading, initGifts } = giftSlice.actions;
const giftReducer = giftSlice.reducer;

export default giftReducer;
