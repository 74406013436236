import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { MessageCircle } from "react-feather";
import {
  Avatar,
  AvatarGroup as MuiAvatarGroup,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Grid,
  Link,
  Typography as MuiTypography,
  Stack,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Box,
} from "@mui/material";
import { spacing } from "@mui/system";
import { orange, green, blue } from "@mui/material/colors";
import SportsGymnasticsIcon from "@mui/icons-material/SportsGymnastics";
import MicExternalOnIcon from "@mui/icons-material/MicExternalOn";
import EmergencyRecordingIcon from "@mui/icons-material/EmergencyRecording";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";
import SmartButtonIcon from "@mui/icons-material/SmartButton";
import { Done as DoneIcon } from "@mui/icons-material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import axios from "axios";
import { API_URL } from "config";
import { getMediaUrl } from "utils/objectUtil";
import AvatarImgFromUrl from "components/media/AvatarImgFromUrl";
import useNotification from "hooks/useNotification";
import useProgram from "hooks/useProgram";
import ThemeMessagesBtn from "./ThemeMessagesBtn";
import CardDayChallenge from "./CardDayChallenge";
// ----------------------------------------------------------------------

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)`
  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(4)};
  }
`;

const AvatarGroup = styled(MuiAvatarGroup)`
  display: inline-flex;
`;

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const TaskWrapper = styled(Card)`
  border: 1px solid ${(props) => props.theme.palette.grey[300]};
  margin-bottom: ${(props) => props.theme.spacing(4)};
  cursor: grab;

  &:hover {
    background: ${(props) => props.theme.palette.background.default};
  }
`;

const TaskWrapperContent = styled(CardContent)`
  position: relative;

  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(4)};
  }
`;

const TaskAvatars = styled.div`
  margin-top: ${(props) => props.theme.spacing(1)};
`;

const MessageCircleIcon = styled(MessageCircle)`
  color: ${(props) => props.theme.palette.grey[500]};
  vertical-align: middle;
`;

const TaskBadge = styled.div`
  background: ${(props) => props.color};
  width: 40px;
  height: 6px;
  border-radius: 6px;
  display: inline-block;
  margin-right: ${(props) => props.theme.spacing(2)};
`;

const TaskNotifications = styled.div`
  display: flex;
  position: absolute;
  bottom: ${(props) => props.theme.spacing(4)};
  right: ${(props) => props.theme.spacing(4)};
`;

const TaskNotificationsAmount = styled.div`
  color: ${(props) => props.theme.palette.grey[500]};
  font-weight: 600;
  margin-right: ${(props) => props.theme.spacing(1)};
  line-height: 1.75;
`;

const Typography = styled(MuiTypography)(spacing);

const TaskTitle = styled(Typography)`
  font-weight: 600;
  font-size: 15px;
  margin-right: ${(props) => props.theme.spacing(10)};
`;
const refModel = "challenge";
export default function ThemeConfig({ editProgram, editTheme }) {
  const [columns, setColumns] = useState(null);
  const [avSubjecs, setavSubjecs] = useState(null);
  const [loading, setloading] = useState(false);
  const { notif } = useNotification();
  const { saveThemeConfig, initProgramById } = useProgram();
  useEffect(() => {
    if (!editTheme) return;
    if (!editTheme.reftheme) return;
    const init = async () => {
      try {
        const res = await axios.post(API_URL.refsubject + "/queryForProg", {
          filter: { reftheme: editTheme.reftheme.id },
          options: {},
        });
        if (res && res.data) {
          const av = res.data.filter((it) => {
            const existSubj = editTheme.subjects.findIndex(
              (sub) =>
                sub.refsubject &&
                it.refsubject &&
                String(sub.refsubject.id) === String(it.refsubject.id)
            );
            return existSubj < 0;
          });
          setavSubjecs(av);
        }
      } catch (error) {
        console.error(error);
      }
    };
    init();
  }, [editTheme]);

  useEffect(() => {
    if (!avSubjecs) return;
    if (!editTheme) return;
    if (!editTheme.subjects) return;
    setColumns({
      av: {
        title: "Sujets disponibles",
        description: "La liste des sujets par thème synchronisé au référentiel",
        items: [...avSubjecs],
      },
      choiced: {
        title: "Sujets choisis",
        description: "Cette liste sera appliquée à la publication du programme",
        items: [...editTheme.subjects],
      },
    });
  }, [editTheme, avSubjecs]);

  const validateTheme = async () => {
    try {
      setloading(true);
      const choicedSubjects = columns.choiced.items.map((it) => {
        return it.refsubject.id;
      });
      await saveThemeConfig(editProgram.id, {
        reftheme: editTheme.reftheme.id,
        subjects: choicedSubjects,
      });
      await initProgramById(editProgram.id);
      notif("Sauvegarde réussie");
      setloading(false);
    } catch (error) {
      setloading(false);
      notif(null, error);
      console.error();
    }
  };
  const onDragEnd = (result, columns, setColumns) => {
    try {
      if (loading) return;
      if (!result.destination) return;
      const { source, destination } = result;
      if (source.droppableId !== destination.droppableId) {
        const sourceColumn = columns[source.droppableId];
        const destColumn = columns[destination.droppableId];
        const sourceItems = [...sourceColumn.items];
        const destItems = [...destColumn.items];
        const [removed] = sourceItems.splice(source.index, 1);
        destItems.splice(destination.index, 0, removed);
        setColumns({
          ...columns,
          [source.droppableId]: {
            ...sourceColumn,
            items: sourceItems,
          },
          [destination.droppableId]: {
            ...destColumn,
            items: destItems,
          },
        });
      } else {
        const column = columns[source.droppableId];
        const copiedItems = [...column.items];
        const [removed] = copiedItems.splice(source.index, 1);
        copiedItems.splice(destination.index, 0, removed);
        setColumns({
          ...columns,
          [source.droppableId]: {
            ...column,
            items: copiedItems,
          },
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  if (!editProgram) return <></>;
  if (!editTheme) return <></>;
  if (!columns) return <></>;
  return (
    <>
      <Grid justifyContent="flex-end" container>
        <Grid item sx={{ mx: 6, mt: 3 }}>
          <ThemeMessagesBtn editProgram={editProgram} editTheme={editTheme} />
        </Grid>
      </Grid>
      <Grid container sx={{ p: 6 }}>
        <DragDropContext
          onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
        >
          {Object.entries(columns).map(([columnId, column]) => {
            return (
              <Lane key={columnId} column={column}>
                <Droppable droppableId={columnId} key={columnId}>
                  {(provided) => {
                    return (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={{
                          minHeight: 50,
                        }}
                      >
                        {column.items.map((item, index) => {
                          return (
                            <React.Fragment key={index}>
                              {item ? (
                                <Draggable
                                  key={item._id}
                                  draggableId={item._id}
                                  index={index}
                                >
                                  {(provided) => {
                                    return (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                      >
                                        <Task item={item} />
                                      </div>
                                    );
                                  }}
                                </Draggable>
                              ) : (
                                <></>
                              )}
                            </React.Fragment>
                          );
                        })}
                        {provided.placeholder}
                      </div>
                    );
                  }}
                </Droppable>
              </Lane>
            );
          })}
        </DragDropContext>
        <Divider my={6} />
        <Button
          color="primary"
          variant="contained"
          fullWidth
          onClick={validateTheme}
        >
          <DoneIcon />
          Valider
        </Button>
      </Grid>
    </>
  );
}
const Lane = ({ column, children }) => {
  return (
    <Grid item xs={12} lg={4} xl={4}>
      <Card mb={6}>
        <CardContent pb={0}>
          <Typography variant="h6" gutterBottom>
            {column.title}
          </Typography>
          <Typography variant="body2" mb={4}>
            {column.description}
          </Typography>
          {children}
        </CardContent>
      </Card>
    </Grid>
  );
};

const Task = ({ item }) => {
  const handleOpenChallenge = (challenge) => {
    try {
      console.log(challenge);
    } catch (error) {
      console.error(error);
    }
  };
  if (!item) return <></>;
  if (!item.refsubject) return <></>;
  return (
    <TaskWrapper mb={4}>
      <TaskWrapperContent>
        {item.badges &&
          item.badges.map((color, i) => <TaskBadge color={color} key={i} />)}

        <TaskTitle variant="body1" gutterBottom>
          {item.refsubject ? item.refsubject.label : "errrrrrrrorrrr"}
        </TaskTitle>

        <TaskAvatars>
          <Stack direction="column" spacing={2}>
            {item.challenges &&
              item.challenges.map((challenge, i) => (
                <ViewChallengeBtn key={i} challenge={challenge} />
              ))}
          </Stack>
          {/*  <AvatarGroup max={3}>
            {item.challenges &&
              item.challenges.map((challenge, i) => (
                <AvatarImgFromUrl
                  key={i}
                  image={getMediaUrl(challenge, "image", refModel)}
                />
              ))}
          </AvatarGroup> */}
        </TaskAvatars>

        {!!item.notifications && item.notifications > 0 && (
          <TaskNotifications>
            <TaskNotificationsAmount>
              {item.notifications}
            </TaskNotificationsAmount>
            <MessageCircleIcon />
          </TaskNotifications>
        )}
      </TaskWrapperContent>
    </TaskWrapper>
  );
};

const ViewChallengeBtn = ({ challenge }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const getIcon = () => {
    try {
      if (!challenge) return <></>;
      if (Number(challenge.refchallengetype.code) === 2) {
        /* note */
        return <SpeakerNotesIcon />;
      } else if (Number(challenge.refchallengetype.code) === 3) {
        /* video */
        return <EmergencyRecordingIcon />;
      } else if (Number(challenge.refchallengetype.code) === 5) {
        /* specific */
        return <SmartButtonIcon />;
      } else if (Number(challenge.refchallengetype.code) === 1) {
        /* video */
        return <MicExternalOnIcon />;
      } else if (Number(challenge.refchallengetype.code) === 4) {
        /* video */
        return <SportsGymnasticsIcon />;
      }
      return <></>;
    } catch (error) {
      return <></>;
    }
  };
  if (!challenge) return <></>;
  return (
    <>
      <div
        aria-hidden="true"
        onClick={handleClickOpen}
        onKeyDown={handleClickOpen}
      >
        <Stack direction="row" spacing={2} alignItems="center">
          <AvatarImgFromUrl image={getMediaUrl(challenge, "image", refModel)} />
          {getIcon()}
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            <Typography variant="span" nowrap>
              {challenge.title}
            </Typography>
          </div>
        </Stack>
      </div>

      <Dialog open={open} fullWidth maxWidth="xl">
        <DialogTitle>
          {`Détail du défi : ${challenge.code} ${challenge.title}`}
        </DialogTitle>
        <DialogContent>
          <CardDayChallenge challengeDay={challenge} refModel={refModel} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Annuler</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
