import React, { useState } from "react";

import {
  Button,
  IconButton,
  Card,
  Popover,
  CardActions,
  CardContent,
  Typography,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import AppShortcutIcon from "@mui/icons-material/AppShortcut";
import useProgram from "hooks/useProgram";
import useNotification from "hooks/useNotification";
// ----------------------------------------------------------------------
export default function PublishBtn({ editProgram }) {
  const { publishProgram } = useProgram();
  const { notif } = useNotification();
  const [submitting, setsubmitting] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleConfirm = async () => {
    try {
      if (!editProgram) return;
      setsubmitting(true);
      await publishProgram(editProgram);
      notif("Publication réussie");
      setsubmitting(false);
      setAnchorEl(null);
    } catch (error) {
      console.error(error);
      notif(null, error);
      setsubmitting(false);
    }
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const isDisabled = () => {
    try {
      if (!editProgram) return false;
      return !editProgram.isValidated;
    } catch (error) {
      return false;
    }
  };
  return (
    <div>
      <Button
        onClick={handleClick}
        color="success"
        disabled={isDisabled()}
        variant="contained"
      >
        <AppShortcutIcon sx={{ mr: 2 }} /> Publier
      </Button>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Card
          sx={{
            minWidth: 275,
            backgroundColor: "#1A2027",
          }}
        >
          <CardContent>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              Etes vous sur de publier ?
            </Typography>
          </CardContent>
          <CardActions
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Button disabled={submitting} onClick={handleConfirm}>
              Valider
            </Button>
            {submitting ? (
              <CircularProgress />
            ) : (
              <Button onClick={handleClose}>Annuler</Button>
            )}
          </CardActions>
        </Card>
      </Popover>
    </div>
  );
}
