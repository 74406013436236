import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import styled from "@emotion/styled";
import {
  Alert as MuiAlert,
  Checkbox,
  FormControlLabel,
  Button,
  MenuItem,
  TextField as MuiTextField,
  FormHelperText,
  ListSubheader,
  RadioGroup,
  Box,
  Typography,
  FormLabel,
  OutlinedInput,
  InputLabel,
  FormControl,
  ListItemText,
  Select,
} from "@mui/material";
import { spacing } from "@mui/system";
import useNotification from "hooks/useNotification";
import useReferentiel from "hooks/useReferentiel";

import { maxdaysprog } from "config";
// ----------------------------------------------------------------------
const TextField = styled(MuiTextField)(spacing);
export default function RefthemeForm({ editData, onComplete }) {
  const [refperiods, setrefperiods] = useState(null);
  const [periodsSelected, setperiodsSelected] = useState([]);
  const { saveReftheme, initRefs } = useReferentiel();
  const { notif } = useNotification();
  const { appconfigs, refs } = useReferentiel();
  useEffect(() => {
    if (!appconfigs) return;
    const valueMaxDaysProg = appconfigs.find((it) => it.code === maxdaysprog);
    if (valueMaxDaysProg) {
      const arr = [...Array(Number(valueMaxDaysProg.value))].map(
        (it, i) => i + 1
      );
      setrefperiods(arr);
    }
  }, [appconfigs]);

  const dataSchema = Yup.object().shape({
    code: Yup.string().max(20).required("Veuillez renseigner le code"),
    label: Yup.string().max(50).required(`Veuillez renseigner le libellé`),
    description: Yup.string().max(100),
    periods: Yup.array().of(Yup.number()),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      code: editData && editData.code ? editData.code : "",
      label: editData && editData.label ? editData.label : "",
      description: editData && editData.description ? editData.description : "",
      periods: editData && editData.periods ? [...editData.periods] : [],
    },
    validationSchema: dataSchema,
    onSubmit: async () => {
      try {
        const newData =
          editData && editData.id ? { ...editData, ...values } : { ...values };
        const arrPeriod = periodsSelected
          ? [...periodsSelected.sort((a, b) => a - b)]
          : [];
        await saveReftheme({
          ...newData,
          periods: [...arrPeriod],
        });
        await initRefs();
        notif("Sauvegarde réussie");
        onComplete();
      } catch (error) {
        console.error(error);
        notif(null, error);
        setSubmitting(false);
      }
    },
  });

  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setSubmitting,
    handleChange,
    setFieldValue,
  } = formik;
  useEffect(() => {
    if (!values) return;
    if (!values.periods) return;

    setperiodsSelected(values.periods);
  }, [values]);

  const handleChangePeriods = (event) => {
    const {
      target: { value },
    } = event;
    setperiodsSelected(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <TextField
            fullWidth
            type="text"
            label="Code"
            {...getFieldProps("code")}
            error={Boolean(touched.code && errors.code)}
            helperText={touched.code && errors.code}
            my={2}
          />
          <TextField
            fullWidth
            type="text"
            name="label"
            label="Libellé"
            {...getFieldProps("label")}
            error={Boolean(touched.label && errors.label)}
            helperText={touched.label && errors.label}
            my={2}
          />
          <TextField
            fullWidth
            type="text"
            name="description"
            label="Description"
            {...getFieldProps("description")}
            error={Boolean(touched.description && errors.description)}
            helperText={touched.description && errors.description}
            my={2}
          />
          {Array.isArray(refperiods) && (
            <TextField
              fullWidth
              select
              multiple
              label="Jour(s) de déclenchement"
              {...getFieldProps("periods")}
              error={Boolean(touched.periods && errors.periods)}
              helperText={touched.periods && errors.periods}
              SelectProps={{
                multiple: true,
                value: periodsSelected,
                renderValue: (selected) => selected.join(", "),
                onChange: handleChangePeriods,
              }}
              my={2}
            >
              {refperiods.map((name) => (
                <MenuItem key={name} value={name}>
                  <Checkbox checked={periodsSelected.indexOf(name) > -1} />
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </TextField>
          )}

          <Button
            sx={{ my: 2 }}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            Valider
          </Button>
        </Form>
      </FormikProvider>
    </>
  );
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
