import React from "react";

import {
  Button,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { Add as AddIcon } from "@mui/icons-material";
import RefsForm from "./RefsForm";
import RefthemeForm from "./RefthemeForm";
import RefsubjectForm from "./RefsubjectForm";
import RefActionPointForm from "./RefActionPointForm";
import RefCoachVoiceForm from "./RefCoachVoiceForm";

// ----------------------------------------------------------------------
export default function RefsEditBtn({ editData, refName, addLabel }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const getTitle = () => {
    try {
      if (editData && editData.id) {
        return `Modification`;
      } else {
        return addLabel;
      }
    } catch (error) {
      return "";
    }
  };
  const getForm = () => {
    try {
      if (refName === "reftheme") {
        return (
          <RefthemeForm editData={editData} onComplete={() => handleClose()} />
        );
      } else if (refName === "refsubject") {
        return (
          <RefsubjectForm
            editData={editData}
            onComplete={() => handleClose()}
          />
        );
      } else if (refName === "refactionpoint") {
        return (
          <RefActionPointForm
            editData={editData}
            onComplete={() => handleClose()}
          />
        );
      } else if (refName === "refCoachVoice") {
        return (
          <RefCoachVoiceForm
            editData={editData}
            onComplete={() => handleClose()}
          />
        );
      }
      return (
        <RefsForm
          refName={refName}
          editData={editData}
          onComplete={() => handleClose()}
        />
      );
    } catch (error) {
      console.log(error);
      return <></>;
    }
  };
  const getIcon = () => {
    try {
      if (editData && editData.id) {
        return (
          <IconButton
            aria-label="details"
            size="small"
            onClick={handleClickOpen}
          >
            <EditIcon size="small" />
          </IconButton>
        );
      } else {
        return (
          <Button variant="contained" color="primary" onClick={handleClickOpen}>
            <AddIcon />
            {getTitle()}
          </Button>
        );
      }
    } catch (error) {
      return <></>;
    }
  };
  return (
    <div>
      {getIcon()}
      <Dialog open={open} fullWidth maxWidth="xl">
        <DialogTitle> {getTitle()}</DialogTitle>
        <DialogContent>{getForm()} </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Annuler</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
