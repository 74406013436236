import React, { useState, useEffect } from "react";
import {
  Button,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { Add as AddIcon } from "@mui/icons-material";
import useNotification from "hooks/useNotification";
import useAppmedia from "hooks/useAppmedia";
import UploadMultiFiles from "components/file/UploadMultiFiles";
import CircularProgress from "@mui/material/CircularProgress";

import { generateVideoThumbnail, strB64toBlob } from "utils/media";
import { m } from "framer-motion";

// ----------------------------------------------------------------------
export default function MediaUpload({ addLabel, mediaType, refName }) {
  const { uploadAppMedia, getAppVideos, getAppMusics, getAppImages } =
    useAppmedia();
  const { notif } = useNotification();
  const [loading, setloading] = useState(false);
  const [files, setfiles] = useState(null);
  const [thumbs, setthumbs] = useState([]);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setthumbs(null);
    setfiles(null);
    setOpen(false);
  };

  const uploadFiles = async () => {
    try {
      if (!Array.isArray(files) || files.length === 0) return;
      setloading(true);
      const formData = new FormData();
      files.forEach((file) => {
        formData.append("files", file);
      });
      if (
        mediaType === "video" &&
        Array.isArray(thumbs) &&
        thumbs.length === files.length
      ) {
        thumbs.forEach((file) => {
          const thumb = strB64toBlob(file.src);
          formData.append("thumbs", thumb);
        });
      }
      await uploadAppMedia(formData, refName);
      if (mediaType === "video") {
        await getAppVideos();
      } else if (mediaType === "audio") {
        await getAppMusics();
      } else if (mediaType === "image") {
        await getAppImages();
      }

      setfiles(null);
      setloading(false);
      notif("Téléchargement réussi");
      handleClose();
    } catch (error) {
      setloading(false);
      console.error(error);
      notif(null, error);
    }
  };

  useEffect(() => {
    if (!Array.isArray(files)) {
      setthumbs(null);
      return;
    }
    if (mediaType !== "video") return;
    const initThumbs = async () => {
      setloading(true);
      try {
        const arr = [];
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          const thumbnail = await generateVideoThumbnail(file);
          arr.push({ src: thumbnail, fileName: file.name });
        }
        setthumbs(arr);
        setloading(false);
      } catch (error) {
        setloading(true);
        console.error(error);
      }
    };
    initThumbs();
  }, [files, mediaType]);
  useEffect(() => {
    return () => {
      setthumbs(null);
      setfiles(null);
    };
  }, []);

  const getAcceptFile = () => {
    if (mediaType === "video") {
      return {
        accept: {
          "video/mp4": [".mp4"],
        },
      };
    } else if (mediaType === "music") {
      return {
        accept: {
          "audio/mp3": [".mp3"],
        },
      };
    } else if (mediaType === "image") {
      return {
        accept: {
          "image/jpeg": [".jpeg", ".png"],
        },
      };
    }
    return {};
  };
  const getCaption = () => {
    if (mediaType === "video") {
      return "(Les formats *.mp4 sont acceptés)";
    } else if (mediaType === "music") {
      return "(Les formats *.mp3 sont acceptés)";
    } else if (mediaType === "image") {
      return "(Les formats *.jpeg and *.png sont acceptés)";
    }
    return "";
  };
  const getLabel = () => {
    if (mediaType === "video") {
      return "Vidéos";
    } else if (mediaType === "music") {
      return "Musiques";
    } else if (mediaType === "image") {
      return "Images";
    }
  };
  return (
    <>
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        <AddIcon />
        {addLabel}
      </Button>
      <Dialog open={open} fullWidth maxWidth="xl">
        <DialogTitle> {addLabel}</DialogTitle>
        <DialogContent style={{ minHeight: "500px" }}>
          <UploadMultiFiles
            value={files}
            label={getLabel()}
            onChange={(val) => setfiles(val)}
            caption={getCaption()}
            sx={{ maxHeight: "80px" }}
            acceptFile={getAcceptFile()}
            mediaType={mediaType}
            thumbs={thumbs}
          />
        </DialogContent>
        <DialogActions>
          {loading ? (
            <>
              <Button
                variant="outlined"
                color="primary"
                sx={{ mr: 2 }}
                disabled
              >
                <CircularProgress />
              </Button>
            </>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={uploadFiles}
              sx={{ mr: 2 }}
            >
              Valider
            </Button>
          )}

          <Button onClick={handleClose}>Annuler</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
