import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import styled from "@emotion/styled";
import {
  Alert as MuiAlert,
  Checkbox,
  FormControlLabel,
  Button,
  MenuItem,
  TextField as MuiTextField,
  FormHelperText,
  ListSubheader,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Stack,
  Divider,
  Box,
} from "@mui/material";
import { spacing } from "@mui/system";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import UploadSingleFile from "components/file/UploadSingleFile";
import useNotification from "hooks/useNotification";
import useReferentiel from "hooks/useReferentiel";
import useDataUser from "hooks/useDataUser";
import CodeGen from "components/CodeGen";
import usePublishedProg from "hooks/usePublishedProg";
import { DatePicker } from "@mui/x-date-pickers";
import CircularProgress from "@mui/material/CircularProgress";
import GridImportUser from "./GridImportUser";
// ----------------------------------------------------------------------
const TextField = styled(MuiTextField)(spacing);
export default function UserImportBtn() {
  const [userData, setuserData] = useState(null);
  const [loading, setloading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const { notif } = useNotification();
  const { runImport, submitImport, initUsers } = useDataUser();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleComplete = () => {
    handleClose();
  };
  const onSubmitForm = async (formData) => {
    try {
      setloading(true);
      const res = await submitImport(formData);
      setuserData(res);
      setloading(false);
    } catch (error) {
      setloading(false);
      console.error(error);
      notif(null, error);
    }
  };
  const handleImport = async (rows) => {
    try {
      setloading(true);
      const res = await runImport(rows);
      await initUsers();
      setuserData(null);
      setloading(false);
      handleClose();
    } catch (error) {
      setloading(false);
      console.error(error);
      notif(null, error);
    }
  };
  const onCancel = () => {
    setuserData(null);
    handleClose();
  };
  return (
    <div>
      <Button
        aria-label="details"
        color="warning"
        onClick={handleClickOpen}
        sx={{ mx: 4 }}
      >
        <UploadFileIcon size="small" sx={{ mr: 2 }} />
        Importer
      </Button>
      <Dialog open={open} fullWidth maxWidth="xl">
        <DialogTitle> Importer des utilisateurs depuis le CSV</DialogTitle>
        <DialogContent>
          {userData ? (
            <GridImportUser
              users={userData}
              handleImport={handleImport}
              onCancel={onCancel}
              loading={loading}
            />
          ) : (
            <FormImport
              onComplete={handleComplete}
              onSubmitForm={onSubmitForm}
            />
          )}
        </DialogContent>
        {!userData && (
          <DialogActions>
            <Button onClick={handleClose}>Annuler</Button>
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
}
const FormImport = ({ onSubmitForm, loading }) => {
  const { publishedPrograms, getPublishedPrograms } = usePublishedProg();

  const { refs } = useReferentiel();
  useEffect(() => {
    getPublishedPrograms();
  }, []);
  const today = new Date(Date.now() - 86400000);
  const UserSchema = Yup.object().shape({
    company: Yup.string().required(`Veuillez renseigner la société`),
    file: Yup.mixed().required("Veuillez renseigner le fichier csv"),
    enablecodeat: Yup.date().min(
      today,
      "La date d'activation devrait être supérieure à la date courante"
    ),
  });

  const formik = useFormik({
    enableReinitialize: false,
    initialValues: {
      company: "",
      publishedprogram: "",
    },
    validationSchema: UserSchema,
    onSubmit: async () => {
      try {
        const formData = new FormData();
        formData.append("files", values.file);
        formData.append("company", values.company);
        formData.append("publishedprogram", values.publishedprogram);
        formData.append("enablecodeat", values.enablecodeat);
        onSubmitForm(formData);
      } catch (error) {
        console.error(error);
      }
    },
  });

  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setSubmitting,
    handleChange,
    setFieldValue,
  } = formik;
  useEffect(() => {
    if (!setFieldValue) return;
    if (values.publishedprogram) {
      setFieldValue("enablecodeat", new Date());
    }
  }, [setFieldValue, values.publishedprogram]);
  const companies = Array.isArray(refs.companies) ? refs.companies : [];
  if (!companies) return <></>;
  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack
            spacing={2}
            direction="row"
            alignItems="center"
            sx={{ my: 4 }}
            style={{ minHeigh: "450px" }}
          >
            <TextField
              select
              label="Société"
              value={values.company}
              onChange={handleChange("company")}
              {...getFieldProps("company")}
              error={Boolean(touched.company && errors.company)}
              helperText={touched.company && errors.company}
              py={2}
              sx={{ width: 250 }}
            >
              {companies.map((option, i) => (
                <MenuItem key={i} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
            {Array.isArray(publishedPrograms) && (
              <TextField
                select
                label="Génération du code pour le programme"
                value={values.publishedprogram}
                onChange={handleChange("publishedprogram")}
                {...getFieldProps("publishedprogram")}
                error={Boolean(
                  touched.publishedprogram && errors.publishedprogram
                )}
                helperText={touched.publishedprogram && errors.publishedprogram}
                py={2}
                sx={{ width: 450 }}
              >
                {publishedPrograms.map((option, i) => (
                  <MenuItem key={i} value={option.id}>
                    {`${option.title}`}
                  </MenuItem>
                ))}
              </TextField>
            )}
            {values && values.publishedprogram && (
              <DatePicker
                onChange={(value) => {
                  setFieldValue("enablecodeat", value, true);
                }}
                value={values.enablecodeat}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    label="Date d'activation du code"
                    {...getFieldProps("enablecodeat")}
                    error={Boolean(touched.enablecodeat && errors.enablecodeat)}
                    helperText={touched.enablecodeat && errors.enablecodeat}
                    my={2}
                    sx={{ width: 250 }}
                    {...params}
                  />
                )}
              />
            )}
            <UploadSingleFile
              value={values.file}
              label="Fichier CSV"
              onChange={(val) => setFieldValue("file", val)}
              caption="(Les formats *.csv est acceptés)"
              error={Boolean(touched.file && errors.image)}
              sx={{ maxHeight: "50px" }}
              acceptFile={[
                ".csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values",
              ]}
              mediaType="txt"
            />
          </Stack>

          <Box display="flex" alignItems="flex-end" sx={{ height: "200px" }}>
            {loading ? (
              <CircularProgress size={20} />
            ) : (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={isSubmitting}
              >
                Valider
              </Button>
            )}
          </Box>
        </Form>
      </FormikProvider>
    </>
  );
};
