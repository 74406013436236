import React, { useState } from "react";
import PowerIcon from "@mui/icons-material/Power";
import PowerOffIcon from "@mui/icons-material/PowerOff";
import useProgram from "hooks/useProgram";
import { Button } from "@mui/material";
import useNotification from "hooks/useNotification";
import CircularProgress from "@mui/material/CircularProgress";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
// ----------------------------------------------------------------------
export default function CoachProgUpdBtn({ editProgram, data }) {
  const [submitting, setsubmitting] = useState(false);
  const { initProgramById, saveCoachProg, updateCoachDefaultProg } =
    useProgram();
  const { notif } = useNotification();
  const updateCoachProg = async (enable) => {
    try {
      setsubmitting(true);
      await saveCoachProg(editProgram, data.voice, enable);
      await initProgramById(editProgram.id);
      notif("Sauvegarde réussie");
      setsubmitting(false);
    } catch (error) {
      console.error(error);
      notif(null, error);
      setsubmitting(false);
    }
  };
  const handleupdateCoachDefaultProg = async () => {
    try {
      setsubmitting(true);
      await updateCoachDefaultProg(editProgram.id, data._id);
      await initProgramById(editProgram.id);
      notif("Sauvegarde réussie");
      setsubmitting(false);
    } catch (error) {
      console.error(error);
      notif(null, error);
      setsubmitting(false);
    }
  };
  return (
    <>
      {submitting ? (
        <CircularProgress />
      ) : (
        <>
          {data.enable ? (
            <>
              <Button
                variant="outlined"
                color="error"
                onClick={() => updateCoachProg(false)}
              >
                <PowerOffIcon size="small" />
                Désactiver
              </Button>
              <Button
                variant="outlined"
                color="info"
                onClick={() => handleupdateCoachDefaultProg()}
              >
                <SubscriptionsIcon size="small" />
                Voie défaut
              </Button>
            </>
          ) : (
            <Button
              variant="contained"
              color="success"
              onClick={() => updateCoachProg(true)}
            >
              <PowerIcon size="small" />
              Activer
            </Button>
          )}
        </>
      )}
    </>
  );
}
