import React, { useEffect, useState } from "react";

import {
  Avatar,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Card as MuiCard,
  CardActions,
  CardContent as MuiCardContent,
  CardMedia as MuiCardMedia,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid,
  Link,
  Typography as MuiTypography,
  AvatarGroup as MuiAvatarGroup,
  Box,
  CardActionArea,
} from "@mui/material";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";

import { getMediaUrl } from "utils/objectUtil";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import axios from "axios";
import MediaEditBtn from "./MediaEditBtn";
import MediaDelBtn from "./MediaDelBtn";
// ----------------------------------------------------------------------
const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)`
  border-bottom: 1px solid ${(props) => props.theme.palette.grey[300]};
`;

const CardMedia = styled(MuiCardMedia)`
  height: 220px;
`;
const Typography = styled(MuiTypography)(spacing);

export default function MusicList({ items, musicField, refName }) {
  if (!items) return <></>;
  return (
    <Grid container spacing={6} columns={{ xs: 4, sm: 6, md: 12 }}>
      {items.map((item, i) => (
        <Grid item key={i}>
          <MusicFromUrl
            item={item}
            image={getMediaUrl(item, musicField, refName)}
            refName={refName}
          />
        </Grid>
      ))}
    </Grid>
  );
}
const MusicFromUrl = ({ item, image, refName }) => {
  return (
    <Card>
      <CardActionArea>
        <CardMedia
          component="img"
          image="/static/img/illustrations/audio_upload.png"
          alt={item.name}
          sx={{ padding: "20px" }}
        />
        <CardContent>
          <Typography variant="span" sx={{ color: "text.secondary" }}>
            {item.name}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions sx={{ display: "flex", justifyContent: "flex-end" }}>
        <MediaEditBtn editData={item} refName={refName} />
        <MediaDelBtn editData={item} refName={refName} />
      </CardActions>
    </Card>
  );
};
