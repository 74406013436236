import React, { useState, useEffect } from "react";
import {
  Grid,
  Tabs as MuiTabs,
  Typography,
  Card,
  CardHeader,
  CardContent,
  Button,
} from "@mui/material";
import useProgram from "hooks/useProgram";
import SelectStereoProgBtn from "./SelectStereoProgBtn";
// ----------------------------------------------------------------------
export default function TabStereoConfig({ editProgram }) {
  const [stereos, setstereos] = useState(null);
  const { getAllStereo } = useProgram();
  useEffect(() => {
    const init = async () => {
      const res = await getAllStereo();
      if (res) {
        setstereos(res);
      }
    };
    init();
  }, []);

  if (!editProgram) return <></>;
  if (!editProgram.stereochallenge) return <></>;
  if (!stereos) return <></>;
  const { stereochallenge } = editProgram;
  return (
    <Grid container spacing={6}>
      <>
        {Array.isArray(stereos) && (
          <>
            {stereos.map((it, i) => {
              const selected =
                String(it.id) === String(editProgram.stereochallenge.id);
              return (
                <Grid item xs={12} lg={6} xl={3} key={i}>
                  <Detail
                    data={it}
                    editProgram={editProgram}
                    selected={selected}
                  />
                </Grid>
              );
            })}
          </>
        )}
      </>
    </Grid>
  );
}
const Detail = ({ editProgram, data, selected }) => {
  const { title } = data;
  if (!data) return <></>;
  return (
    <Card>
      <CardHeader title={title} sx={{ textAlign: "center" }} />
      {/* audio player */}
      <CardContent
        sx={{
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {selected ? (
          <Button variant="contained" color="success">
            Stéréotype choisi
          </Button>
        ) : (
          <SelectStereoProgBtn data={data} editProgram={editProgram} />
        )}
      </CardContent>
    </Card>
  );
};
