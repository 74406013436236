import React from "react";
import styled from "@emotion/styled";
import {
  Grid,
  Tabs as MuiTabs,
  Typography,
  Card,
  CardHeader,
  CardContent,
  Button,
} from "@mui/material";
import CoachProgUpdBtn from "./CoachProgUpdBtn";
// ----------------------------------------------------------------------
export default function TabCoachConfig({ editProgram }) {
  if (!editProgram) return <></>;
  if (!editProgram.coachVoices) return <></>;

  return (
    <Grid container spacing={6}>
      <>
        {Array.isArray(editProgram.coachVoices) && (
          <>
            {editProgram.coachVoices.map((it, i) => {
              return (
                <Grid item xs={12} lg={6} xl={3} key={i}>
                  <Detail data={it} editProgram={editProgram} />
                </Grid>
              );
            })}
          </>
        )}
      </>
    </Grid>
  );
}
const Detail = ({ editProgram, data }) => {
  const { defaultEnabled, enable, voice } = data;
  if (!data) return <></>;
  if (!voice) return <></>;
  return (
    <Card>
      <CardHeader title={voice.label} sx={{ textAlign: "center" }} />
      {/* audio player */}
      <CardContent
        sx={{
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {defaultEnabled ? (
          <Button variant="contained" color="primary" disabled>
            Voix défaut
          </Button>
        ) : (
          <CoachProgUpdBtn data={data} editProgram={editProgram} />
        )}
      </CardContent>
    </Card>
  );
};
