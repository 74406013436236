import React, { useEffect } from "react";
import useAuth from "hooks/useAuth";
import useReferentiel from "hooks/useReferentiel";
// ----------------------------------------------------------------------
const AppContext = React.createContext();
export default function AppProvider({ ...props }) {
  const { user, initAuth } = useAuth();
  const { initAppconfigs, initRefs } = useReferentiel();
  useEffect(() => {
    initAuth();
  }, []);
  useEffect(() => {
    if (!user || !user._id) return;
    initAppconfigs();
    initRefs();
  }, [user]);

  return <AppContext.Provider value={{}}>{props.children}</AppContext.Provider>;
}
