import * as React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from "@mui/lab/TimelineOppositeContent";
import { addDaysToDate, durationBetween2Dates, fDate } from "utils/formatTime";
// ----------------------------------------------------------------------
export default function UserProgramTimeLine({ editData }) {
  const { userprogram } = editData;
  if (!userprogram) return <></>;
  const { responses, dayflows, themes } = userprogram;
  let additionalDay = 0;
  return (
    <>
      {Array.isArray(dayflows) && (
        <Timeline
          sx={{
            [`& .${timelineOppositeContentClasses.root}`]: {
              flex: 0.2,
            },
          }}
        >
          {dayflows.map((dayflow, i) => {
            const { isFinished, daystep, dayrun } = dayflow;

            let userDayflow = null;
            try {
              userDayflow = responses.dayflows.find((uDayflow) => {
                if (i === 0 && additionalDay === 0) {
                  const diff = durationBetween2Dates(dayrun, uDayflow.dayrun);
                  if (diff && diff.days) {
                    additionalDay = diff.days;
                  }
                }
                return String(uDayflow.daystep) === String(daystep);
              });
              if (userDayflow) {
                const userTheme = themes.find((t) => {
                  return (
                    String(t.reftheme.id) ===
                    String(userDayflow.subject.refsubject.reftheme)
                  );
                });

                if (userTheme) {
                  userDayflow.theme = userTheme;
                }
              }
            } catch (error) {
              console.error(error);
            }
            return (
              <TimelineItem key={i}>
                <TimelineOppositeContent color="textSecondary">
                  {fDate(addDaysToDate(dayflow.dayrun, additionalDay))}
                </TimelineOppositeContent>
                <TimelineSeparator>
                  {userDayflow ? (
                    <TimelineDot color="success" />
                  ) : (
                    <TimelineDot variant="outlined" />
                  )}

                  {i < dayflows.length - 1 && <TimelineConnector />}
                </TimelineSeparator>
                {userDayflow ? (
                  <TimelineContent>{`${userDayflow.theme.reftheme.code}-${userDayflow.theme.reftheme.label} | ${userDayflow.subject.refsubject.code}-${userDayflow.subject.refsubject.label} | ${userDayflow.challenge.code}-${userDayflow.challenge.title} (${userDayflow.point} points)`}</TimelineContent>
                ) : (
                  <TimelineContent>pas encore effectuer</TimelineContent>
                )}
              </TimelineItem>
            );
          })}
        </Timeline>
      )}
    </>
  );
}
