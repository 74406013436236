import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { NavLink } from "react-router-dom";
import styled from "@emotion/styled";
import {
  Avatar,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Card as MuiCard,
  CardActions,
  CardContent as MuiCardContent,
  CardMedia as MuiCardMedia,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid,
  Link,
  Typography as MuiTypography,
  AvatarGroup as MuiAvatarGroup,
} from "@mui/material";
import { spacing } from "@mui/system";
import useReferentiel from "hooks/useReferentiel";
import { getMediaUrl } from "utils/objectUtil";
import { API_URL } from "config";
import RefsCardItem from "./RefsCardItem";

// ----------------------------------------------------------------------

const Chip = styled(MuiChip)`
  height: 20px;
  padding: 4px 0;
  font-size: 85%;
  background-color: ${(props) =>
    props.theme.palette[props.color ? props.color : "primary"].light};
  color: ${(props) => props.theme.palette.common.white};
  margin-bottom: ${(props) => props.theme.spacing(4)};
`;
export default function RefsList({ items, refName, ...props }) {
  const { refs } = useReferentiel();

  if (!items) return;
  const getDescription = (it) => {
    try {
      if (refName === "refsubject") {
        return it.reftheme.label;
      }
      return it.description;
    } catch (error) {
      return null;
    }
  };
  const getTitle = (it) => {
    try {
      if (refName === "refsubject") {
        return `${it.code}-${it.label}`;
      }
      return it.label;
    } catch (error) {
      return null;
    }
  };
  const getChip = (it) => {
    try {
      if (refName === "refsubject" && it.refstereotype) {
        const color =
          it.refstereotype.code === "strong"
            ? "error"
            : it.refstereotype.code === "medium"
            ? "warning"
            : "primary";
        return <Chip label={`${it.refstereotype.label}`} color={color} />;
      }
      if (it.enable) {
        return <Chip label="Activé" color="success" />;
      }
      return <></>;
    } catch (error) {
      return <></>;
    }
  };
  return (
    <>
      <Grid container spacing={6}>
        {items.map((it, i) => {
          return (
            <Grid item xs={12} lg={6} xl={3} key={i}>
              <RefsCardItem
                image={getMediaUrl(it, "image", refName)}
                refName={refName}
                row={it}
                title={getTitle(it)}
                description={getDescription(it)}
                chip={getChip(it)}
              />
            </Grid>
          );
        })}
      </Grid>
    </>
  );
}
