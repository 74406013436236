import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

import CardActionArea from "@mui/material/CardActionArea";

// ----------------------------------------------------------------------
export default function ThumbnailVideo({ thumb }) {
  return (
    <>
      <Card sx={{ maxWidth: 345 }}>
        <CardActionArea>
          {thumb && thumb.src ? (
            <CardMedia
              component="img"
              height="140"
              image={thumb.src}
              alt="green iguana"
            />
          ) : (
            <Box
              sx={{
                height: 140,
                width: "100%",
              }}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress color="success" />
            </Box>
          )}

          <CardContent>
            {thumb ? (
              <Typography variant="body2" color="text.secondary">
                {thumb.fileName}
              </Typography>
            ) : (
              <>Loading...</>
            )}
          </CardContent>
        </CardActionArea>
      </Card>
    </>
  );
}
