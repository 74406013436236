import React from "react";
import styled from "@emotion/styled";
import { Grid, Box, Tabs as MuiTabs, Tab, Typography } from "@mui/material";
import Chip from "@mui/material/Chip";
import SelfImprovementIcon from "@mui/icons-material/SelfImprovement";
import MoveUpIcon from "@mui/icons-material/MoveUp";
import ShareIcon from "@mui/icons-material/Share";
import HighlightIcon from "@mui/icons-material/Highlight";
import LandslideIcon from "@mui/icons-material/Landslide";
import SportsGymnasticsIcon from "@mui/icons-material/SportsGymnastics";
import BoosterConfig from "./BoosterConfig";
// ----------------------------------------------------------------------
const Tabs = styled(MuiTabs)`
  .MuiTabs-flexContainer {
    align-items: flex-start;
  }
`;

export default function TabBoostersConfig({ editProgram }) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getIcon = (booster) => {
    try {
      if (!booster) return <></>;
      if (Number(booster.code) === 3) {
        //booster exercices
        return <SportsGymnasticsIcon />;
      }

      return <></>;
    } catch (error) {
      return <></>;
    }
  };
  const getBoosTypes = () => {
    try {
      if (!editProgram.boosters) return [];
      const arr = [];

      editProgram.boosters.forEach((it) => {
        const existItem = arr.find(
          (item) => String(item.code) === String(it.booster.refboostertype.code)
        );
        if (!existItem) {
          arr.push({ ...it.booster.refboostertype });
        }
      });
      return arr;
    } catch (error) {
      return [];
    }
  };
  const boostTypes = getBoosTypes();
  const getContentTab = () => {
    try {
      if (!editProgram.boosters) return <></>;
      if (!boostTypes) return <></>;
      const refType = boostTypes.find((it, i) => Number(i) === Number(value));
      if (!refType) return <></>;
      const boost = editProgram.boosters.filter(
        (it, i) =>
          String(it.booster.refboostertype.code) === String(refType.code)
      );
      if (!boost) return <></>;
      return <BoosterConfig editProgram={editProgram} boosters={boost} />;
    } catch (error) {
      console.error(error);
      return <></>;
    }
  };
  if (!editProgram) return <></>;
  if (!editProgram.boosters) return <></>;

  return (
    <Box
      sx={{
        flexGrow: 1,
        bgcolor: "background.paper",
        display: "flex",
      }}
    >
      {boostTypes && (
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          sx={{ borderRight: 1, borderColor: "divider" }}
        >
          {boostTypes.map((it, i) => {
            return (
              <Tab
                key={i}
                icon={getIcon(it)}
                iconPosition="start"
                label={it.label}
                deleteIcon={getIcon(it)}
              />
            );
          })}
        </Tabs>
      )}
      <Box sx={{ width: "100%", height: "100%" }}>{getContentTab()}</Box>
    </Box>
  );
}
