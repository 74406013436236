import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import { useCallback, useEffect, useState } from "react";
// material
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import ReactPlayer from "react-player";
import ThumbnailVideo from "./ThumbnailVideo";
// ----------------------------------------------------------------------

const DropZoneStyle = styled("div")(({ theme }) => ({
  outline: "none",
  display: "flex",
  overflow: "hidden",
  textAlign: "center",
  position: "relative",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(1, 0),
  borderRadius: theme.shape.borderRadius,
  transition: theme.transitions.create("padding"),
  backgroundColor: theme.palette.background.neutral,
  border: `1px dashed ${theme.palette.grey[500_32]}`,
  "&:hover": {
    opacity: 0.72,
    cursor: "pointer",
  },
  [theme.breakpoints.up("md")]: {
    textAlign: "left",
    flexDirection: "row",
  },
}));
const PlayerStyle = styled(ReactPlayer)(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  overflow: "hidden",
  "& video": {
    width: "auto!important",
    height: "120px!important",
    position: "absolute",
  },
}));
// ----------------------------------------------------------------------

export default function UploadMultiFiles({
  caption,
  error = false,
  value: files,
  label,
  onChange: setFiles,
  sx,
  acceptFile,
  mediaType,
  thumbs,
  ...other
}) {
  const handleDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles) {
        setFiles(acceptedFiles);
      }
    },
    [setFiles]
  );
  const { getRootProps, getInputProps, isDragActive, isDragReject } =
    useDropzone({
      ...acceptFile,
      onDrop: handleDrop,
      multiple: true,
    });
  const [preview, setPreview] = useState(null);

  const handeRemove = (e) => {
    setFiles(null);
    e.preventDefault();
  };
  const handeRemoveOne = (e, index) => {
    const arr = files.filter((it, i) => i !== index);
    setFiles(arr);
    e.preventDefault();
  };
  const getMediaTypeIcon = () => {
    try {
      if (mediaType === "audio") {
        return "/static/img/illustrations/audio_upload.png";
      } else if (mediaType === "video") {
        return "/static/img/illustrations/video_upload.png";
      }
      return "/static/img/illustrations/image_upload.png";
    } catch (error) {
      return "/static/img/illustrations/image_upload.png";
    }
  };
  useEffect(() => {
    if (!files) {
      setPreview(null);
      return;
    }
    if (mediaType !== "image") return;

    const arr = [];
    files.forEach((file) => {
      const preview = URL.createObjectURL(file);
      arr.push(preview);
    });
    setPreview(arr);
  }, [files, mediaType]);
  const getPreview = () => {
    try {
      if (!files) return <></>;
      if (mediaType === "audio") {
        return (
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            {files.map((file, i) => {
              const name =
                file && file.name && file.name.length < 50
                  ? file.name
                  : file.name.substring(0, 50) + "...";
              return (
                <Grid item xs={2} sm={4} md={4} key={i}>
                  <Box>
                    <Typography variant="span" sx={{ color: "text.secondary" }}>
                      {name}
                    </Typography>
                  </Box>
                  <Box sx={{ paddingTop: "5px" }}>
                    <IconButton
                      size="small"
                      color="error"
                      onClick={(e) => handeRemoveOne(it)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        );
      } else if (mediaType === "video") {
        if (thumbs) {
          return (
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              {thumbs.map((it, i) => {
                return (
                  <Grid item xs={2} sm={4} md={4} key={i}>
                    <ThumbnailVideo thumb={it} />

                    <Box sx={{ paddingTop: "5px" }}>
                      <IconButton
                        size="small"
                        color="error"
                        onClick={(e) => handeRemoveOne(e, i)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          );
        }
      }
      if (files) {
        return (
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            {preview.map((it, i) => {
              return (
                <Grid item xs={2} sm={4} md={4} key={i}>
                  <ThumbnailVideo thumb={{ src: it, fileName: "File_" + i }} />
                  <Box sx={{ paddingTop: "5px" }}>
                    <IconButton
                      size="small"
                      color="error"
                      onClick={(e) => handeRemoveOne(e, i)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        );
      }
    } catch (error) {
      return <></>;
    }
  };
  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{ width: "100%", ...sx }}
      {...other}
    >
      <DropZoneStyle
        {...getRootProps()}
        sx={{
          ...(isDragActive && { opacity: 0.72 }),
          ...((isDragReject || error) && {
            color: "error.main",
            borderColor: "error.light",
            bgcolor: "error.lighter",
          }),
          ...(files && { padding: "6% 0" }),
        }}
      >
        <input {...getInputProps()} />

        <Box
          component="img"
          alt="select file"
          src={getMediaTypeIcon()}
          sx={{ height: 50 }}
        />
        <Box sx={{ p: 3, ml: { md: 2 } }}>
          <Typography gutterBottom variant="h5">
            {label ? label + ": " : ""} déposez ou sélectionnez les fichiers
          </Typography>

          {caption ? (
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              {caption}
            </Typography>
          ) : (
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              {label ? label + ": " : ""} déposez ici ou sélectionnez les
              fichiers &nbsp;
              <Typography
                variant="body2"
                component="span"
                sx={{ color: "primary.main" }}
              >
                explorer
              </Typography>
              &nbsp;sur votre machine
            </Typography>
          )}
        </Box>
      </DropZoneStyle>
      {files && files.length > 0 && (
        <Button
          variant="outlined"
          color="error"
          sx={{ my: 3 }}
          onClick={(e) => handeRemove(e)}
        >
          Supprimer tous
        </Button>
      )}
      <Box
        style={{
          width: "100%",
          position: "relative",
        }}
      >
        {files && <>{getPreview()}</>}
      </Box>
    </Box>
  );
}
