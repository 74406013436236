import React from "react";
import { Grid } from "@mui/material";
import UserDetail from "./UserDetail";
import UserStereotype from "./UserStereotype";
import UserAppcodes from "./UserAppcodes";

// ----------------------------------------------------------------------
export default function UserProfile({ userData }) {
  return (
    <React.Fragment>
      <Grid container spacing={6}>
        <Grid item xs={12} lg={4} xl={3}>
          <UserDetail userData={userData} />
          <UserStereotype userData={userData} />
        </Grid>
        <Grid item xs={12} lg={8} xl={9}>
          <UserAppcodes userData={userData} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
