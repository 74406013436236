import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import authReducer from "./slices/authSlice";
import refReducer from "./slices/refSlice";
import userReducer from "./slices/userSlice";
import appcodeReducer from "./slices/appcodeSlice";
import programReducer from "./slices/programSlice";
import appmediaReducer from "./slices/appmediaSlice";
import giftReducer from "./slices/giftSlice";

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
  whitelist: ["settings"],
};

const rootReducer = combineReducers({
  auth: authReducer,
  ref: refReducer,
  users: userReducer,
  appcodes: appcodeReducer,
  programs: programReducer,
  appmedia: appmediaReducer,
  gift: giftReducer,
});

export { rootPersistConfig, rootReducer };
