import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";

// ----------------------------------------------------------------------

export default function SearchAppcodeByKw({ handleSearch, handleReset }) {
  const [keywords, setkeywords] = useState("");
  useEffect(() => {
    if (!keywords) return;
    handleSearch(keywords);
  }, [keywords]);
  const onReset = () => {
    if (!keywords) return;
    setkeywords("");
    handleReset();
  };
  return (
    <Paper
      component="form"
      sx={{ p: "2px 4px", display: "flex", alignItems: "center", width: 500 }}
    >
      <IconButton sx={{ p: "10px" }} aria-label="menu">
        <SearchIcon />
      </IconButton>
      <InputBase
        value={keywords ? keywords : ""}
        onChange={(e) => setkeywords(e.target.value)}
        sx={{ ml: 1, flex: 1 }}
        placeholder="Recherche par mots clés (pseudo, code, nom et prénom)"
        inputProps={{ "aria-label": "search google maps" }}
      />
      <IconButton
        type="button"
        sx={{ p: "10px" }}
        aria-label="search"
        onClick={onReset}
        disabled={keywords && keywords.length > 0 ? false : true}
      >
        <CloseIcon />
      </IconButton>
    </Paper>
  );
}
