import { useDispatch, useSelector } from "react-redux";
import { maxsubjectsbyday, maxchallengesbysubject } from "config";
import axios from "axios";
import { API_URL } from "config";
import {
  startLoading,
  stopLoading,
  initPrograms,
  initAppProgram,
  initSubjsNumberByDay,
  initChallengesNumberBySubj,
  initBoosters,
} from "redux/slices/programSlice";

// ----------------------------------------------------------------------

export default function useProgram() {
  const dispatch = useDispatch();
  const {
    isLoading,
    isLoaded,
    programs,
    boosters,
    stereotypechallenges,
    challenges,
    appProgram,
    maxSubjsByDay,
    challengesNumberBySubj,
  } = useSelector((state) => state.programs);
  const { appconfigs } = useSelector((state) => state.ref);
  return {
    isLoaded,
    isLoading,
    programs,
    boosters,
    stereotypechallenges,
    challenges,
    appProgram,
    maxSubjsByDay,
    challengesNumberBySubj,
    initConfigProgram: () => {
      try {
        if (!appconfigs) return false;
        const subjsNumberByDay = appconfigs.find(
          (it) => it.code === maxsubjectsbyday
        );
        if (subjsNumberByDay) {
          dispatch(initSubjsNumberByDay(Number(subjsNumberByDay.value)));
        }

        const challengesNumberBySubj = appconfigs.find(
          (it) => it.code === maxchallengesbysubject
        );
        if (challengesNumberBySubj) {
          dispatch(
            initChallengesNumberBySubj(Number(challengesNumberBySubj.value))
          );
        }
      } catch (error) {
        dispatch(stopLoading());
        console.error(error);
      }
    },

    getBoosters: async () => {
      try {
        dispatch(startLoading());
        const res = await axios.get(API_URL.booster);
        if (res && res.data) {
          dispatch(initBoosters(res.data));
        } else {
          dispatch(initBoosters(null));
          throw new Error("Boosters ne sont pas disponibles");
        }
      } catch (error) {
        dispatch(stopLoading());
        console.error(error);
        throw new Error("Challenges ne sont pas disponibles");
      }
    },
    getPrograms: async () => {
      try {
        dispatch(startLoading());
        const res = await axios.get(API_URL.program);
        if (res && res.data) {
          dispatch(initPrograms(res.data));
        } else {
          dispatch(initPrograms(null));
          throw new Error("Challenges ne sont pas disponibles");
        }
      } catch (error) {
        dispatch(stopLoading());
        console.error(error);
        throw new Error("Challenges ne sont pas disponibles");
      }
    },
    initProgramById: async (id) => {
      try {
        dispatch(startLoading());
        const res = await axios.get(`${API_URL.program}/${id}`);
        if (res && res.data) {
          dispatch(initAppProgram(res.data));
        } else {
          throw new Error("Challenges ne sont pas disponibles");
        }
      } catch (error) {
        dispatch(stopLoading());
        console.error(error);
        throw new Error("Challenges ne sont pas disponibles");
      }
    },
    delProgram: async (o) => {
      try {
        if (!o) return;
        dispatch(startLoading());
        await axios.delete(`${API_URL.program}/${o.id}`);
        dispatch(stopLoading());
      } catch (error) {
        dispatch(stopLoading());
        console.error(error);
        throw error;
      }
    },
    cloneProgram: async (o) => {
      try {
        if (!o) return;
        dispatch(startLoading());
        await axios.get(`${API_URL.program}/clone/${o.id}`);
        dispatch(stopLoading());
      } catch (error) {
        dispatch(stopLoading());
        console.error(error);
        throw error;
      }
    },
    publishProgram: async (o) => {
      try {
        if (!o) return;
        dispatch(startLoading());
        await axios.get(`${API_URL.program}/publish/${o.id}`);
        dispatch(stopLoading());
      } catch (error) {
        dispatch(stopLoading());
        console.error(error);
        throw error;
      }
    },
    saveBoosterProg: async (program, boosterData, enable) => {
      try {
        if (!program) return;
        if (!boosterData) return;
        dispatch(startLoading());
        await axios.post(`${API_URL.program}/booster/${program.id}`, {
          booster: boosterData.id,
          enable,
        });
        dispatch(stopLoading());
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    saveCoachProg: async (program, coachData, enable) => {
      try {
        if (!program) return;
        if (!coachData) return;
        dispatch(startLoading());
        await axios.post(`${API_URL.program}/coach-voice/${program.id}`, {
          voice: coachData.id,
          enable,
        });
        dispatch(stopLoading());
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    saveProgram: async (o) => {
      try {
        dispatch(startLoading());

        delete o.modifiedby;
        delete o.createdby;
        if (o && o.id) {
          delete o.numberoffdays;
          await axios.patch(API_URL.program + "/" + o.id, o);
        } else {
          const res = await axios.post(API_URL.program, o);
          return res.data;
        }
      } catch (error) {
        dispatch(stopLoading());
        console.error(error);
        throw error;
      }
    },
    saveBoosterFormData: async (o, formdata) => {
      try {
        dispatch(startLoading());
        delete o.modifiedby;
        delete o.createdby;
        if (o && o.id) {
          await axios.patch(API_URL.booster + "/formdata/" + o.id, formdata);
        } else {
          await axios.post(API_URL.booster + "/formdata", formdata);
        }
      } catch (error) {
        dispatch(stopLoading());
        console.error(error);
        throw error;
      }
    },
    saveBooster: async (o) => {
      try {
        dispatch(startLoading());

        delete o.modifiedby;
        delete o.createdby;
        if (o && o.id) {
          await axios.patch(API_URL.booster + "/" + o.id, o);
        } else {
          const res = await axios.post(API_URL.booster, o);
          return res.data;
        }
      } catch (error) {
        dispatch(stopLoading());
        console.error(error);
        throw error;
      }
    },
    saveThemeConfig: async (id, themeconfig) => {
      try {
        dispatch(startLoading());

        await axios.post(API_URL.program + "/themeconfig/" + id, themeconfig);
      } catch (error) {
        dispatch(stopLoading());
        console.error(error);
        throw error;
      }
    },
    saveProgarmFormdata: async (o, formdata) => {
      try {
        dispatch(startLoading());
        delete o.modifiedby;
        delete o.createdby;
        if (o && o.id) {
          await axios.patch(API_URL.program + "/formdata/" + o.id, formdata);
        } else {
          const res = await axios.post(API_URL.program + "/formdata", formdata);
          return res.data;
        }
      } catch (error) {
        dispatch(stopLoading());
        console.error(error);
        throw error;
      }
    },
    getAllStereo: async () => {
      try {
        const res = await axios.get(API_URL.stereotypechallenge);
        if (res && res.data) {
          return res.data;
        }
        return null;
      } catch (error) {
        console.error(error);
      }
    },
    selectStereoForProg: async (idProg, idStereo) => {
      try {
        await axios.get(
          `${API_URL.program}/selectStereo/${idProg}/${idStereo}`
        );
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    updateCoachDefaultProg: async (idProg, idVoice) => {
      try {
        await axios.get(
          `${API_URL.program}/coach-voice-default/${idProg}/${idVoice}`
        );
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
  };
}
