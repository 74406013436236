import * as React from "react";
import styled from "@emotion/styled";
import {
  Avatar as MuiAvatar,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
  Chip as MuiChip,
  IconButton,
  Popover,
  CardActions,
  CardContent,
  Button,
} from "@mui/material";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";
import ChoiceEditBtn from "./ChoiceEditBtn";

// ----------------------------------------------------------------------
const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Avatar = styled(MuiAvatar)(spacing);
const Chip = styled(MuiChip)(spacing);
export default function ChoicesTable({
  choices,
  handleCompleteChoice,
  handleDelChoice,
}) {
  if (!Array.isArray(choices)) return <></>;

  const getAvatarStereo = (it) => {
    try {
      const str = it.refstereotype.code.substring(0, 2);
      return str;
    } catch (error) {
      return "";
    }
  };

  return (
    <>
      {choices.map((it, i) => {
        return (
          <React.Fragment key={i}>
            <Card mb={1} sx={{ p: 0 }}>
              <ChoiceEditBtn
                editData={it}
                handleCompleteChoice={handleCompleteChoice}
              />
              <DeleteChoiceBtn editData={it} handleDelete={handleDelChoice} />
              <Typography variant="p" gutterBottom sx={{ mx: 6 }}>
                {it.title}
              </Typography>
              {it.pointgained.map((p, i) => {
                return (
                  <Chip
                    variant="outlined"
                    key={i}
                    avatar={<Avatar>{getAvatarStereo(p)}</Avatar>}
                    label={`${p.refstereotype.label}: ${p.point}`}
                    m={1}
                    color={
                      p.refstereotype.code === "strong"
                        ? "error"
                        : p.refstereotype.code === "medium"
                        ? "warning"
                        : "primary"
                    }
                  />
                );
              })}
            </Card>
            <Divider my={2} />
          </React.Fragment>
        );
      })}
    </>
  );
}

const DeleteChoiceBtn = ({ editData, handleDelete }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleConfirm = () => {
    handleDelete(editData);
    setAnchorEl(null);
  };
  if (!editData) return;

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <>
      <IconButton
        aria-label="details"
        size="small"
        color="error"
        variant="contained"
        onClick={handleClick}
      >
        <DeleteIcon size="small" />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Card  sx={{
            minWidth: 275,
            backgroundColor: "#1A2027",
          }}>
          <CardContent>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              Etes vous sur de supprimer ?
            </Typography>
          </CardContent>
          <CardActions sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button onClick={handleConfirm}>Valider</Button>
            <Button onClick={handleClose}>Annuler</Button>
          </CardActions>
        </Card>
      </Popover>
    </>
  );
};
