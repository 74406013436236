import React, { useEffect, useState } from "react";

import {
  Avatar,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Card as MuiCard,
  CardActions,
  CardContent as MuiCardContent,
  CardMedia as MuiCardMedia,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid,
  Link,
  Typography as MuiTypography,
  AvatarGroup as MuiAvatarGroup,
} from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { getMediaUrl } from "utils/objectUtil";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import axios from "axios";
import MediaEditBtn from "./MediaEditBtn";
import MediaDelBtn from "./MediaDelBtn";
// ----------------------------------------------------------------------
const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)`
  border-bottom: 1px solid ${(props) => props.theme.palette.grey[300]};
`;

const CardMedia = styled(MuiCardMedia)`
  height: 220px;
`;
const Typography = styled(MuiTypography)(spacing);

export default function ImageList({ items, imgField, refName }) {
  if (!items) return <></>;
  return (
    <Grid container spacing={6} columns={{ xs: 4, sm: 8, md: 12 }}>
      {items.map((item, i) => (
        <Grid item xs={2} sm={4} md={4} key={i}>
          <ImgFromUrl
            item={item}
            image={getMediaUrl(item, imgField, refName)}
            refName={refName}
          />
        </Grid>
      ))}
    </Grid>
  );
}
const ImgFromUrl = ({ item, image, refName }) => {
  const [imageObj, setimageObj] = useState(null);
  useEffect(() => {
    return () => {
      setimageObj(null);
    };
  }, []);

  useEffect(() => {
    if (!image) return;
    const init = async () => {
      try {
        const response = await axios.get(`${image}`, {
          responseType: "arraybuffer",
        });
        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });

        const imageDataUrl = URL.createObjectURL(blob);
        setimageObj(imageDataUrl);
      } catch (error) {
        console.error(error);
      }
    };
    init();
  }, [image]);

  useEffect(() => {
    return () => {
      setimageObj(null);
    };
  }, []);
  return (
    <Card>
      <LazyLoadImage
        alt={item.name}
        src={imageObj} // use normal <img> attributes as props
        width="100%"
      />
      <CardContent>
        <Typography variant="span" sx={{ color: "text.secondary" }}>
          {item.name}
        </Typography>
      </CardContent>
      <CardActions sx={{ display: "flex", justifyContent: "flex-end" }}>
        <MediaEditBtn editData={item} refName={refName} />
        <MediaDelBtn editData={item} refName={refName} />
      </CardActions>
    </Card>
  );
};
