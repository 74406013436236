import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { NavLink } from "react-router-dom";
import styled from "@emotion/styled";
import {
  Avatar,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Card as MuiCard,
  CardActions,
  CardContent as MuiCardContent,
  CardMedia as MuiCardMedia,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid,
  Link,
  Typography as MuiTypography,
  AvatarGroup as MuiAvatarGroup,
} from "@mui/material";
import { spacing } from "@mui/system";
import useReferentiel from "hooks/useReferentiel";
import RefsList from "./RefsList";
import RefsEditBtn from "./RefsEditBtn";
// ----------------------------------------------------------------------
const Typography = styled(MuiTypography)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
export default function RefsReportContentPage() {
  const { refs } = useReferentiel();

  if (!refs) return <></>;
  const { refgiftcategory } = refs;
  return (
    <>
      <Helmet title="Gestion des thèmes" />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Types de coupon cadeau
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/app">
              Accueil
            </Link>
            <Typography>Types de coupon cadeau</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <RefsEditBtn
            refName="refgiftcategory"
            addLabel="Nouveau type de coupon"
          />
        </Grid>
      </Grid>

      <Divider my={6} />
      <Grid container spacing={6}>
        <RefsList items={refgiftcategory} refName="refgiftcategory" />
      </Grid>
    </>
  );
}
