import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { withTheme } from "@emotion/react";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Bar } from "react-chartjs-2";

import { MoreVertical } from "react-feather";

import {
  Avatar as MuiAvatar,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardMedia as MuiCardMedia,
  CardContent,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid as MuiGrid,
  LinearProgress as MuiLinearProgress,
  Link,
  Table,
  CardHeader,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography as MuiTypography,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import axios from "axios";
import { API_URL } from "config";
import { Add as AddIcon } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import { spacing } from "@mui/system";
import useChallenge from "hooks/useChallenge";
import { getMediaUrl } from "utils/objectUtil";
import AudioBoxFromUrl from "components/media/AudioBoxFromUrl";
// ----------------------------------------------------------------------
const LetterAvatar = styled(MuiAvatar)(spacing);
const Chip = styled(MuiChip)(spacing);
const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Grid = styled(MuiGrid)(spacing);
const Spacer = styled.div(spacing);
const Typography = styled(MuiTypography)(spacing);
const TableWrapper = styled.div`
  overflow-y: auto;
  width: 100%;
  /* max-width: calc(100vw - ${(props) => props.theme.spacing(12)}); */
`;
export default function UserProgramStereo({ editData }) {
  if (!editData) return <></>;
  if (!editData.userprogram) return <></>;
  const { responses, stereochallenge } = editData.userprogram;

  const stereoPoint = () => {
    try {
      const { stereochallenge } = responses;
      return ` ${stereochallenge.point} (points)`;
    } catch (error) {
      return "";
    }
  };
  return (
    <>
      <Card mb={6}>
        <CardHeader
          title={`Résultat du stereotype challenge: ${stereochallenge.title}`}
        />
        <CardContent>
          <Typography variant="p" gutterBottom>
            {`Sujet ${stereochallenge.refsubject.code} - ${stereochallenge.refsubject.label} `}
          </Typography>
          <Typography variant="span" color="primary" gutterBottom>
            <strong>{`${stereoPoint()}`}</strong>
          </Typography>
          <Spacer mb={3} />
          {stereochallenge.questions && (
            <Questions
              questions={stereochallenge.questions}
              responses={responses}
            />
          )}
        </CardContent>
      </Card>
    </>
  );
}

const Questions = ({ questions, responses }) => {
  if (!Array.isArray(questions)) return <></>;
  const getAvatarStereo = (it) => {
    try {
      const str = it.refstereotype.code.substring(0, 2);
      return str;
    } catch (error) {
      return "";
    }
  };
  return (
    <Grid container spacing={6}>
      <Grid item>
        <Card mb={6}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              La liste des questionnaires et réponses utilisateur
            </Typography>
            <TableWrapper>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Question</TableCell>
                    <TableCell>Réponses</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {questions.map((it, i) => {
                    return (
                      <TableRow
                        key={i}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {it.title}
                        </TableCell>
                        <TableCell>
                          {it.choices.map((challenge, i) => {
                            let userChoiced = null;
                            try {
                              userChoiced =
                                responses.stereochallenge.choices.find(
                                  (userChallenge) => {
                                    return (
                                      String(userChallenge.selected) ===
                                        String(challenge._id) &&
                                      String(it.id) ===
                                        String(userChallenge._id)
                                    );
                                  }
                                );
                            } catch (error) {
                              console.error(error);
                            }
                            return (
                              <React.Fragment key={i}>
                                <Card mb={1} sx={{ p: 0 }}>
                                  {userChoiced ? (
                                    <Chip
                                      variant="contained"
                                      label={`${challenge.title}`}
                                      m={1}
                                      color={"primary"}
                                      avatar={
                                        <LetterAvatar>
                                          {userChoiced.pointgained}
                                        </LetterAvatar>
                                      }
                                    />
                                  ) : (
                                    <Typography
                                      variant="p"
                                      gutterBottom
                                      sx={{ mx: 6 }}
                                    >
                                      {challenge.title}
                                    </Typography>
                                  )}
                                </Card>
                                <Divider my={2} />
                              </React.Fragment>
                            );
                          })}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableWrapper>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
/* 

{it.pointgained.map((p, i) => {
                                    return (
                                      <Chip
                                        variant="outlined"
                                        key={i}
                                        avatar={
                                          <LetterAvatar>
                                            {getAvatarStereo(p)}
                                          </LetterAvatar>
                                        }
                                        label={`${p.refstereotype.label}: ${p.point}`}
                                        m={1}
                                        color={
                                          p.refstereotype.code === "strong"
                                            ? "error"
                                            : p.refstereotype.code === "medium"
                                            ? "warning"
                                            : "primary"
                                        }
                                      />
                                    );
                                  })}
*/
