import { useEffect, useState } from "react";
import React from "react";
import styled from "@emotion/styled";
import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Checkbox,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Link,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { green, orange, red } from "@mui/material/colors";
import {
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
  RemoveRedEye as RemoveRedEyeIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import useDataAppcode from "hooks/useDataAppcode";
import AppcodeEditBtn from "./AppcodeEditBtn";
import AppcodeDelBtn from "./AppcodeDelBtn";
import AppcodeResetBtn from "./AppcodeResetBtn";
import SearchAppcodeByKw from "./SearchAppcodeByKw";
// ----------------------------------------------------------------------
const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Chip = styled(MuiChip)`
  ${spacing};

  background: ${(props) => props.shipped && green[500]};
  background: ${(props) => props.processing && orange[700]};
  background: ${(props) => props.cancelled && red[500]};
  color: ${(props) => props.theme.palette.common.white};
`;

const Spacer = styled.div`
  flex: 1 1 100%;
`;

const ToolbarTitle = styled.div`
  min-width: 150px;
`;

function descendingComparator(a, b, orderBy) {
  let aVal = "";
  let bVal = "";
  if (orderBy === "pseudo") {
    aVal = a.userapp.pseudo ? a.userapp.pseudo : "";
    bVal = b.userapp.pseudo ? b.userapp.pseudo : "";
  } else if (orderBy === "name") {
    aVal = `${a.userapp.lastname} ${a.userapp.firstname}`;
    bVal = `${b.userapp.lastname} ${b.userapp.firstname}`;
  } else {
    aVal = a[orderBy];
    bVal = b[orderBy];
  }
  if (bVal < aVal) {
    return -1;
  }
  if (bVal > aVal) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}
const headCells = [
  { id: "id", alignment: "left", label: "ID" },
  { id: "code", alignment: "left", label: "Code" },
  { id: "description", alignment: "left", label: "Description" },
  { id: "pseudo", alignment: "left", label: "Pseudo" },
  { id: "name", alignment: "left", label: "Utilisateur" },
  { id: "refstatus", alignment: "left", label: "Statut" },
  { id: "actions", alignment: "right", label: "Actions" },
];

const EnhancedTableHead = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all" }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignment}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const EnhancedTableToolbar = (props) => {
  // Here was 'let'
  const { numSelected, handleSearch, handleReset } = props;

  return (
    <Toolbar>
      <ToolbarTitle>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} sélectionné(s)
          </Typography>
        ) : (
          <Typography
            variant="h6"
            id="tableTitle"
            style={{ minWidth: "200px" }}
          >
            Tous les codes
          </Typography>
        )}
      </ToolbarTitle>
      <Spacer />
      <div>
        <SearchAppcodeByKw
          handleSearch={handleSearch}
          handleReset={handleReset}
        />
      </div>
    </Toolbar>
  );
};
export default function AppcodesGrid() {
  const { appcodes } = useDataAppcode();
  const [rows, setrows] = useState([]);
  useEffect(() => {
    if (!appcodes || !Array.isArray(appcodes)) {
      setrows([]);
      return;
    }
    setrows(appcodes);
  }, [appcodes]);

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("customer");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleSearch = (kw) => {
    try {
      if (!kw) return;
      if (!Array.isArray(appcodes)) return;
      console.log(kw);
      const newArr = appcodes.filter((it) => {
        const isCodeContainsKw = it.code
          .toUpperCase()
          .includes(kw.toUpperCase());
        const isPseudoContainsKw = it.userapp.pseudo
          ? it.userapp.pseudo.toUpperCase().includes(kw.toUpperCase())
          : false;
        const isNameContainsKw =
          it.userapp.firstname && it.userapp.lastname
            ? String(`${it.userapp.lastname} ${it.userapp.lastname}`)
                .toUpperCase()
                .includes(kw.toUpperCase())
            : false;
        return isCodeContainsKw || isPseudoContainsKw || isNameContainsKw;
      });
      setrows(newArr);
    } catch (error) {
      console.error(error);
    }
  };
  const handleReset = () => {
    try {
      setrows(appcodes);
    } catch (error) {
      console.error(error);
    }
  };
  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div>
      <Paper>
        <EnhancedTableToolbar
          numSelected={selected.length}
          handleReset={handleReset}
          handleSearch={handleSearch}
        />
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size={"medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <React.Fragment key={`${row.id}-${index}`}>
                      {row && row.userapp && (
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          selected={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              inputProps={{ "aria-labelledby": labelId }}
                              onClick={(event) => handleClick(event, row.id)}
                            />
                          </TableCell>

                          <TableCell align="left">#{row.id}</TableCell>
                          <TableCell align="left">{row.code}</TableCell>
                          <TableCell align="left">{row.description}</TableCell>
                          <TableCell align="left">
                            {row.userapp.pseudo ? row.userapp.pseudo : ""}
                          </TableCell>
                          <TableCell align="left">
                            {row.userapp.lastname && row.userapp.firstname
                              ? `${row.userapp.lastname} ${row.userapp.firstname}`
                              : ""}
                          </TableCell>
                          <TableCell>
                            {row.enable ? (
                              <Chip
                                size="small"
                                mr={1}
                                mb={1}
                                label="Activé"
                                shipped={+true}
                              />
                            ) : (
                              <Chip
                                size="small"
                                mr={1}
                                mb={1}
                                label="Désactivé"
                                cancelled={+true}
                              />
                            )}
                          </TableCell>
                          <TableCell padding="none" align="right">
                            <Box
                              mr={2}
                              display="flex"
                              justifyContent="flex-end"
                              alignItems="center"
                            >
                              <AppcodeEditBtn editData={row} />
                              <AppcodeDelBtn editdata={row} />
                              <AppcodeResetBtn editdata={row} />
                            </Box>
                          </TableCell>
                        </TableRow>
                      )}
                    </React.Fragment>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={8} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
