import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  isLoaded: false,
  refs: {},
  appconfigs: [],
};
const refSlice = createSlice({
  name: "ref",
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
      state.isLoaded = false;
    },
    stopLoading(state) {
      state.isLoading = false;
      state.isLoaded = true;
    },
    setInitialize(state, action) {
      state.isLoading = false;
      state.refs = action.payload.refs;
      state.isLoaded = true;
    },
    setInitAppconfig(state, action) {
      state.isLoading = false;
      state.appconfigs = action.payload;
      state.isLoaded = true;
    },
  },
});
export const { startLoading, stopLoading, setInitialize, setInitAppconfig } =
  refSlice.actions;
const refReducer = refSlice.reducer;

export default refReducer;
