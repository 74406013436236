import React, { useState } from "react";
import PowerIcon from "@mui/icons-material/Power";
import PowerOffIcon from "@mui/icons-material/PowerOff";
import useProgram from "hooks/useProgram";
import { Button } from "@mui/material";
import useNotification from "hooks/useNotification";
import CircularProgress from "@mui/material/CircularProgress";
// ----------------------------------------------------------------------
export default function BoosterProgUpdBtn({ editProgram, data }) {
  const [submitting, setsubmitting] = useState(false);
  const { initProgramById, saveBoosterProg } = useProgram();
  const { notif } = useNotification();
  const updateBoosterProg = async (enable) => {
    try {
      setsubmitting(true);
      await saveBoosterProg(editProgram, data.booster, enable);
      await initProgramById(editProgram.id);
      notif("Sauvegarde réussie");
      setsubmitting(false);
    } catch (error) {
      console.error(error);
      notif(null, error);
      setsubmitting(false);
    }
  };
  return (
    <>
      {submitting ? (
        <CircularProgress />
      ) : (
        <>
          {data.enable ? (
            <Button
              variant="outlined"
              color="error"
              onClick={() => updateBoosterProg(false)}
            >
              <PowerOffIcon size="small" />
              Désactiver
            </Button>
          ) : (
            <Button
              variant="contained"
              color="success"
              onClick={() => updateBoosterProg(true)}
            >
              <PowerIcon size="small" />
              Activer
            </Button>
          )}
        </>
      )}
    </>
  );
}
