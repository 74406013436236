import React, { useEffect, useState } from "react";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import styled from "@emotion/styled";
import {
  Box,
  MenuItem,
  TextField as MuiTextField,
  Button,
  Stack,
  Paper,
  Divider as MuiDivider,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { Helmet } from "react-helmet-async";
import { spacing } from "@mui/system";
import useNotification from "hooks/useNotification";
import useReferentiel from "hooks/useReferentiel";
import UploadSingleFile from "components/file/UploadSingleFile";
import ImgBoxFromUrl from "components/media/ImgBoxFromUrl";
import AudioBoxFromUrl from "components/media/AudioBoxFromUrl";
import VideoBoxFromUrl from "components/media/VideoBoxFromUrl";
import useGift from "hooks/useGift";
import BackBtn from "components/custom/BackBtn";
import AlertMsg from "components/custom/AlertMsg";
import { getMediaUrl } from "utils/objectUtil";
import UploadMultiFiles from "components/file/UploadMultiFiles";
import GalleryImg from "./GalleryImg";
import GiftStockBtn from "./GiftStockBtn";
// ----------------------------------------------------------------------
const REF_MODEL = "gift";
const Divider = styled(MuiDivider)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Item = styled(Paper)(({ theme }) => ({
  //backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
export default function GiftEditPage() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { getStockByGift, getGifts, getGiftById, saveGiftFormData, saveGift } =
    useGift();
  const { notif } = useNotification();
  const { refs } = useReferentiel();
  const { refgiftcategory } = refs;
  const [loading, setloading] = useState(false);
  const [editData, seteditdata] = useState(null);
  const [stock, setstock] = useState(null);
  useEffect(() => {
    if (!id || id === "add") return;
    const init = async () => {
      try {
        const res = await getGiftById(id);
        if (res) {
          seteditdata(res);
        }
      } catch (error) {
        console.error();
      }
    };
    init();
  }, [id]);

  useEffect(() => {
    if (!editData || !editData.id) return;

    initGiftStock();
  }, [editData]);
  const initGiftStock = async () => {
    try {
      if (!editData) return;
      if (!editData.id) return;
      const res = await getStockByGift(editData.id);
      if (res) {
        setstock(res);
      } else {
        setstock(null);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleCompleteDelImg = async () => {
    if (!id || id === "add") return;
    const res = await getGiftById(id);
    if (res) {
      seteditdata(res);
    }
  };
  const today = new Date(Date.now() - 86400000);
  const dataSchema = Yup.object().shape({
    code: Yup.string().max(20).required("Veuillez renseigner le code"),
    title: Yup.string().max(100).required(`Veuillez renseigner le titre`),
    description: Yup.string(),
    conditions: Yup.string()
      .max(500)
      .required(`Veuillez renseigner les conditions`),
    pointvalue: Yup.number().required(`Veuillez renseigner le titre`),
    refgiftcategory: Yup.string()
      .max(100)
      .required(`Veuillez renseigner le type`),
    enablefrom: Yup.date()
      .required(`Veuillez renseigner le début de validité`)
      .min(today, "La date devrait être supérieure à la date courante"),
    enableend: Yup.date()
      .required(`Veuillez renseigner la fin de validité`)
      .min(today, "La date devrait être supérieure à la date courante"),
    image:
      editData && editData.id
        ? Yup.string()
        : Yup.mixed().required("Veuillez renseigner l'image"),
  });
  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      code: editData && editData.code ? editData.code : "",
      title: editData && editData.title ? editData.title : "",
      description: editData && editData.description ? editData.description : "",
      conditions: editData && editData.conditions ? editData.conditions : "",
      pointvalue: editData && editData.pointvalue ? editData.pointvalue : "",
      refgiftcategory:
        editData && editData.refgiftcategory ? editData.refgiftcategory.id : "",
    },
    validationSchema: dataSchema,
    onSubmit: async () => {
      try {
        const newData =
          editData && editData.id ? { ...editData, ...values } : { ...values };
        /* in case file upload is choiced we need to make formdata */
        const isFormdata = values.image || values.galleryimg;

        if (isFormdata) {
          const formData = new FormData();
          delete newData.modifiedby;
          delete newData.createdby;
          if (values.image) {
            delete newData.image;
            formData.append("image", values.image);
          }
          if (values.galleryimg) {
            delete newData.galleryimg;
            values.galleryimg.forEach((file) =>
              formData.append("galleryimg", file)
            );
          }
          Object.entries(newData).forEach(([e, k]) => {
            if (k) {
              formData.append(e, k);
            }
          });
          await saveGiftFormData(newData, formData);
        } else {
          await saveGift({
            ...newData,
          });
        }
        await getGifts();
        notif("Sauvegarde réussie");
        navigate("/app/gift");
      } catch (error) {
        console.error(error);
        notif(null, error);
        setSubmitting(false);
      }
    },
  });

  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setSubmitting,
    handleChange,
    setFieldValue,
  } = formik;
  useEffect(() => {
    if (!setFieldValue) return;
    if (editData && editData.enablefrom) {
      setFieldValue("enablefrom", editData.enablefrom);
    } else {
      setFieldValue("enablefrom", new Date());
    }
    if (editData && editData.enableend) {
      setFieldValue("enableend", editData.enableend);
    } else {
      setFieldValue("enableend", new Date());
    }
  }, [setFieldValue, editData]);
  if (!refs) return;
  if (!refgiftcategory) return <>Veuillez entrer au moin un type de cadeau</>;
  return (
    <>
      <Helmet title="Edition de cadeau" />
      <Box
        sx={{ with: "100%" }}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <BackBtn
          backUrl="/app/gift"
          needConfirm={true}
          titleConfim="Demande de confirmation"
          msgConfirm="Des données saisies pourraient ne pas être enregistrées.. Voulez-vous quitter cette page ?"
        />
        {editData && editData.id && (
          <GiftStockBtn
            editData={editData}
            stock={stock}
            onComplete={initGiftStock}
          />
        )}
      </Box>
      <Divider my={6} />
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <AlertMsg errors={errors} />
          <TextField
            fullWidth
            type="text"
            label="Code"
            {...getFieldProps("code")}
            error={Boolean(touched.code && errors.code)}
            helperText={touched.code && errors.code}
            my={2}
          />
          <TextField
            fullWidth
            type="title"
            label="Titre"
            {...getFieldProps("title")}
            error={Boolean(touched.title && errors.title)}
            helperText={touched.title && errors.title}
            my={2}
          />
          <TextField
            fullWidth
            type="text"
            name="description"
            label="Description"
            {...getFieldProps("description")}
            error={Boolean(touched.description && errors.description)}
            helperText={touched.description && errors.description}
            my={2}
          />
          <TextField
            fullWidth
            type="text"
            name="conditions"
            label="Disponibilités & Conditions"
            {...getFieldProps("conditions")}
            error={Boolean(touched.conditions && errors.conditions)}
            helperText={touched.conditions && errors.conditions}
            my={2}
            multiline
            rows={3}
            maxRows={6}
          />
          <TextField
            fullWidth
            type="number"
            name="pointvalue"
            label="Prix d'achat (en bomba coins)"
            {...getFieldProps("pointvalue")}
            error={Boolean(touched.pointvalue && errors.pointvalue)}
            helperText={touched.pointvalue && errors.pointvalue}
            my={2}
          />
          <DatePicker
            onChange={(value) => {
              setFieldValue("enablefrom", value, true);
            }}
            value={values.enablefrom}
            renderInput={(params) => (
              <TextField
                fullWidth
                label="Début de validité"
                {...getFieldProps("enablefrom")}
                error={Boolean(touched.enablefrom && errors.enablefrom)}
                helperText={touched.enablefrom && errors.enablefrom}
                my={2}
                {...params}
              />
            )}
          />
          <DatePicker
            onChange={(value) => {
              setFieldValue("enableend", value, true);
            }}
            value={values.enableend}
            renderInput={(params) => (
              <TextField
                fullWidth
                label="Fin de validité"
                {...getFieldProps("enableend")}
                error={Boolean(touched.enableend && errors.enableend)}
                helperText={touched.enableend && errors.enableend}
                my={2}
                {...params}
              />
            )}
          />
          <TextField
            fullWidth
            select
            label="Type de cadeau"
            value={values.refgiftcategory}
            onChange={handleChange("refgiftcategory")}
            {...getFieldProps("refgiftcategory")}
            error={Boolean(touched.refgiftcategory && errors.refgiftcategory)}
            helperText={touched.refgiftcategory && errors.refgiftcategory}
            my={2}
          >
            {refgiftcategory.map((option, i) => (
              <MenuItem key={i} value={option.id}>
                {`${option.label}`}
              </MenuItem>
            ))}
          </TextField>

          <Stack
            alignItems="center"
            justifyContent="flex-start"
            sx={{ my: 6 }}
            direction="row"
            spacing={5}
          >
            {editData && editData.image && (
              <Item>
                <ImgBoxFromUrl
                  image={editData.image}
                  sx={{
                    borderRadius: 1,
                    objectFit: "cover",
                    width: "150px",
                    height: "auto",
                  }}
                />
              </Item>
            )}
            <Item>
              <UploadSingleFile
                value={values.image}
                label="Image d'illustration"
                onChange={(val) => setFieldValue("image", val)}
                caption="(Les formats *.jpeg and *.png sont acceptés)"
                error={Boolean(touched.image && errors.image)}
                sx={{ maxHeight: "150px" }}
                acceptFile={{
                  accept: {
                    "image/jpeg": [".jpeg", ".png"],
                  },
                }}
                mediaType="image"
              />
            </Item>
          </Stack>
          <Divider my={3} />

          <UploadMultiFiles
            value={values.galleryimg}
            label="Galerie d'image"
            onChange={(val) => setFieldValue("galleryimg", val)}
            caption="(Les formats *.jpeg and *.png sont acceptés)"
            acceptFile={{
              accept: {
                "image/jpeg": [".jpeg", ".png"],
              },
            }}
            mediaType="image"
          />

          {editData && editData.id && (
            <>
              <GalleryImg
                editData={editData}
                onComplete={handleCompleteDelImg}
              />
            </>
          )}
          <Divider my={3} />
          <Button
            sx={{ my: 2 }}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            Valider
          </Button>
        </Form>
      </FormikProvider>
    </>
  );
}
