import React from "react";
import styled from "@emotion/styled";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Alert as MuiAlert,
  Button,
  IconButton,
  Popover,
  FormHelperText,
  Card,
  CardActions,
  CardContent,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import EditIcon from "@mui/icons-material/Edit";
import LockResetIcon from "@mui/icons-material/LockReset";
import CodeGen from "components/CodeGen";
import useDataUser from "hooks/useDataUser";
import useNotification from "hooks/useNotification";
// ----------------------------------------------------------------------
const Alert = styled(MuiAlert)(spacing);
export default function UserChangePwdBtn({ editUser }) {
  const { changePassword } = useDataUser();
  const { notif } = useNotification();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  if (!editUser || !editUser.id) return;
  return (
    <div>
      <IconButton aria-label="details" size="large" onClick={handleClick}>
        <LockResetIcon />
      </IconButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Card  sx={{
            minWidth: 275,
            backgroundColor: "#1A2027",
          }}>
          <CardContent>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              Modification du mot de passe
            </Typography>
            <Formik
              initialValues={{
                password: "",
                submit: false,
              }}
              validationSchema={Yup.object().shape({
                password: Yup.string()
                  .max(255)
                  .required("Veuillez renseigner le mot de passe"),
              })}
              onSubmit={async (
                values,
                { setErrors, setStatus, setSubmitting }
              ) => {
                try {
                  const { password } = values;
                  await changePassword(editUser, password);
                  notif("Modification du mot de passe réussie");
                  handleClose();
                } catch (error) {
                  notif(null, error);
                  const message = error.message || "Something went wrong";
                  setStatus({ success: false });
                  setErrors({ submit: message });
                  setSubmitting(false);
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
                setFieldValue,
              }) => (
                <form noValidate onSubmit={handleSubmit}>
                  {errors.submit && (
                    <Alert mt={2} mb={3} severity="warning">
                      {errors.submit}
                    </Alert>
                  )}

                  <CodeGen
                    name="password"
                    label="Mot de passe"
                    title="Générer le mot de passe"
                    placeholder="Mot de passe"
                    value={values.password}
                    onChange={(val) => {
                      setFieldValue("password", val);
                    }}
                    error={Boolean(touched.password && errors.password)}
                  />

                  {errors.password && (
                    <FormHelperText error>{errors.password} </FormHelperText>
                  )}

                  <Button
                    sx={{ mt: 2 }}
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    Valider
                  </Button>
                </form>
              )}
            </Formik>
          </CardContent>
        </Card>

        <CardActions sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button onClick={handleClose}>Annuler</Button>
        </CardActions>
      </Popover>
    </div>
  );
}
