import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { NavLink } from "react-router-dom";
import styled from "@emotion/styled";
import {
  Avatar,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Card as MuiCard,
  CardActions,
  CardContent as MuiCardContent,
  CardMedia as MuiCardMedia,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid,
  Link,
  Typography as MuiTypography,
  AvatarGroup as MuiAvatarGroup,
} from "@mui/material";
import { spacing } from "@mui/system";
import useAppmedia from "hooks/useAppmedia";
import MediaUpload from "./MediaUpload";
import ImageList from "./ImageList";
// ----------------------------------------------------------------------
const Typography = styled(MuiTypography)(spacing);

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const REF_MODEL = "appimage";

export default function LibImagePage() {
  const { appimages, getAppImages } = useAppmedia();

  useEffect(() => {
    getAppImages();
  }, []);
  if (!appimages) return <></>;

  return (
    <>
      <Helmet title="Bomba images" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Bomba images
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/app">
              Accueil
            </Link>
            <Typography>Bomba images</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <MediaUpload
            refName={REF_MODEL}
            addLabel="Ajouter des images"
            mediaType="image"
          />
        </Grid>
      </Grid>

      <Divider my={6} />

      {appimages && (
        <ImageList items={appimages} imgField="filepath" refName={REF_MODEL} />
      )}
    </>
  );
}
