import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { withTheme } from "@emotion/react";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Bar } from "react-chartjs-2";

import { MoreVertical } from "react-feather";

import {
  Avatar as MuiAvatar,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardMedia as MuiCardMedia,
  CardContent,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid as MuiGrid,
  LinearProgress as MuiLinearProgress,
  Link,
  Table,
  CardHeader,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography as MuiTypography,
  IconButton,
  Menu,
  MenuItem,
  CardActions,
} from "@mui/material";
import axios from "axios";
import { API_URL } from "config";
import { Add as AddIcon } from "@mui/icons-material";
import SportsGymnasticsIcon from "@mui/icons-material/SportsGymnastics";
import MicExternalOnIcon from "@mui/icons-material/MicExternalOn";
import MissedVideoCallIcon from "@mui/icons-material/MissedVideoCall";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";
import BookmarkAddedIcon from "@mui/icons-material/BookmarkAdded";
import EditIcon from "@mui/icons-material/Edit";
import { spacing } from "@mui/system";
import useProgram from "hooks/useProgram";
import { getMediaUrl } from "utils/objectUtil";
import CardMediaFromUrl from "components/media/CardMediaFromUrl";
// ----------------------------------------------------------------------
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Button = styled(MuiButton)(spacing);

const Card = styled(MuiCard)(spacing);

const Chip = styled(MuiChip)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Grid = styled(MuiGrid)(spacing);

const Typography = styled(MuiTypography)(spacing);

const REF_MODEL = "booster";

export default function BoosterConfig({ editProgram, boosters }) {
  return (
    <>
      <Grid container spacing={6}>
        <>
          {Array.isArray(boosters) && (
            <>
              {boosters.map((it, i) => {
                return (
                  <Grid item xs={12} lg={6} xl={3} key={i}>
                    <Detail data={it} editProgram={editProgram} />
                  </Grid>
                );
              })}
            </>
          )}
        </>
      </Grid>
    </>
  );
}
/* item card */
/* item card */
const Detail = ({ editProgram, data }) => {
  const { enable, booster } = data;
  if (!data) return <></>;
  if (!booster) return <></>;
  return (
    <Card>
      <CardHeader title={booster.label} sx={{ textAlign: "center" }} />
      {booster.image ? (
        <CardMediaFromUrl
          image={booster.image}
          title={booster.title}
          sx={{ backgroundSize: "contain" }}
        />
      ) : null}
      <CardContent
        sx={{
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography color="textPrimary" component="p">
          {`${booster.refboostertype.code} - ${booster.refboostertype.label}`}
        </Typography>
      </CardContent>
    </Card>
  );
};
