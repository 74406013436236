import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import { useCallback, useEffect, useState } from "react";
// material
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import ReactPlayer from "react-player";

// ----------------------------------------------------------------------

const DropZoneStyle = styled("div")(({ theme }) => ({
  outline: "none",
  display: "flex",
  overflow: "hidden",
  textAlign: "center",
  position: "relative",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(1, 0),
  borderRadius: theme.shape.borderRadius,
  transition: theme.transitions.create("padding"),
  backgroundColor: theme.palette.background.neutral,
  border: `1px dashed ${theme.palette.grey[500_32]}`,
  "&:hover": {
    opacity: 0.72,
    cursor: "pointer",
  },
  [theme.breakpoints.up("md")]: {
    textAlign: "left",
    flexDirection: "row",
  },
}));
const PlayerStyle = styled(ReactPlayer)(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  overflow: "hidden",
  "& video": {
    width: "auto!important",
    height: "120px!important",
    position: "absolute",
  },
}));
// ----------------------------------------------------------------------

UploadSingleFile.propTypes = {
  caption: PropTypes.string,
  error: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
  sx: PropTypes.object,
};

export default function UploadSingleFile({
  caption,
  error = false,
  value: file,
  label,
  onChange: setFile,
  sx,
  acceptFile,
  mediaType,
  ...other
}) {
  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        setFile(file);
      }
    },
    [setFile]
  );
  const { getRootProps, getInputProps, isDragActive, isDragReject } =
    useDropzone({
      ...acceptFile,
      onDrop: handleDrop,
      multiple: false,
    });
  const [preview, setPreview] = useState(null);
  useEffect(() => {
    if (file) {
      const preview = URL.createObjectURL(file);
      setPreview(preview);
    } else {
      setPreview(null);
    }
  }, [file]);
  const handeRemove = (e) => {
    setFile(null);
    e.preventDefault();
  };
  const getMediaTypeIcon = () => {
    try {
      if (mediaType === "audio") {
        return "/static/img/illustrations/audio_upload.png";
      } else if (mediaType === "video") {
        return "/static/img/illustrations/video_upload.png";
      }
      return "/static/img/illustrations/image_upload.png";
    } catch (error) {
      return "/static/img/illustrations/image_upload.png";
    }
  };
  const getPreview = () => {
    try {
      if (!file) return <></>;
      if (!preview) return <></>;
      if (mediaType === "audio" || mediaType === "txt") {
        const name =
          file && file.name && file.name.length < 50
            ? file.name
            : file.name.substring(0, 50) + "...";
        return (
          <Box
            sx={{
              top: 8,
              borderRadius: 1,
              objectFit: "cover",
              position: "absolute",
              width: "calc(100% - 16px)",
              height: "calc(100% - 16px)",
            }}
          >
            <Typography variant="span" sx={{ color: "text.secondary" }}>
              {name}
            </Typography>
          </Box>
        );
      } else if (mediaType === "video") {
        return (
          <Box
            sx={{
              top: 8,
              borderRadius: 1,
              position: "absolute",
              width: "calc(100% - 16px)",
              height: "calc(100% - 16px)",
            }}
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
          >
            <PlayerStyle muted playing url={preview} />
          </Box>
        );
      }
      return (
        <Box
          component="img"
          alt="file preview"
          src={preview}
          sx={{
            top: 8,
            borderRadius: 1,
            objectFit: "cover",
            position: "absolute",
            width: "calc(100% - 16px)",
            height: "calc(100% - 16px)",
          }}
        />
      );
    } catch (error) {
      return <></>;
    }
  };
  return (
    <Box display="flex" sx={{ width: "100%", ...sx }} {...other}>
      <DropZoneStyle
        {...getRootProps()}
        sx={{
          ...(isDragActive && { opacity: 0.72 }),
          ...((isDragReject || error) && {
            color: "error.main",
            borderColor: "error.light",
            bgcolor: "error.lighter",
          }),
          ...(file && { padding: "12% 0" }),
        }}
      >
        <input {...getInputProps()} />

        <Box
          component="img"
          alt="select file"
          src={getMediaTypeIcon()}
          sx={{ height: 50 }}
        />
        <Box sx={{ p: 3, ml: { md: 2 } }}>
          <Typography gutterBottom variant="h5">
            {label ? label + ": " : ""} déposez ou sélectionnez un fichier
          </Typography>

          {caption ? (
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              {caption}
            </Typography>
          ) : (
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              {label ? label + ": " : ""} déposez ici ou sélectionnez un fichier
              &nbsp;
              <Typography
                variant="body2"
                component="span"
                sx={{ color: "primary.main" }}
              >
                explorer
              </Typography>
              &nbsp;sur votre machine
            </Typography>
          )}
        </Box>

        {file && <>{getPreview()}</>}
      </DropZoneStyle>
      {file && (
        <Box sx={{ paddingTop: "20px" }}>
          <IconButton
            size="small"
            color="error"
            onClick={(e) => handeRemove(e)}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      )}
    </Box>
  );
}
