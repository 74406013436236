import React from "react";
import { Grid, Box, Tabs, Tab, Typography } from "@mui/material";
import Chip from "@mui/material/Chip";
import DoneIcon from "@mui/icons-material/Done";
import SignalCellularConnectedNoInternet3BarIcon from "@mui/icons-material/SignalCellularConnectedNoInternet3Bar";
import PublishDayflow from "./PublishDayflow";
// ----------------------------------------------------------------------
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}
export default function TabPublishDayflow({ editProgram }) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getContentTab = () => {
    try {
      if (!editProgram.dayflows) return <></>;

      const dayFlow = editProgram.dayflows.find(
        (it) => Number(it.daystep) === Number(value) + 1
      );
      if (!dayFlow) return <></>;
      return <DayflowConfig editProgram={editProgram} dayflow={dayFlow} />;
    } catch (error) {
      console.error(error);
      return <></>;
    }
  };
  if (!editProgram) return <></>;
  if (!editProgram.dayflows) return <></>;
  return (
    <Box
      sx={{
        flexGrow: 1,
        bgcolor: "background.paper",
        display: "flex",
      }}
    >
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{ borderRight: 1, borderColor: "divider" }}
      >
        {editProgram.dayflows.map((it, i) => {
          return (
            <Tab
              key={i}
              label={
                <TabLabelDayflow
                  dayflow={it}
                  isCurrentTab={Number(i) === Number(value)}
                />
              }
              {...a11yProps(i)}
            />
          );
        })}
      </Tabs>
      <Box sx={{ width: "100%", height: "100%" }}>{getContentTab()}</Box>
    </Box>
  );
}

const DayflowConfig = ({ editProgram, dayflow }) => {
  if (!editProgram) return <></>;
  if (!dayflow) return <></>;
  return (
    <>
      <PublishDayflow editProgram={editProgram} dayflow={dayflow} />
    </>
  );
};

const TabLabelDayflow = ({ dayflow, isCurrentTab }) => {
  const handleClick = () => {
    console.info();
  };

  const handleDelete = () => {
    console.info();
  };
  if (!dayflow) return <></>;
  const getChip = () => {
    try {
      if (dayflow.isValidated) {
        return (
          <Chip
            color="success"
            variant={isCurrentTab ? "contained" : "outlined"}
            sx={{ minWidth: 100 }}
            label={`Jour ${dayflow.daystep}`}
            onClick={handleClick}
            onDelete={handleDelete}
            deleteIcon={<DoneIcon />}
          />
        );
      }
      return (
        <Chip
          color="warning"
          variant={isCurrentTab ? "contained" : "outlined"}
          sx={{ minWidth: 100 }}
          label={`Jour ${dayflow.daystep}`}
          onClick={handleClick}
          onDelete={handleDelete}
          deleteIcon={<SignalCellularConnectedNoInternet3BarIcon />}
        />
      );
    } catch (error) {
      return <></>;
    }
  };
  return <>{getChip()} </>;
};
