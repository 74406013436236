import React from "react";

import {
  Button,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AppVideoForm from "./AppVideoForm";
import AppMusicForm from "./AppMusicForm";
import AppImageForm from "./AppImageForm";
// ----------------------------------------------------------------------
export default function MediaEditBtn({ editData, refName }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getForm = () => {
    try {
      if (refName === "appvideo") {
        return (
          <AppVideoForm
            editData={editData}
            refName={refName}
            onComplete={() => handleClose()}
          />
        );
      } else if (refName === "appmusic") {
        return (
          <AppMusicForm
            editData={editData}
            refName={refName}
            onComplete={() => handleClose()}
          />
        );
      } else if (refName === "appimage") {
        return (
          <AppImageForm
            editData={editData}
            refName={refName}
            onComplete={() => handleClose()}
          />
        );
      }
      return <></>;
    } catch (error) {
      console.log(error);
      return <></>;
    }
  };
  return (
    <div>
      <IconButton aria-label="details" size="small" onClick={handleClickOpen}>
        <EditIcon size="small" />
      </IconButton>
      <Dialog open={open} fullWidth maxWidth="xl">
        <DialogTitle> Modification</DialogTitle>
        <DialogContent>{getForm()}</DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Annuler</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
